import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { styles } from "../assets/StylesFive";
import ConfigDB from "../../../../data/customizer/config";
import footer from "../assets/DeshaktheeFooter.jpeg";
import { CheckedIcon, UncheckedIcon } from "../assets/SVGIcons";
import { FormattedCurrentDateTypeTwo } from "../../../../data/util/DateUtil";
import logo from "../assets/deshakthee.png";

const TypeFive = ({ cv_data }) => {
  const SKILLSPERROW = 4;

  return (
    <Document title={`${cv_data?.referenceNumber}`}>
      <Page size="A4" style={styles.page.container}>
        {/* Start of Main Body */}
        <View style={styles.body.outer}>
          {/* Start of Orange Strip Line */}
          <View style={styles.body.stripContainer}>
            <View style={styles.body.orangeStrip}></View>
          </View>
          {/* End of Orange Strip Line */}
          <View style={{ width: "100%", paddingHorizontal: 15 }}>
            {/* Start of Header Section */}
            <View style={styles.body.applicationHeaderContainer}>
              <View style={styles.body.headerTextContainer}>
                <View style={styles.body.headerTextInner}>
                  {/* Start of Application Header */}
                  <View
                    style={{
                      marginBottom: 5,
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text style={styles.body.applicationHeaderText}>
                      Candidate's Resume
                    </Text>
                  </View>
                  {/* End of Application Header */}
                  {/* Start of Category Value */}
                  <View
                    style={{
                      marginBottom: 5,
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Text style={styles.body.categoryText}>
                      {cv_data?.workExperience?.length > 0
                        ? "Experienced"
                        : "First-Timer"}
                    </Text>
                  </View>
                </View>
                {/* End of Category Value */}
              </View>
              <View style={styles.body.logoContainer}>
                <Image style={styles.body.headerImage} src={logo} />
                <Text style={styles.body.imageText}>Deshakthee Lanka</Text>
              </View>
            </View>
            {/* End of Header Section */}
            {/* Start of Main Data Section*/}
            <View style={styles.body.mainDataContainer}>
              {/* Start of Face Image Container */}
              <View style={styles.body.faceViewImageContainer}>
                <Image
                  style={styles.body.faceViewImage}
                  src={`${ConfigDB.data.fileServerUrl}${cv_data?.faceImage}`}
                />
              </View>
              {/* End of Face Image Container */}
              {/* Start of Passport Details */}
              <View>
                <View style={styles.body.passportRow}>
                  <View style={styles.body.passportColumnOne}>
                    <Text style={styles.body.passportText}>Passport No</Text>
                  </View>
                  <View style={styles.body.passportColumnTwo}>
                    <Text style={styles.body.passportText}>
                      {`${cv_data?.passport?.number}`}
                    </Text>
                  </View>
                  <Text style={styles.body.passportArabic}>
                    رقم جواز السفر{" "}
                  </Text>
                </View>
                <View style={styles.body.passportRow}>
                  <View style={styles.body.passportColumnOne}>
                    <Text style={styles.body.passportText}>Date of Issue</Text>
                  </View>
                  <View style={styles.body.passportColumnTwo}>
                    <Text style={styles.body.passportText}>
                      {`${cv_data?.passport?.dateOfIssue}`}
                    </Text>
                  </View>
                  <Text style={styles.body.passportArabic}>تاريخ الإصدار</Text>
                </View>
                <View style={styles.body.passportRow}>
                  <View style={styles.body.passportColumnOne}>
                    <Text style={styles.body.passportText}>Date of Expire</Text>
                  </View>
                  <View style={styles.body.passportColumnTwo}>
                    <Text style={styles.body.passportText}>
                      {`${cv_data?.passport?.dateOfExpire}`}
                    </Text>
                  </View>
                  <Text style={styles.body.passportArabic}>تاريخ الانتهاء</Text>
                </View>
                <View style={styles.body.passportRow}>
                  <View style={styles.body.passportColumnOne}>
                    <Text style={styles.body.passportText}>Place of Issue</Text>
                  </View>
                  <View style={styles.body.passportColumnTwo}>
                    <Text style={styles.body.passportText}>
                      {cv_data?.passport?.placeOfIssue}
                    </Text>
                  </View>
                  <Text style={styles.body.passportArabic}>مكان الإصدار</Text>
                </View>
              </View>
              {/* End of Passport Details */}
              {/* Start of Reference Number and Date */}
              <View>
                <Text style={styles.body.refNoBox}>
                  {cv_data?.referenceNumber}
                </Text>
                <View>
                  <Text style={styles.body.dateLabel}>Date</Text>
                  <Text style={styles.body.dateBox}>
                    {FormattedCurrentDateTypeTwo()}
                  </Text>
                </View>
              </View>
              {/* End of Reference Number and Date */}
            </View>
            {/* End of Main Data Section */}
            <View style={styles.body.main}>
              {/* Start of Left Column */}
              <View style={styles.page.pageLeftColumn}>
                {/* Start of Language Data */}
                {/* Start of Personal Information */}
                <View style={styles.body.languageHeader}>
                  <Text style={styles.body.languageHeaderText}>Language</Text>
                  <Text style={styles.body.languageHeaderArabic}>اللغة</Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.languageColumnOne}>
                    <Text style={styles.body.languageColumnText}>Arabic</Text>
                    <Text style={styles.body.languageArabic}>
                      اللغة العربية
                    </Text>
                  </View>
                  <View style={styles.body.languageColumnTwo}>
                    <Text style={styles.body.languageColumnText}>
                      {cv_data?.languages?.length > 0
                        ? cv_data?.languages?.filter(
                            (value) => value.name === "Arabic"
                          ).length > 0
                          ? cv_data?.languages?.find(
                              (value) => value.name === "Arabic"
                            ).remark
                          : "N/A"
                        : "N/A"}
                    </Text>
                  </View>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.languageColumnOne}>
                    <Text style={styles.body.languageColumnText}>English</Text>
                    <Text style={styles.body.languageArabic}>
                      اللغة الإنجليزية
                    </Text>
                  </View>
                  <View style={styles.body.languageColumnTwo}>
                    <Text style={styles.body.languageColumnText}>
                      {cv_data?.languages?.length > 0
                        ? cv_data?.languages?.filter(
                            (value) => value.name === "English"
                          ).length > 0
                          ? cv_data?.languages?.find(
                              (value) => value.name === "English"
                            ).remark
                          : "N/A"
                        : "N/A"}
                    </Text>
                  </View>
                </View>
                <View style={styles.body.dataLastRow}>
                  <View style={styles.body.languageColumnOne}>
                    <Text style={styles.body.languageColumnText}>Tamil</Text>
                    <Text style={styles.body.languageArabic}>
                      اللغة التاميلية
                    </Text>
                  </View>
                  <View style={styles.body.languageColumnTwo}>
                    <Text style={styles.body.languageColumnText}>
                      {cv_data?.languages?.length > 0
                        ? cv_data?.languages?.filter(
                            (value) => value.name === "Tamil"
                          ).length > 0
                          ? cv_data?.languages?.find(
                              (value) => value.name === "Tamil"
                            ).remark
                          : "N/A"
                        : "N/A"}
                    </Text>
                  </View>
                </View>
                {/* End of Language Data */}
                {/* Start of Image Container */}
                <View style={styles.body.fullViewImageContainer}>
                  <Image
                    style={styles.body.fullViewImage}
                    src={`${ConfigDB.data.fileServerUrl}${cv_data?.fullImage}`}
                  />
                </View>
                {/* End of Image Container */}
              </View>
              {/* End of Left Column */}
              {/* Start of Right Column */}
              <View style={styles.page.pageRightColumn}>
                {/* Start of Primary Application Data */}
                <View style={styles.body.personalHeader}>
                  <Text style={styles.body.personalHeaderText}>
                    General Information
                  </Text>
                  <Text style={styles.body.personalHeaderArabic}>
                    معلومات عامة
                  </Text>
                </View>
                {/* End of Primary Application Data */}
                {/* Start of Primary Personal Data */}
                <View style={styles.body.dataRow}>
                  <View style={styles.body.primaryPersonalColumnOne}>
                    <Text style={styles.body.primaryPersonalColumnOneText}>
                      Position
                    </Text>
                  </View>
                  <View style={styles.body.primaryPersonalColumnTwo}>
                    <Text style={styles.body.primaryPersonalColumnTwoText}>
                      {cv_data?.appliedJob?.appliedPost}
                    </Text>
                  </View>
                  <Text style={styles.body.primaryPersonalArabicColumn}>
                    المنصب{" "}
                  </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.primaryPersonalColumnOne}>
                    <Text style={styles.body.primaryPersonalColumnOneText}>
                      Contact Period
                    </Text>
                  </View>
                  <View style={styles.body.primaryPersonalColumnTwo}>
                    <Text style={styles.body.primaryPersonalColumnTwoText}>
                      {cv_data?.appliedJob?.contactPeriod}
                    </Text>
                  </View>
                  <Text style={styles.body.primaryPersonalArabicColumn}>
                    مدة العقد
                  </Text>
                </View>
                <View style={styles.body.dataLastRow}>
                  <View style={styles.body.primaryPersonalColumnOne}>
                    <Text style={styles.body.primaryPersonalColumnOneText}>
                      Salary
                    </Text>
                  </View>
                  <View style={styles.body.primaryPersonalColumnTwo}>
                    <Text style={styles.body.primaryPersonalColumnTwoText}>
                      {cv_data?.appliedJob?.salary}
                    </Text>
                  </View>
                  <Text style={styles.body.primaryPersonalArabicColumn}>
                    الراتب{" "}
                  </Text>
                </View>
                {/* End of Primary Personal Data */}
                {/* Start of Personal Information */}
                <View style={styles.body.personalHeader}>
                  <Text style={styles.body.personalHeaderText}>
                    Personal Information
                  </Text>
                  <Text style={styles.body.personalHeaderArabic}>
                    المعلومات الشخصية
                  </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>Name</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.fullName}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>الاسم</Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>
                      Date of Birth
                    </Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.dateOfBirth}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>
                    تاريخ الميلاد
                  </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>Age</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.age}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>العمر</Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>Gender</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.gender}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>الجنس</Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>Color</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.complexion}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>اللون </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>
                      Nationality
                    </Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.nationality}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>الجنسية</Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>City</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.city}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>المدينة</Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>
                      Marital Status
                    </Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.civilStatus}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>
                    الحالة الاجتماعية
                  </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>
                      No. of Kids
                    </Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.NoOfChildren === 0 ? "" : cv_data?.NoOfChildren}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>
                    عدد الأولاد
                  </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>Religion</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.religion}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>الديانة </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>
                      Education
                    </Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.education?.levelName ?? ""}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>التعليم </Text>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>
                      Height( cm )
                    </Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.height === 0 ? "" : `${cv_data?.height} cm`}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>الطول </Text>
                </View>
                <View style={styles.body.dataLastRow}>
                  <View style={styles.body.personalColumnOne}>
                    <Text style={styles.body.personalColumnText}>Weight</Text>
                  </View>
                  <View style={styles.body.personalColumnTwo}>
                    <Text style={styles.body.personalColumnText}>
                      {cv_data?.weight === 0 ? "" : `${cv_data.weight} KG`}
                    </Text>
                  </View>
                  <Text style={styles.body.personalArabicColumn}>الوزن</Text>
                </View>
                {/* End of Personal Information */}
                {/* Start of Experience Information */}
                <View style={styles.body.experienceHeader}>
                  <Text style={styles.body.experienceHeaderText}>
                    Employment Background
                  </Text>
                  <Text style={styles.body.experienceHeaderArabic}>
                    خلفية التوظيف{" "}
                  </Text>
                </View>
                <View style={styles.body.experienceApplicationDataHeader}>
                  <View
                    style={[
                      styles.body.experienceApplicationDataHeaderColumn,
                      styles.body.experienceDataHeaderBorder,
                    ]}
                  >
                    <Text
                      style={styles.body.experienceApplicationDataHeaderText}
                    >
                      Country
                    </Text>
                    <Text
                      style={styles.body.experienceApplicationDataHeaderArabic}
                    >
                      البلد{" "}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.body.experienceApplicationDataHeaderColumn,
                      styles.body.experienceDataHeaderBorder,
                    ]}
                  >
                    <Text
                      style={styles.body.experienceApplicationDataHeaderText}
                    >
                      Position
                    </Text>
                    <Text
                      style={styles.body.experienceApplicationDataHeaderArabic}
                    >
                      المنصب{" "}
                    </Text>
                  </View>
                  <View
                    style={styles.body.experienceApplicationDataHeaderColumn}
                  >
                    <Text
                      style={styles.body.experienceApplicationDataHeaderText}
                    >
                      Duration of Employment
                    </Text>
                    <Text
                      style={styles.body.experienceApplicationDataHeaderArabic}
                    >
                      مدة التوظيف{" "}
                    </Text>
                  </View>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[0]?.countryName ?? ""}
                    </Text>
                  </View>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[0]?.jobCategoryName ?? ""}
                    </Text>
                  </View>
                  <View style={styles.body.experienceLastColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[0]?.yearOfExperience > 0
                        ? `${cv_data?.workExperience[0]?.yearOfExperience} YRS`
                        : ""}
                    </Text>
                  </View>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[1]?.countryName}
                    </Text>
                  </View>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[1]?.jobCategoryName}
                    </Text>
                  </View>
                  <View style={styles.body.experienceLastColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[1]?.yearOfExperience > 0
                        ? `${cv_data?.workExperience[1]?.yearOfExperience} YRS`
                        : ""}
                    </Text>
                  </View>
                </View>
                <View style={styles.body.dataRow}>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[2]?.countryName}
                    </Text>
                  </View>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[2]?.jobCategoryName}
                    </Text>
                  </View>
                  <View style={styles.body.experienceLastColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[2]?.yearOfExperience > 0
                        ? `${cv_data?.workExperience[2]?.yearOfExperience} YRS`
                        : ""}
                    </Text>
                  </View>
                </View>
                <View style={styles.body.dataLastRow}>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[3]?.countryName}
                    </Text>
                  </View>
                  <View style={styles.body.experienceColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[3]?.jobCategoryName}
                    </Text>
                  </View>
                  <View style={styles.body.experienceLastColumn}>
                    <Text style={styles.body.experienceColumnText}>
                      {cv_data?.workExperience[3]?.yearOfExperience > 0
                        ? `${cv_data?.workExperience[3]?.yearOfExperience} YRS`
                        : ""}
                    </Text>
                  </View>
                </View>
                {/* End of Experience Information */}
              </View>
              {/* End of Right Column */}
            </View>
            <View>
              {/* Start of Skill Section */}
              <View style={styles.body.skillHeader}>
                <Text style={styles.body.skillHeaderText}>Skills</Text>
                <Text style={styles.body.skillHeaderArabic}>المهارات</Text>
              </View>
              {/* Start of Skill Row One */}
              <View style={{ flexDirection: "row" }}>
                {[...Array(SKILLSPERROW).keys()].map((value, index) =>
                  cv_data?.skills?.length - 1 >= index ? (
                    <View style={styles.body.skillContainer}>
                      <Text style={styles.body.skillText}>
                        {cv_data?.skills[value]?.name}
                      </Text>
                      <Text style={styles.body.skillArabic}>
                        {cv_data?.skills[value]?.remark}
                      </Text>
                      <View>
                        {cv_data?.skills[value]?.isCan ? (
                          <CheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#035B0F"
                            stroke_width={2}
                          />
                        ) : (
                          <UncheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#800000"
                            stroke_width={2}
                          />
                        )}
                      </View>
                    </View>
                  ) : null
                )}
              </View>
              {/* End of Skill Row One */}
              {/* Start of Skill Row Two */}
              <View style={{ flexDirection: "row" }}>
                {[...Array(SKILLSPERROW).keys()].map((value, index) =>
                  cv_data?.skills?.length - 1 >= index + SKILLSPERROW ? (
                    <View style={styles.body.skillContainer}>
                      <Text style={styles.body.skillText}>
                        {cv_data?.skills[value + SKILLSPERROW]?.name}
                      </Text>
                      <View>
                        {cv_data?.skills[value + SKILLSPERROW]?.isCan ? (
                          <CheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#035B0F"
                            stroke_width={2}
                          />
                        ) : (
                          <UncheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#800000"
                            stroke_width={2}
                          />
                        )}
                      </View>
                    </View>
                  ) : null
                )}
              </View>
              {/* End of Skill Row Two */}
              {/* Start of Skill Row Three */}
              <View style={{ flexDirection: "row" }}>
                {[...Array(SKILLSPERROW).keys()].map((value, index) =>
                  cv_data?.skills?.length - 1 >= index + SKILLSPERROW * 2 ? (
                    <View style={styles.body.skillContainer}>
                      <Text style={styles.body.skillText}>
                        {cv_data?.skills[value + SKILLSPERROW * 2]?.name}
                      </Text>
                      <View>
                        {cv_data?.skills[value + SKILLSPERROW * 2]?.isCan ? (
                          <CheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#035B0F"
                            stroke_width={2}
                          />
                        ) : (
                          <UncheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#800000"
                            stroke_width={2}
                          />
                        )}
                      </View>
                    </View>
                  ) : null
                )}
              </View>
              {/* End of Skill Row Three */}
              {/* Start of Skill Row Four */}
              {/* <View style={{ flexDirection: "row" }}>
                {[...Array(SKILLSPERROW).keys()].map((value, index) =>
                  cv_data?.skills?.length - 1 >= index + SKILLSPERROW * 3 ? (
                    <View style={styles.body.skillContainer}>
                      <Text style={styles.body.skillText}>
                        {cv_data?.skills[value + SKILLSPERROW * 3]?.name}
                      </Text>
                      <View>
                        {cv_data?.skills[value + SKILLSPERROW * 3]?.isCan ? (
                          <CheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#035B0F"
                            stroke_width={2}
                          />
                        ) : (
                          <UncheckedIcon
                            width={18}
                            height={18}
                            stroke_color="#800000"
                            stroke_width={2}
                          />
                        )}
                      </View>
                    </View>
                  ) : null
                )}
              </View> */}
              {/* End of Skill Row Four */}
              {/* End of Skill Section */}
              {/* Start of Remarks Section */}
              <View style={styles.body.remarkHeader}>
                <Text style={styles.body.remarkHeaderText}>Remarks</Text>
                <Text style={styles.body.remarkHeaderArabic}> الملاحظات </Text>
                <View style={styles.body.remarkHeaderLine}></View>
              </View>
              {cv_data?.remarks?.map((value, index) => (
                <Text style={styles.body.remarksText}>{`${
                  index + 1
                }. ${value}`}</Text>
              ))}
              {/* End of Remarks Section */}
            </View>
          </View>
        </View>
        {/* End of Main Body */}
        {/* Start of Footer */}
        <View style={styles.body.footerContainer}>
          <Image style={{ width: "100%" }} src={footer} />
        </View>
        {/* End of Footer */}
      </Page>
      {/* Start of Attachment Page */}
      {cv_data?.passport?.attachments?.length ? (
        <Page size="A4" style={styles.page.container}>
          <View style={styles.body.passportImageContainer}>
            <Image
              style={styles.body.passportImage}
              src={`${ConfigDB.data.fileServerUrl}${
                cv_data?.passport?.attachments !== undefined
                  ? cv_data.passport.attachments[0].filePath
                  : ""
              }`}
            />
          </View>
        </Page>
      ) : null}
      {cv_data?.passport?.attachments?.length > 1 ? (
        <Page size="A4" style={styles.page.container}>
          <View style={styles.body.passportImageContainer}>
            <Image
              style={styles.body.passportImage}
              src={`${ConfigDB.data.fileServerUrl}${
                cv_data?.passport?.attachments !== undefined
                  ? cv_data.passport.attachments[1].filePath
                  : ""
              }`}
            />
          </View>
        </Page>
      ) : null}
      {/* End of Attachment Page */}
    </Document>
  );
};

export default TypeFive;
// CUS1591E
