import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

// images
import default_avatar from "../../assets/images/user/default_avatar.png";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons, BooleanValue } from "../common/datatable";
import formValidation from "../../customHooks/useValidation";

//network requests
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import { ConvertMillisToDate } from "../../data/util/DateUtil";
import {
  DEACTIVATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  EDIT_EMPLOYEE,
  EMPLOYEE,
  INSERT_EMPLOYEE,
} from "../../constant";
import ConfigDB from "../../data/customizer/config";
import { LoadStaticURL } from "../../data/util/LoadStaticURL";

const Employee = () => {
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, EMPLOYEE);
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [resData, setResData] = useState();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [profilePicture, setProfilePicture] = useState({
    base64Value: "",
    extension: "",
    changed: false,
    url: "",
  });
  const [titleList, setTitleList] = useState([
    { titleId: "", name: "-Select-" },
  ]);
  const [dpTitleValue, setDpTitleValue] = useState(0);
  const [genderList, setGenderList] = useState([
    { genderId: "", name: "-Select-" },
  ]);
  const [dpGenderValue, setDpGenderValue] = useState(0);
  const [departmentList, setDepartmentList] = useState([
    { departmentId: "", name: "-Select-" },
  ]);
  const [dpDepartmentValue, setDpDepartmentValue] = useState(0);
  const [designationList, setDesignationList] = useState([
    { designationId: "", name: "-Select-" },
  ]);
  const [dpDesignationValue, setDpDesignationValue] = useState(0);
  const [nationalityList, setNationalityList] = useState([
    { nationalityId: "", name: "-Select-" },
  ]);
  const [dpNationalityValue, setDpNationalityValue] = useState(0);
  const [cityList, setCityList] = useState([{ cityId: "", name: "-Select-" }]);
  const [dpCityValue, setDpCityValue] = useState(0);
  const [countryList, setCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpCountryValue, setDpCountryValue] = useState(0);
  const [employeeId, setEmployeeId] = useState(0);

  // table data
  const [employeeRowList, setEmployeeRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    // {
    //   name: "Client",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    // {
    //   name: "Organization",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    "First Name",
    "Last Name",
    "Title",
    "Gender",
    "NIC",
    "Date of Birth",
    "Contact Number",
    "Email",
    "Department",
    "Designation",
    "Nationality",
    "Address",
    "Country",
    "City",
    "Description",
    "Remark",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDIT_EMPLOYEE) ||
          checkIsAccessible(DELETE_EMPLOYEE),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editEmployee] = useState(() => (employee_id, response_data) => {
    const editingEmployee = response_data.find(
      (item) => item.bpartnerId === employee_id
    );
    setEmployeeId(employee_id);
    setDpTitleValue(editingEmployee.titleId);
    setDpGenderValue(editingEmployee.genderId);
    setDpDepartmentValue(editingEmployee.departmentId);
    setDpDesignationValue(editingEmployee.designationId);
    setDpNationalityValue(editingEmployee.nationalityId);
    setDpCountryValue(editingEmployee.countryId);
    setDpCityValue(editingEmployee.cityId);
    setProfilePicture({
      changed: false,
      base64Value: "",
      extension: "",
      url: editingEmployee.profilePicturePath,
    });

    document.querySelector("input[name='fm_1_first_name']").value =
      editingEmployee.firstName;
    document.querySelector("input[name='fm_1_last_name']").value =
      editingEmployee.lastName;
    document.querySelector("input[name='fm_1_nic_no']").value =
      editingEmployee.nicNo;
    document.querySelector("input[name='fm_1_date_of_birth']").value =
      ConvertMillisToDate(editingEmployee.dateOfBirth);
    document.querySelector("input[name='fm_1_contact_number']").value =
      editingEmployee.contactNumber;
    document.querySelector("input[name='fm_1_email']").value =
      editingEmployee.email;
    document.querySelector("input[name='fm_1_address']").value =
      editingEmployee.address;
    document.querySelector("textarea[name='fm_1_description']").value =
      editingEmployee.description;
    document.querySelector("textarea[name='fm_1_remark']").value =
      editingEmployee.remark;
    document.querySelector("input[name='fm_1_is_active']").checked =
      editingEmployee.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteEmployee] = useState(() => (employee_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Employee!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          bpartnerId: employee_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/deleteEmployee",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Employee has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      document
        .querySelectorAll("input:required,select:required")
        .forEach((elem) =>
          elem.previousSibling.classList.add("required-input-mark")
        );
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/viewEmployee",
          data: reqBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.allEmployeeList) {
            setResData(response_data.allEmployeeList);
          }
        }
      });

      const dpListRequestBody = { clientId: loggedUser.clientId };
      let responseArrayData = new Promise((resolve) => {
        const result = sendRequest({
          url: [
            "/getAllActiveTitle",
            "/getAllActiveGender",
            "/getAllActiveDepartment",
            "/getAllActiveDesignation",
            "/getAllActiveNationality",
            "/getAllActiveCountry",
            "/getAllActiveCity",
          ],
          data: dpListRequestBody,
          template: "REQUEST_ARRAY",
        });
        resolve(result);
      });
      responseArrayData.then((response_data_array) => {
        if (response_data_array) {
          setTitleList((prevList) => [...prevList, ...response_data_array[0]]);
          setGenderList((prevList) => [...prevList, ...response_data_array[1]]);
          setDepartmentList((prevList) => [
            ...prevList,
            ...response_data_array[2],
          ]);
          setDesignationList((prevList) => [
            ...prevList,
            ...response_data_array[3],
          ]);
          setNationalityList((prevList) => [
            ...prevList,
            ...response_data_array[4],
          ]);
          setCountryList((prevList) => [
            ...prevList,
            ...response_data_array[5],
          ]);
          setCityList((prevList) => [...prevList, ...response_data_array[6]]);
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated, sendRequest]);
  useEffect(() => {
    if (resData) {
      let tempList = [];
      resData.forEach((listItem) => {
        if (loggedUser.orgId === listItem.orgId) {
          tempList.push([
            listItem.bpartnerId,
            // loggedUser.activeClientList?.find(
            //   (item) => item.clientId === listItem.clientId
            // ).name,
            // loggedUser.activeOrgRoleList?.find(
            //   (item) => item.orgId === listItem.orgId
            // ).name,
            listItem.firstName,
            listItem.lastName,
            listItem.titleName,
            listItem.genderName,
            listItem.nicNo,
            ConvertMillisToDate(listItem.dateOfBirth),
            listItem.contactNumber,
            listItem.email,
            listItem.departmentName,
            listItem.designationName,
            listItem.nationalityName,
            listItem.address,
            listItem.countryName,
            listItem.cityName,
            listItem.description,
            listItem.remark,
            listItem.isActive ? "True" : "False",
            <ControlButtons
              editMethod={() => editEmployee(listItem.bpartnerId, resData)}
              disableEdit={!checkIsAccessible(EDIT_EMPLOYEE)}
              deleteMethod={() =>
                deleteEmployee(listItem.bpartnerId, loggedUser)
              }
              disableDelete={!checkIsAccessible(DELETE_EMPLOYEE)}
            />,
          ]);
        }
      });
      setEmployeeRowList([...tempList]);
    }
  }, [resData, editEmployee, deleteEmployee, loggedUser, checkIsAccessible]);

  // form control functions
  const resetForm = (evt) => {
    setIsValidated(false);
    setEmployeeId(0);
    setDpTitleValue(0);
    setDpGenderValue(0);
    setDpDepartmentValue(0);
    setDpDesignationValue(0);
    setDpNationalityValue(0);
    setDpCountryValue(0);
    setDpCityValue(0);
    setProfilePicture({
      base64Value: "",
      extension: "",
      url: "",
      changed: true,
    });
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
    setTimeout(() => {
      document.querySelector("select[name='fm_1_sa_client_id']").value =
        loggedUser.clientId;
      document.querySelector("select[name='fm_1_sa_org_id']").value =
        loggedUser.orgId;
    }, 100);
  };
  const submitForm = (evt) => {
    setIsValidated(true);
    console.log(profilePicture);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkEmployeeExist",
          data: {
            clientId: loggedUser.clientId,
            bpartnerId: employeeId,
            nicNo: formData.get("fm_1_nic_no"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let employee = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                userId: parseInt(loggedUser.userId),
                bpartnerId: employeeId,
                isActive: document.querySelector("input[name='fm_1_is_active']")
                  ?.checked,
                firstName: formData.get("fm_1_first_name"),
                lastName: formData.get("fm_1_last_name"),
                titleId: parseInt(dpTitleValue),
                genderId: parseInt(dpGenderValue),
                nicNo: formData.get("fm_1_nic_no"),
                dateOfBirth: formData.get("fm_1_date_of_birth"),
                contactNumber: formData.get("fm_1_contact_number"),
                email: formData.get("fm_1_email"),
                departmentId: parseInt(dpDepartmentValue),
                designationId: parseInt(dpDesignationValue),
                nationalityId: parseInt(dpNationalityValue),
                countryId: parseInt(dpCountryValue),
                address: formData.get("fm_1_address"),
                cityId: parseInt(dpCityValue),
                profilePicture: profilePicture.base64Value, //Base64 file
                isProfilePictureChange: profilePicture.changed,
                profilePictureNameExtension: profilePicture.extension,
                description: formData.get("fm_1_description"),
                remark: formData.get("fm_1_remark"),
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveEmployee",
                  data: employee,
                  template: "CONTROL_DATA",
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: employeeId
                      ? "Employee has successfully updated."
                      : "New Employee has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setRefreshTable(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "NIC Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };
  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      console.log(mimeType.replace(/image\//, "."));
      setProfilePicture({
        changed: true,
        base64Value: reader.result,
        extension: mimeType.replace(/image\//, "."),
        url: "",
      });
    };
  };

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Master File" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Employee</h5>
                <span>Master File for creating an Employee</span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_first_name">
                        First Name
                      </label>
                      <input
                        id="id_input_fm_1_first_name"
                        name="fm_1_first_name"
                        type="text"
                        maxLength={250}
                        className="form-control"
                        placeholder="Enter First Name"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            checkExpression: /[0-9!@#$%^<=>{}]+/,
                            // customMessage: customeValidationMessages[0],
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter First Name
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-6 position-relative">
                      <label htmlFor="id_input_fm_1_last_name required-input-mark">
                        Last Name
                      </label>
                      <input
                        id="id_input_fm_1_last_name"
                        name="fm_1_last_name"
                        type="text"
                        maxLength={250}
                        className="form-control"
                        placeholder="Enter Last Name"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            checkExpression: /[0-9!@#$%^<=>{}]+/,
                            // customMessage: customeValidationMessages[0],
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Last Name
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_date_of_birth">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="id_input_fm_1_date_of_birth"
                        name="fm_1_date_of_birth"
                        required
                      />
                      <div className="invalid-feedback position-absolute">
                        Please select Date of Birth
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_gender_id"
                        className="col-form-label pt-0"
                      >
                        Gender
                      </label>
                      <select
                        id="id_input_fm_1_sa_gender_id"
                        name="fm_1_sa_gender_id"
                        className="form-select"
                        onChange={(evt) => setDpGenderValue(evt.target.value)}
                        value={dpGenderValue}
                        required
                      >
                        {genderList.length
                          ? genderList.map((listItem, index) => (
                              <option value={listItem?.genderId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select Gender
                      </small>
                    </div>
                    <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_nic_no">NIC</label>
                      <input
                        id="id_input_fm_1_nic_no"
                        name="fm_1_nic_no"
                        type="text"
                        maxLength={20}
                        className="form-control"
                        placeholder="Enter NIC Number"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateNIC: true,
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter NIC Number
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-3 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_title_id"
                        className="col-form-label pt-0"
                      >
                        Title
                      </label>
                      <select
                        id="id_input_fm_1_sa_title_id"
                        name="fm_1_sa_title_id"
                        className="form-select"
                        onChange={(evt) => setDpTitleValue(evt.target.value)}
                        value={dpTitleValue}
                        required
                      >
                        {titleList.length
                          ? titleList.map((listItem, index) => (
                              <option value={listItem?.titleId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Title
                      </small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_contact_number"
                        className="required-input-mark"
                      >
                        Contact Number
                      </label>
                      <input
                        id="id_input_fm_1_contact_number"
                        name="fm_1_contact_number"
                        type="text"
                        maxLength={15}
                        minLength={9}
                        className="form-control"
                        placeholder="Enter Contact Number"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validatePhoneNumber: true,
                          })
                        }
                        required
                      />
                      <div className="invalid-feedback position-absolute">
                        Please enter a Contact Number.
                      </div>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-6">
                      <label htmlFor="id_input_fm_1_email">Email</label>
                      <input
                        id="id_input_fm_1_email"
                        name="fm_1_email"
                        type="email"
                        maxLength={100}
                        className="form-control"
                        placeholder="Enter Email Address"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateEmail: true,
                          })
                        }
                        required
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_department_id"
                        className="col-form-label pt-0"
                      >
                        Department
                      </label>
                      <select
                        id="id_input_fm_1_sa_department_id"
                        name="fm_1_sa_department_id"
                        className="form-select"
                        onChange={(evt) =>
                          setDpDepartmentValue(evt.target.value)
                        }
                        value={dpDepartmentValue}
                        required
                      >
                        {departmentList.length
                          ? departmentList.map((listItem, index) => (
                              <option
                                value={listItem?.departmentId}
                                key={index}
                              >
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Department
                      </small>
                    </div>
                    <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_designation_id"
                        className="col-form-label pt-0"
                      >
                        Designation
                      </label>
                      <select
                        id="id_input_fm_1_sa_designation_id"
                        name="fm_1_sa_designation_id"
                        className="form-select"
                        onChange={(evt) =>
                          setDpDesignationValue(evt.target.value)
                        }
                        value={dpDesignationValue}
                        required
                      >
                        {designationList.length
                          ? designationList.map((listItem, index) => (
                              <option
                                value={listItem?.designationId}
                                key={index}
                              >
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Designation
                      </small>
                    </div>
                    <div className="form-group col-12 col-sm-4 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_nationality_id"
                        className="col-form-label pt-0"
                      >
                        Nationality
                      </label>
                      <select
                        id="id_input_fm_1_sa_nationality_id"
                        name="fm_1_sa_nationality_id"
                        className="form-select"
                        onChange={(evt) =>
                          setDpNationalityValue(evt.target.value)
                        }
                        value={dpNationalityValue}
                        required
                      >
                        {nationalityList.length
                          ? nationalityList.map((listItem, index) => (
                              <option
                                value={listItem?.nationalityId}
                                key={index}
                              >
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Nationality
                      </small>
                    </div>
                  </div>
                  <div className="form-group position-relative mb-4">
                    <label htmlFor="id_input_fm_3_address">Address</label>
                    <input
                      id="id_input_fm_3_address"
                      name="fm_1_address"
                      type="text"
                      maxLength={250}
                      className="form-control"
                      placeholder="Enter Address Line"
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          checkExpression: /[^\w/,'".-\s]+/,
                          customMessage: "Invalid Address",
                        })
                      }
                    />
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_country_id"
                        className="col-form-label pt-0"
                      >
                        Country
                      </label>
                      <select
                        id="id_input_fm_1_sa_country_id"
                        name="fm_1_sa_country_id"
                        className="form-select"
                        onChange={(evt) => setDpCountryValue(evt.target.value)}
                        value={dpCountryValue}
                        required
                      >
                        {countryList.length
                          ? countryList.map((listItem, index) => (
                              <option value={listItem?.countryId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Country
                      </small>
                    </div>
                    <div className="form-group col-12 col-sm-6 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_city_id"
                        className="col-form-label pt-0"
                      >
                        City
                      </label>
                      <select
                        id="id_input_fm_1_sa_city_id"
                        name="fm_1_sa_city_id"
                        className="form-select"
                        onChange={(evt) => setDpCityValue(evt.target.value)}
                        value={dpCityValue}
                        required
                      >
                        {cityList.length
                          ? cityList.map((listItem, index) => (
                              <option value={listItem?.cityId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a City
                      </small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-3">
                      <div className="user-image">
                        <div className="avatar m-auto m-sm-0">
                          <img
                            className="pro"
                            alt=""
                            src={
                              profilePicture.url
                                ? `${ConfigDB.data.fileServerUrl}${profilePicture.url}`
                                : profilePicture.base64Value
                                ? profilePicture.base64Value
                                : LoadStaticURL(default_avatar)
                            }
                            data-intro="This is Profile image"
                          />
                          <div className="icon-wrapper">
                            <i
                              className="icofont icofont-pencil-alt-5"
                              data-intro="Change Profile image here"
                            >
                              <input
                                accept="image/*"
                                className="pencil"
                                type="file"
                                onChange={(e) => readUrl(e)}
                              />
                            </i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-9">
                      <label htmlFor="id_input_fm_1_description">
                        Description
                      </label>
                      <textarea
                        id="id_input_fm_1_description"
                        name="fm_1_description"
                        maxLength={250}
                        className="form-control"
                        rows="3"
                        placeholder="Enter Description"
                        onChange={(evt) =>
                          formValidation({ event: evt, checkExpression: "" })
                        }
                      ></textarea>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_remark">Remarks</label>
                    <textarea
                      id="id_input_fm_1_remark"
                      name="fm_1_remark"
                      rows="3"
                      maxLength={250}
                      className="form-control"
                      placeholder="Enter Remarks"
                      onChange={(evt) =>
                        formValidation({ event: evt, checkExpression: "" })
                      }
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                  <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-1">
                      <input
                        id="id_input_fm_1_is_active"
                        name="fm_1_is_active"
                        type="checkbox"
                        disabled={!checkIsAccessible(DEACTIVATE_EMPLOYEE)}
                        defaultChecked
                      />
                      <label htmlFor="id_input_fm_1_is_active">Active</label>
                    </div>
                  </div>
                  {checkIsAccessible(INSERT_EMPLOYEE) ||
                  (checkIsAccessible(EDIT_EMPLOYEE) && employeeId !== 0) ? (
                    <div className="d-sm-flex justify-content-end">
                      <button
                        className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                        type="submit"
                      >
                        {employeeId ? "Update" : "Submit"}{" "}
                      </button>
                      <button
                        className="btn btn-warning col-12 col-sm-2"
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className="mb-4">
              <Datatable
                titleData="Employee List"
                columnData={tableColumnHeaderList}
                rowData={employeeRowList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Employee;
