import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import FileUploader from "../../../common/fileUploader";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import ConfigDB from "../../../../data/customizer/config";
import {
  COUNTRY,
  INSERT_COUNTRY,
  INSERT_JOB_CATEGORY,
  JOB_CATEGORY,
} from "../../../../constant";

const Experience = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  const MAXFILELIMIT = 5;

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [attachmentList, setAttachmentList] = useState([]);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpCountry, setRefreshDpCountry] = useState(false);
  const [refreshDpJobCategory, setRefreshDpJobCategory] = useState(false);

  // form values
  const [jobList, setJobList] = useState([
    { jobCategoryId: "", name: "-Select-" },
  ]);
  const [dpJobValue, setDpJobValue] = useState(0);
  const [countryList, setCountryList] = useState([
    { countryId: "", name: "-Select-" },
  ]);
  const [dpCountryValue, setDpCountryValue] = useState(0);
  const [experienceId, setExperienceId] = useState(0);

  // table data
  const [experienceRowList, setExperienceRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Country",
    "Employer Name",
    "Job Name",
    "Start Date",
    "End Date",
    "Experience",
    {
      name: "Visible",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editExperience] = useState(() => (experience_id, response_data) => {
    const editingExperience = response_data.find(
      (item) => item.experienceId === experience_id
    );
    setExperienceId(editingExperience.experienceId);
    setDpCountryValue(editingExperience.countryId);
    setDpJobValue(editingExperience.jobCategoryId);
    document.querySelector("input[name='fm_6_employer_name']").value =
      editingExperience.employerName;
    document.querySelector(
      "input[name='fm_6_month_of_experience_years']"
    ).value = editingExperience.yearOfExperience;
    document.querySelector(
      "input[name='fm_6_month_of_experience_months']"
    ).value = editingExperience.monthOfExperience;
    document.querySelector("input[name='fm_6_start_date']").value =
      ConvertMillisToDate(editingExperience.startDate);
    document.querySelector("input[name='fm_6_end_date']").value =
      ConvertMillisToDate(editingExperience.endDate);
    document.querySelector("input[name='fm_6_is_visible']").checked =
      editingExperience.isVisible;
    document.querySelector("textarea[name='fm_6_remark']").value =
      editingExperience.remark;
    document.querySelector("input[name='fm_6_is_active']").checked =
      editingExperience.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const reqBody = {
      experienceId: editingExperience.experienceId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/getExperienceAttachmentListByExperienceId",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        let tempList = [];
        let tempList2 = [];
        response_data.forEach((listItem) => {
          tempList.push({
            documentId: listItem.id,
            isModified: false,
            attachmentExtension: "",
            attachment: "",
          });
          tempList2.push({
            id: listItem.id,
            type: listItem.filePath.split(".")[1],
            source: listItem.filePath,
          });
        });
        setPreviewFileList(tempList2);
        setAttachmentList([...tempList]);
        setIsLoaded(LOADED);
        setRefreshTable(false);
      }
    });
  });
  const [deleteExperience] = useState(() => (experience_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Experience Record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          experienceId: experience_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteExperience",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Experience Record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let experience = {
        bpartnerId: parseInt(b_partner_id),
        experienceId: parseInt(experienceId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        employerName: formData.get("fm_6_employer_name"),
        countryId: parseInt(dpCountryValue),
        jobCategoryId: parseInt(dpJobValue),
        yearOfExperience: formData.get("fm_6_month_of_experience_years"),
        monthOfExperience: formData.get("fm_6_month_of_experience_months"),
        startDate: formData.get("fm_6_start_date"),
        endDate: formData.get("fm_6_end_date"),
        isActive: formData.get("fm_6_is_active") ? true : false,
        isVisible: formData.get("fm_6_is_collected") ? true : false,
        remark: formData.get("fm_6_remark"),
        attachmentList: attachmentList,
      };
      console.log(experience);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveExperienceTab",
          data: experience,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: experienceId
              ? "Experience Record has successfully updated."
              : "New Experience Record has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setDpCountryValue(0);
    setDpJobValue(0);
    setExperienceId(0);
    setAttachmentList([]);
    setPreviewFileList([]);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewExperienceTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeCountryList) {
              setCountryList([
                { countryId: "", name: "-Select-" },
                ...response_data.activeCountryList,
              ]);
            }
            if (response_data.activeJobCategoryList) {
              setJobList([
                { jobCategoryId: "", name: "-Select-" },
                ...response_data.activeJobCategoryList,
              ]);
            }
            if (response_data.allCustomerExperienceTableList) {
              let tempList = [];
              response_data.allCustomerExperienceTableList.forEach(
                (listItem) => {
                  tempList.push([
                    listItem.experienceId,
                    listItem.countryName,
                    listItem.employerName,
                    listItem.jobCategoryName,
                    ConvertMillisToDate(listItem.startDate),
                    ConvertMillisToDate(listItem.endDate),
                    `${listItem.yearOfExperience}Years ${listItem.monthOfExperience}Months`,
                    listItem.isVisible ? "True" : "False",
                    listItem.remark,
                    listItem.isActive ? "True" : "False",
                    <ControlButtons
                      editMethod={() =>
                        editExperience(
                          listItem.experienceId,
                          response_data.allCustomerExperienceTableList
                        )
                      }
                      disableEdit={!checkIsAccessible(EDITFUNCTION)}
                      deleteMethod={() =>
                        deleteExperience(listItem.experienceId, loggedUser)
                      }
                      disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                    />,
                  ]);
                }
              );
              setExperienceRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  useEffect(() => {
    if (refreshDpJobCategory) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveJobCategory",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setJobList([
            { jobCategoryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpJobCategory(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpJobCategory]);

  useEffect(() => {
    if (refreshDpCountry) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCountry",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCountryList([
            { countryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCountry(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCountry]);

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Experience Details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-6 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_6_sa_country_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Country
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpCountry ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpCountry(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() => openMasterFileWindow(COUNTRY, INSERT_COUNTRY)}
                />
              </div>
              <select
                id="id_input_fm_6_sa_country_id"
                name="fm_6_sa_country_id"
                className="form-select"
                onChange={(evt) => setDpCountryValue(evt.target.value)}
                value={dpCountryValue}
                required
              >
                {countryList.length
                  ? countryList.map((listItem, index) => (
                      <option value={listItem?.countryId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select Country
              </div>
            </div>
            <div className="form-group col-12 col-sm-9 position-relative">
              <label
                htmlFor="id_input_fm_6_employer_name"
                className="required-input-mark"
              >
                Employer Name
              </label>
              <input
                id="id_input_fm_6_employer_name"
                name="fm_6_employer_name"
                className="form-control"
                maxLength={150}
                placeholder="Enter Employer Name"
                autoComplete="off"
                onChange={(evt) => formValidation({ event: evt })}
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter Employer Name.
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_6_sa_job_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Job
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpJobCategory ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpJobCategory(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(JOB_CATEGORY, INSERT_JOB_CATEGORY)
                  }
                />
              </div>
              <select
                id="id_input_fm_6_sa_job_id"
                name="fm_6_sa_job_id"
                className="form-select"
                onChange={(evt) => setDpJobValue(evt.target.value)}
                value={dpJobValue}
                required
              >
                {jobList.length
                  ? jobList.map((listItem, index) => (
                      <option value={listItem?.jobCategoryId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select a Job
              </div>
            </div>
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0">
              <label htmlFor="id_input_fm_6_start_date">Start Date</label>
              <input
                id="id_input_fm_6_start_date"
                name="fm_6_start_date"
                type="date"
                className="form-control"
              />
            </div>
            <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0">
              <label htmlFor="id_input_fm_6_end_date">End Date</label>
              <input
                id="id_input_fm_6_end_date"
                name="fm_6_end_date"
                type="date"
                className="form-control"
              />
            </div>
            <div className="form-group d-flex col-12 col-sm-3">
              <div className="form-group position-relative">
                <label htmlFor="id_input_fm_6_month_of_experience">
                  Years of Experience
                </label>
                <div className="d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>No. of Years</Tooltip>}
                  >
                    <div className="input-group pr-1">
                      <div className="input-group-prepend">
                        <div className="input-group-text">YY</div>
                      </div>
                      <input
                        id="id_input_fm_6_month_of_experience"
                        name="fm_6_month_of_experience_years"
                        type="text"
                        className="form-control"
                        placeholder="00"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            checkExpression: /[^\d]+/,
                          })
                        }
                      />
                    </div>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>No. of Months</Tooltip>}
                  >
                    <div className="input-group pl-1">
                      <div className="input-group-prepend">
                        <div className="input-group-text">MM</div>
                      </div>
                      <input
                        name="fm_6_month_of_experience_months"
                        type="text"
                        placeholder="00"
                        className="form-control"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            checkExpression: /[^\d]+/,
                          })
                        }
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
          <FileUploader
            attachment_list={attachmentList}
            preview_list={previewFileList}
            set_attachment_list={setAttachmentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
          />
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_6_remark">Remark</label>
            <textarea
              id="id_input_fm_6_remark"
              name="fm_6_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-row mb-4">
            <div className="checkbox checkbox-solid-dark col-12 col-sm-3">
              <input
                id="id_input_fm_6_is_visible"
                name="fm_6_is_visible"
                type="checkbox"
              />
              <label htmlFor="id_input_fm_6_is_visible">Visible</label>
            </div>
            {(checkIsAccessible(INSERTFUNCTION) && experienceId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && experienceId !== 0) ? (
              <div className="checkbox checkbox-solid-dark col-12 col-sm-3">
                <input
                  id="id_input_fm_6_is_active"
                  name="fm_6_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_6_is_active">Active</label>
              </div>
            ) : null}
          </div>
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && experienceId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {experienceId ? "Update" : "Submit"}
                </button>
                <button
                  className="btn btn-warning col-12 col-sm-2"
                  type="reset"
                >
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Experience List"
          columnData={tableColumnHeaderList}
          rowData={experienceRowList}
        />
      </div>
    </>
  );
};

export default Experience;
