import React, { Fragment } from "react";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import Bookmark from "./bookmark";
import { useTranslation } from "react-i18next";

const Breadcrumb = (props) => {
  const breadcrumb = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>{t(breadcrumb.title)}</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>
                      <Home />
                    </Link>
                  </li>
                  <li className={"breadcrumb-item"}>{breadcrumb.parent}</li>
                  {breadcrumb.children
                    ? breadcrumb.children.map((item, index) => (
                        <li
                          className={`breadcrumb-item ${
                            item.active ? "active" : ""
                          }`}
                          key={index}
                        >
                          {item.value}
                        </li>
                      ))
                    : false}
                </ol>
              </div>
            </div>
            {/* <!-- Bookmark Start--> */}
            {/* <Bookmark /> */}
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Breadcrumb;
