import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../../customHooks/useValidation";
import ConfigDB from "../../../../../data/customizer/config";
import { PlusCircle, RefreshCw } from "react-feather";
import {
  CITY,
  CUSTOMER_ADDRESS_TYPE,
  DISTRICT,
  INSERT_CITY,
  INSERT_CUSTOMER_ADDRESS_TYPE,
  INSERT_DISTRICT,
} from "../../../../../constant";

const Address = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpDistrict, setRefreshDpDistrict] = useState(false);
  const [refreshDpCity, setRefreshDpCity] = useState(true);
  const [refreshDpAddressType, setRefreshDpAddressType] = useState(false);

  // form values
  const [districtList, setDistrictList] = useState([
    { districtId: "", name: "-Select-" },
  ]);
  const [dpDistrictValue, setDpDistrictValue] = useState(0);
  const [cityList, setCityList] = useState([{ cityId: "", name: "-Select-" }]);
  const [dpCityValue, setDpCityValue] = useState(0);
  const [addressTypeList, setAddressTypeList] = useState([
    { customerAddressTypeId: "", name: "-Select-" },
  ]);
  const [dpAddressTypeValue, setDpAddressTypeValue] = useState(0);
  const [addressId, setAddressId] = useState(0);

  // table data
  const [addressRowList, setAddressRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "District",
    "City",
    "Address",
    "Address Type",
    {
      name: "Default",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editAddress] = useState(() => (address_id, response_data) => {
    const editingAddress = response_data.find(
      (item) => item.addressId === address_id
    );
    setAddressId(editingAddress.addressId);
    setDpDistrictValue(editingAddress.districtId);
    setDpCityValue(editingAddress.cityId);
    setDpAddressTypeValue(editingAddress.customerAddressTypeId);
    document.querySelector("input[name='fm_4_address']").value =
      editingAddress.address;
    document.querySelector("input[name='fm_4_is_default']").checked =
      editingAddress.isDefault;
    document.querySelector("textarea[name='fm_4_remark']").value =
      editingAddress.remark;
    document.querySelector("input[name='fm_4_is_active']").checked =
      editingAddress.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteAddress] = useState(() => (address_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Address Record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          addressId: address_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteAddress",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Address Record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let address = {
        bpartnerId: parseInt(b_partner_id),
        addressId: parseInt(addressId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        address: formData.get("fm_4_address"),
        cityId: parseInt(dpCityValue),
        customerAddressTypeId: parseInt(dpAddressTypeValue),
        remark: formData.get("fm_4_remark"),
        isActive: formData.get("fm_4_is_active") ? true : false,
        isDefault: formData.get("fm_4_is_default") ? true : false,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveAddressTab",
          data: address,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: addressId
              ? "Address has successfully updated."
              : "New Address has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
        }
      });
    }
  };

  const resetForm = () => {
    setIsValidated(false);
    setAddressId(0);
    setDpDistrictValue(0);
    setDpCityValue(0);
    setDpAddressTypeValue(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewAddressTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeDistrictList) {
              setDistrictList([
                { districtId: "", name: "-Select-" },
                ...response_data.activeDistrictList,
              ]);
            }
            if (response_data.activeCustomerAddressTypeList) {
              setAddressTypeList([
                { customerAddressTypeId: "", name: "-Select-" },
                ...response_data.activeCustomerAddressTypeList,
              ]);
            }
            if (response_data.allCustomerAddressTableList) {
              let tempList = [];
              response_data.allCustomerAddressTableList.forEach((listItem) => {
                tempList.push([
                  listItem.addressId,
                  listItem.districtName,
                  listItem.cityName,
                  listItem.address,
                  listItem.customerAddressTypeName,
                  listItem.isDefault ? "True" : "False",
                  listItem.remark,
                  listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editAddress(
                        listItem.addressId,
                        response_data.allCustomerAddressTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deleteAddress(listItem.addressId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setAddressRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (dpDistrictValue || refreshDpCity) {
      const reqBody = {
        districtId: dpDistrictValue,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCityByDistrict",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCityList((prevList) => [prevList[0], ...response_data]);
          setRefreshDpCity(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpDistrictValue, refreshDpCity]);

  useEffect(() => {
    if (refreshDpDistrict) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveDistrict",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setDistrictList([
            { districtId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpDistrict(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpDistrict]);

  useEffect(() => {
    if (refreshDpAddressType) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCustomerAddressType",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setAddressTypeList([
            { customerAddressTypeId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpAddressType(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpAddressType]);

  return (
    <>
      <div className="card-modified-body mb-4">
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-4 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_district_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  District
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpDistrict ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpDistrict(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(DISTRICT, INSERT_DISTRICT)
                  }
                />
              </div>
              <select
                id="id_input_fm_4_sa_district_id"
                name="fm_4_sa_district_id"
                className="form-select"
                onChange={(evt) => setDpDistrictValue(evt.target.value)}
                value={dpDistrictValue}
                required
              >
                {districtList.length
                  ? districtList.map((listItem, index) => (
                      <option value={listItem?.districtId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a Ditrict
              </small>
            </div>
            <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_city_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  City
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpCity ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpCity(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() => openMasterFileWindow(CITY, INSERT_CITY)}
                />
              </div>
              <select
                id="id_input_fm_4_sa_city_id"
                name="fm_4_sa_city_id"
                className="form-select"
                onChange={(evt) => setDpCityValue(evt.target.value)}
                value={dpCityValue}
                disabled={dpDistrictValue ? false : true}
                required
              >
                {cityList.length
                  ? cityList.map((listItem, index) => (
                      <option value={listItem?.cityId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select a City
              </small>
            </div>
            <div className="form-group col-12 col-sm-4 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_4_sa_customer_address_type_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Address Type
                </label>
                <RefreshCw
                  className="mx-3 mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() => setRefreshDpAddressType(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      CUSTOMER_ADDRESS_TYPE,
                      INSERT_CUSTOMER_ADDRESS_TYPE
                    )
                  }
                />
              </div>
              <select
                id="id_input_fm_4_sa_customer_address_type_id"
                name="fm_4_sa_customer_address_type_id"
                className="form-select"
                onChange={(evt) => setDpAddressTypeValue(evt.target.value)}
                value={dpAddressTypeValue}
                required
              >
                {addressTypeList.length
                  ? addressTypeList.map((listItem, index) => (
                      <option
                        value={listItem?.customerAddressTypeId}
                        key={index}
                      >
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <small className="invalid-feedback position-absolute">
                Please select an Address Type
              </small>
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-8 mb-3 mb-sm-0 position-relative">
              <label
                htmlFor="id_input_fm_4_address"
                className="required-input-mark"
              >
                Address
              </label>
              <input
                id="id_input_fm_4_address"
                name="fm_4_address"
                type="text"
                maxLength={250}
                className="form-control"
                placeholder="Enter Address Line"
                autoComplete="off"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    checkExpression: /[^\w/,'".-]+/,
                  })
                }
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter Address
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
            <div className="form-group col-12 col-sm-4 mx-1 mx-sm-0 d-flex align-items-end">
              <div className="checkbox checkbox-solid-dark">
                <input
                  id="id_input_fm_4_is_default"
                  name="fm_4_is_default"
                  type="checkbox"
                />
                <label htmlFor="id_input_fm_4_is_default" className="mt-0 mb-0">
                  Default
                </label>
              </div>
            </div>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_4_remark">Remark</label>
            <textarea
              id="id_input_fm_4_remark"
              name="fm_4_remark"
              maxLength={250}
              rows={3}
              className="form-control"
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {(checkIsAccessible(INSERTFUNCTION) && addressId === 0) ||
          (checkIsAccessible(DEACTIVATEFUNCTION) && addressId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6">
                <input
                  id="id_input_fm_4_is_active"
                  name="fm_4_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_3_is_active">Active</label>
              </div>
            </div>
          ) : null}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && addressId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {addressId ? "Update" : "Submit"}
                </button>
                <button
                  className="btn btn-warning col-12 col-sm-2"
                  type="reset"
                >
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Address List"
          columnData={tableColumnHeaderList}
          rowData={addressRowList}
        />
      </div>
    </>
  );
};

export default Address;
