import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import { Clock } from "react-feather";
import DetailRowLayout from "../../../common/DetailRowLayout";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";

const DetailsInSinhala = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileSinhalaTab",
        data: reqBody,
        check_for_errors: false,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>
        {isLoaded === LOADED ? (
          customerData.name === null &&
          customerData.address === null &&
          customerData.remark === null ? null : (
            <div
              className="d-flex align-items-center mb-2"
              style={{ minWidth: "max-content" }}
            >
              <Clock height="12px" className="text-secondary"></Clock>
              {customerData.updated === customerData.created
                ? "Created "
                : "Last Modified "}
              on {ConvertMillisToDate(customerData.updated)} By{" "}
              {customerData.updatedBy}
            </div>
          )
        ) : (
          <Skeleton className="w-25" />
        )}
      </div>
      <div>
        {isLoaded === LOADED ? (
          customerData.created ? (
            <div>
              <DetailRowLayout
                display={isLoaded === LOADED}
                labels={["Name"]}
                data={isLoaded === LOADED ? [customerData.name] : null}
              />
              <DetailRowLayout
                display={isLoaded === LOADED}
                labels={["Address"]}
                data={isLoaded === LOADED ? [customerData.address] : null}
              />
              <DetailRowLayout
                display={isLoaded === LOADED}
                labels={["Remark"]}
                data={isLoaded === LOADED ? [customerData.remark] : null}
              />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          // Loading Skeleton
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Name"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Address"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Remark"]}
              data={[]}
            />
          </>
        )}
        <div className="d-flex justify-content-end mt-4 mx-1">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_11/`
                )
              }
              className="btn btn-primary col-12 col-md-2"
            >
              Update
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DetailsInSinhala;
