import React, { useEffect, useState } from "react";
import { AlertTriangle } from "react-feather";
import { Alert } from "reactstrap";
import { LOADED, LOADING } from "../../../../constant";
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import { useSelector } from "react-redux";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import Swal from "sweetalert2";

const Remark = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form values
  const [remarkId, setRemarkId] = useState(0);

  // table data
  const [remarkRowList, setRemarkRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Remark",
    {
      name: "Show In Application",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editDetail] = useState(() => (remark_id, response_data) => {
    const editingRemark = response_data.find(
      (item) => item.customerRemarkId === remark_id
    );
    setRemarkId(editingRemark.customerRemarkId);
    document.querySelector("textarea[name='fm_15_remark']").value =
      editingRemark.remark;
    document.querySelector("input[name='fm_15_is_show_application']").checked =
      editingRemark.isActive;
    document.querySelector("input[name='fm_15_is_active']").checked =
      editingRemark.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let visitDetail = {
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        bpartnerId: parseInt(b_partner_id),
        customerRemarkId: parseInt(remarkId),
        remark: formData.get("fm_15_remark"),
        isShowApplication: formData.get("fm_15_is_show_application")
          ? true
          : false,
        isActive: formData.get("fm_15_is_active") ? true : false,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveRemarkTab",
          data: visitDetail,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: remarkId
              ? "Remark has successfully updated."
              : "New Remark has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setRemarkId(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewRemarkTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            let tempList = [];
            response_data.remarkList.forEach((list_item) => {
              tempList.push([
                list_item.customerRemarkId,
                list_item.remark,
                list_item.isShowApplication ? "True" : "False",
                list_item.isActive ? "True" : "False",
                <ControlButtons
                  editMethod={() =>
                    editDetail(
                      list_item.customerRemarkId,
                      response_data.remarkList
                    )
                  }
                  disableEdit={!checkIsAccessible(EDITFUNCTION)}
                  disableDelete={true}
                />,
              ]);
            });
            setRemarkRowList([...tempList]);
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Remarks.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-15 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_15_remark">Remark</label>
            <textarea
              id="id_input_fm_15_remark"
              name="fm_15_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remarks"
              required
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-row">
            <div className="checkbox checkbox-solid-dark col-12 col-sm-3 mx-1 mx-sm-0">
              <input
                id="id_input_fm_15_is_show_application"
                name="fm_15_is_show_application"
                type="checkbox"
              />
              <label
                htmlFor="id_input_fm_15_is_show_application"
                style={{ minWidth: "max-content" }}
              >
                Show In Application
              </label>
            </div>
            <div className="checkbox checkbox-solid-dark col-12 col-sm-3 mx-1 mx-sm-0">
              <input
                id="id_input_fm_15_is_active"
                name="fm_15_is_active"
                type="checkbox"
                defaultChecked
              />
              <label htmlFor="id_input_fm_15_is_active">Active</label>
            </div>
          </div>
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && remarkId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {remarkId ? "Update" : "Submit"}
                </button>
                <button
                  className="btn btn-warning col-12 col-sm-2"
                  type="reset"
                >
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Remark List"
          columnData={tableColumnHeaderList}
          rowData={remarkRowList}
        />
      </div>
    </>
  );
};

export default Remark;
