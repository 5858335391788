import React, { useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons, BooleanValue } from "../common/datatable";
import formValidation from "../../customHooks/useValidation";

// network requests
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import {
  DEACTIVATE_USER,
  DELETE_USER,
  EDIT_USER,
  INSERT_USER,
  USER,
} from "../../constant";

const User = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Master File", active: false },
    { value: "User", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, USER);
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [resData, setResData] = useState();
  const enteredPassword = useRef("");

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isF2Validated, setIsF2Validated] = useState(false);

  // form values
  const [roleList, setRoleList] = useState([{ roleId: "", name: "-Select-" }]);
  const [dpRoleValue, setDpRoleValue] = useState(0);
  const [employeeList, setEmployeeList] = useState([
    { bpartnerId: "", name: "-Select-" },
  ]);
  const [dpEmployeeValue, setDpEmployeeValue] = useState(0);
  const [userId, setUserId] = useState(0);

  // table data
  const [userRowList, setUserRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    // {
    //   name: "Client",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    // {
    //   name: "Organization",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    "Name",
    "Username",
    "Role",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display: checkIsAccessible(EDIT_USER) || checkIsAccessible(DELETE_USER),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editUser] = useState(() => (user_id, response_data) => {
    const editingUser = response_data.find((item) => item.userId === user_id);
    setIsF2Validated(false);
    setUserId(user_id);
    setDpRoleValue(editingUser.roleId);
    setDpEmployeeValue(editingUser.bpartnerId);
    document.querySelector("input[name='fm_1_username']").value =
      editingUser.username;
    document.querySelector("input[name='fm_1_is_active']").checked =
      editingUser.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteUser] = useState(() => (user_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this User!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          loggedUserId: logged_user.userId,
          userId: user_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/deleteUser",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "User has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      document
        .querySelectorAll("input:required,select:required")
        .forEach((elem) =>
          elem.previousSibling.classList.add("required-input-mark")
        );
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        loggedUserId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({ url: "/viewUser", data: reqBody });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.activeEmployeeList) {
            setEmployeeList((prevList) => [
              prevList[0],
              ...response_data.activeEmployeeList,
            ]);
          }
          if (response_data.allUserList) {
            setResData(response_data.allUserList);
          }
        }
      });
      const dpListRequestBody = { clientId: loggedUser.clientId };
      responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveRole",
          data: dpListRequestBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          setRoleList((prevList) => [prevList[0], ...response_data]);
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated, sendRequest]);

  useEffect(() => {
    if (resData) {
      let tempList = [];
      resData.forEach((listItem) => {
        if (loggedUser.orgId === listItem.orgId) {
          tempList.push([
            listItem.userId,
            // loggedUser.activeClientList?.find(
            //   (item) => item.clientId === listItem.clientId
            // ).name,
            // loggedUser.activeOrgRoleList?.find(
            //   (item) => item.orgId === listItem.orgId
            // ).name,
            listItem.employeeName,
            listItem.username,
            listItem.roleName,
            listItem.isActive ? "True" : "False",
            <ControlButtons
              editMethod={() => editUser(listItem.userId, resData)}
              disableEdit={!checkIsAccessible(EDIT_USER)}
              deleteMethod={() => deleteUser(listItem.userId, loggedUser)}
              disableDelete={!checkIsAccessible(DELETE_USER)}
            />,
          ]);
        }
      });
      setUserRowList([...tempList]);
      editUser(loggedUser.userId, resData);
    }
  }, [resData, editUser, deleteUser, loggedUser, checkIsAccessible]);

  // form control functions
  const resetForm = () => {
    setIsValidated(false);
    setUserId(0);
    setDpRoleValue(0);
    setDpEmployeeValue(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      console.log("user form validated");
      const formData = new FormData(evt.target);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkUsernameExist",
          data: {
            clientId: loggedUser.clientId,
            userId: userId,
            username: formData.get("fm_1_username"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case -1:
              let user = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                loggedUserId: parseInt(loggedUser.userId),
                userId: userId,
              };
              if (userId) {
                user = {
                  ...user,
                  roleId: parseInt(formData.get("fm_1_sa_role_id")),
                  isActive: document.querySelector(
                    "input[name='fm_1_is_active']"
                  )?.checked,
                  isPasswordChanged: false,
                };
              } else {
                user = {
                  ...user,
                  bpartnerId: parseInt(dpEmployeeValue),
                  roleId: parseInt(dpRoleValue),
                  isActive: document.querySelector(
                    "input[name='fm_1_is_active']"
                  )?.checked,
                  username: formData.get("fm_1_username"),
                  password: formData.get("fm_1_confirm_password"),
                };
              }
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveUser",
                  data: user,
                  template: "CONTROL_DATA",
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: userId
                      ? "User has successfully updated."
                      : "New User has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setRefreshTable(true);
                }
              });
              break;
            case 0:
              let user0 = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                loggedUserId: parseInt(loggedUser.userId),
                userId: userId,
              };
              if (userId) {
                user0 = {
                  ...user0,
                  roleId: parseInt(formData.get("fm_1_sa_role_id")),
                  isActive: document.querySelector(
                    "input[name='fm_1_is_active']"
                  )?.checked,
                  isPasswordChanged: false,
                };
              } else {
                user0 = {
                  ...user0,
                  bpartnerId: parseInt(dpEmployeeValue),
                  roleId: parseInt(dpRoleValue),
                  isActive: document.querySelector(
                    "input[name='fm_1_is_active']"
                  )?.checked,
                  username: formData.get("fm_1_username"),
                  password: formData.get("fm_1_confirm_password"),
                };
              }
              let nestedResponseData0 = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveUser",
                  data: user0,
                  template: "CONTROL_DATA",
                });
                resolve(result);
              });
              nestedResponseData0.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: userId
                      ? "User has successfully updated."
                      : "New User has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setRefreshTable(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Username Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };

  const submitNewPassword = (evt) => {
    setIsF2Validated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let user = {
        clientId: parseInt(loggedUser.clientId),
        orgId: parseInt(loggedUser.orgId),
        loggedUserId: parseInt(loggedUser.userId),
        userId: userId,
        isPasswordChanged: true,
        password: formData.get("fm_2_confirm_password"),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/saveUser",
          data: user,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: "User Passsword has successfully updated.",
            showConfirmButton: false,
            timer: 5000,
          });
          setRefreshTable(true);
        }
      });
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Master File"
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>User</h5>
                <span>Master File for creating a User</span>
              </div>
              <div className="card-body border-bottom">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-6 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_bpartner_id"
                        className="col-form-label pt-0"
                      >
                        Employee
                      </label>
                      <select
                        id="id_input_fm_1_sa_bpartner_id"
                        name="fm_1_sa_bpartner_id"
                        className="form-select"
                        onChange={(evt) => setDpEmployeeValue(evt.target.value)}
                        value={dpEmployeeValue}
                        required
                      >
                        {employeeList.length
                          ? employeeList.map((listItem, index) => (
                              <option value={listItem?.bpartnerId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select an Employee
                      </small>
                    </div>
                    <div className="form-group col-6 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_connected_source_id"
                        className="col-form-label pt-0"
                      >
                        Role
                      </label>
                      <select
                        id="id_input_fm_1_sa_role_id"
                        name="fm_1_sa_role_id"
                        className="form-select"
                        onChange={(evt) => setDpRoleValue(evt.target.value)}
                        value={dpRoleValue}
                        required
                      >
                        {roleList.length
                          ? roleList.map((listItem, index) => (
                              <option value={listItem?.roleId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Role
                      </small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-6 position-relative">
                      <label htmlFor="id_input_fm_1_username">Username</label>
                      <input
                        id="id_input_fm_1_username"
                        name="fm_1_username"
                        type="text"
                        maxLength={45}
                        className="form-control"
                        placeholder="Enter Username"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateName: true,
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Username
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  {!userId ? (
                    <>
                      <div className="form-row mb-4">
                        <div className="form-group col-6 position-relative">
                          <label htmlFor="id_input_fm_1_password">
                            Password
                          </label>
                          <input
                            ref={enteredPassword}
                            id="id_input_fm_1_password"
                            name="fm_1_password"
                            type="password"
                            maxLength={45}
                            minLength={8}
                            className="form-control"
                            placeholder="Enter Password"
                            onChange={(evt) =>
                              formValidation({
                                event: evt,
                                validatePassword: true,
                              })
                            }
                            required
                          />
                          <small className="invalid-feedback position-absolute">
                            Please enter a Password
                          </small>
                          <small className="txt-danger position-absolute d-none input-validation"></small>
                        </div>
                        <div className="form-group col-6 position-relative">
                          <label htmlFor="id_input_fm_1_confirm_password">
                            Confirm Password
                          </label>
                          <input
                            id="id_input_fm_1_confirm_password"
                            name="fm_1_confirm_password"
                            type="password"
                            maxLength={45}
                            className="form-control"
                            placeholder="Re-Enter Password"
                            onChange={(evt) =>
                              formValidation({
                                event: evt,
                                confirmPassword: true,
                                enteredPassword: enteredPassword.current.value,
                              })
                            }
                            required
                          />
                          <small className="invalid-feedback position-absolute">
                            Please re-enter Password
                          </small>
                          <small className="txt-danger position-absolute d-none input-validation"></small>
                        </div>
                      </div>
                      <div className="text-warning ">
                        Passsword Requirements
                        <ol className=" d-flex flex-column">
                          <li>Minimum character length of password is 8.</li>
                          <li>
                            Passowrd must consist of at least a single character
                            from Uppercase Letters, Lowercase Letters, Digits
                            and Special Characters (!@#$%^&*-_+).
                          </li>
                          <li>
                            New password should not be a password from last 5
                            passwords used.
                          </li>
                          <li>
                            Password will be expired in 60 days. You must
                            changed the password before expiration.
                          </li>
                        </ol>
                      </div>
                    </>
                  ) : null}
                  <div className="form-group mb-4">
                    <div className="checkbox checkbox-solid-dark col-6">
                      <input
                        id="id_input_fm_1_is_active"
                        name="fm_1_is_active"
                        type="checkbox"
                        disabled={!checkIsAccessible(DEACTIVATE_USER)}
                        defaultChecked
                      />
                      <label htmlFor="id_input_fm_1_is_active">Active</label>
                    </div>
                  </div>
                  {checkIsAccessible(INSERT_USER) ||
                  (checkIsAccessible(EDIT_USER) && userId !== 0) ? (
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-primary col-2 mx-3"
                        type="submit"
                      >
                        {userId ? "Update" : "Submit"}
                      </button>
                      <button className="btn btn-warning col-2" type="reset">
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
              {userId ? (
                <div className="card-body">
                  <form
                    onSubmit={(evt) => submitNewPassword(evt)}
                    className={`form-2 ${isF2Validated ? "was-validated" : ""}`}
                    noValidate
                  >
                    <div className={`form-row mb-4 ${userId ? "" : "d-none"}`}>
                      <div className="form-group col-6 position-relative">
                        <label htmlFor="id_input_fm_2_password">Password</label>
                        <input
                          ref={enteredPassword}
                          id="id_input_fm_2_password"
                          name="fm_2_password"
                          type="password"
                          maxLength={45}
                          minLength={8}
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={(evt) =>
                            formValidation({
                              event: evt,
                              validatePassword: true,
                            })
                          }
                          required
                        />
                        <small className="invalid-feedback position-absolute">
                          Please enter a Password
                        </small>
                        <small className="txt-danger position-absolute d-none input-validation"></small>
                      </div>
                      <div className="form-group col-6 position-relative">
                        <label htmlFor="id_input_fm_2_confirm_password">
                          Confirm Password
                        </label>
                        <input
                          id="id_input_fm_2_confirm_password"
                          name="fm_2_confirm_password"
                          type="password"
                          maxLength={45}
                          className="form-control"
                          placeholder="Re-Enter Password"
                          onChange={(evt) =>
                            formValidation({
                              event: evt,
                              confirmPassword: true,
                              enteredPassword: enteredPassword.current.value,
                            })
                          }
                          required
                        />
                        <small className="invalid-feedback position-absolute">
                          Please re-enter Password
                        </small>
                        <small className="txt-danger position-absolute d-none input-validation"></small>
                      </div>
                    </div>
                    <div className="text-warning ">
                      Passsword Requirements
                      <ol className=" d-flex flex-column">
                        <li>Minimum character length of password is 8.</li>
                        <li>
                          Passowrd must consist of at least a single character
                          from Uppercase Letters, Lowercase Letters, Digits and
                          Special Characters (!@#$%^&*-_+).
                        </li>
                        <li>
                          New password should not be a password from last 5
                          passwords used.
                        </li>
                        <li>
                          Password will be expired in 60 days. You must changed
                          the password before expiration.
                        </li>
                      </ol>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-outline-primary col-2 mx-3"
                        type="submit"
                      >
                        Change Password
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
            <div className="mb-4">
              <Datatable
                titleData="User List"
                columnData={tableColumnHeaderList}
                rowData={userRowList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
