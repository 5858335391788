import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardHeader, CardBody, Button, Collapse } from "reactstrap";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ReactTable from "react-data-table-component";
import { ChevronDown, ChevronUp, Info } from "react-feather";
import formValidation from "../../customHooks/useValidation";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import NoDetailsFound from "../../assets/images/not-details-found.png";
import Swal from "sweetalert2";
import { LoadStaticURL } from "../../data/util/LoadStaticURL";
import ConfigDB from "../../data/customizer/config";

const ExpandedComponent = ({ data }) => (
  <pre className="px-3">{JSON.stringify(data, null, 2)}</pre>
);

const View = () => {
  const SEARCH_BY_CUSID = 0;
  const SEARCH_BY_NAME = 1;
  const SEARCH_BY_NIC = 2;
  const SEARCH_BY_REF = 3;
  const SEARCH_BY_PASSPORT = 4;
  const LOADING = 0;
  const LOADED = 1;

  // breadcrumb values
  let childLinks = [
    { value: "Customer", active: false },
    { value: "View", active: true },
  ];

  const dispatch = useDispatch();
  const sendRequest = useAxiosTemplates();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // interface controllers
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [toggleOrganizations, setToggleOrganizations] = useState(false);
  // const [searchType, setSearchType] = useState(0);
  const [filterAttributes, setFilterAttributes] = useState({
    clientId: 0,
    orgIdList: loggedUser.activeOrgRoleList.map((org_item) => org_item.orgId),
    orgId: 0,
    userId: 0,
    customerId: "",
    referenceId: "",
    customerName: "",
    nic: "",
    districtId: -1,
    cc1DispositionId: -1,
    cc2DispositionId: -1,
    male: false,
    female: false,
    minAge: 0,
    maxAge: 0,
  });
  const searchValue = useRef("");
  const minAgeValue = useRef(0);
  const maxAgeValue = useRef(0);
  const maleChecked = useRef(false);
  const femaleChecked = useRef(false);
  const [districtList, setDistrictList] = useState([]);
  const [cc1DispositionList, setcc1DispositionList] = useState([]);
  const [cc2DispositionList, setcc2DispositionList] = useState([]);
  const [districtValue, setDistrictValue] = useState(-1);
  const [cc1Value, setCC1Value] = useState(-1);
  const [cc2Value, setCC2Value] = useState(-1);
  const [popover, setPopover] = useState(false);
  const OffsetToggle = () => setPopover(!popover);

  // table data
  const [customerRowList, setCustomerRowList] = useState([]);
  const Columns = [
    {
      name: "Client Id",
      selector: (row) => `${row[0].clientId}`,
      sortable: true,
      center: false,
      omit: true,
    },
    {
      name: "Org Id",
      selector: (row) => `${row[0].orgId}`,
      sortable: true,
      center: false,
      omit: true,
    },
    {
      name: "Bpartner Id",
      selector: (row) => `${row[0].bpartnerId}`,
      sortable: true,
      center: false,
      omit: true,
    },
    {
      name: "Customer Id",
      selector: (row) => `${row[0].customerId}`,
      sortable: true,
      center: false,
    },
    {
      name: "Name",
      selector: (row) => `${row[0].fullName}`,
      sortable: true,
      center: false,
    },
    {
      name: "NIC",
      selector: (row) => `${row[0].nic}`,
      sortable: true,
      center: false,
    },
    {
      name: "Age",
      selector: (row) => `${row[0].age}`,
      sortable: true,
      center: true,
    },
    {
      name: "Passport",
      selector: (row) => `${row[0].passportNo}`,
      sortable: true,
      center: false,
    },
    {
      name: "Reference",
      selector: (row) => `${row[0].referenceNo ? row[0].referenceNo : ""}`,
      sortable: true,
      center: false,
    },
  ];
  // table functions
  const onRowClick = (row_data) => {
    console.log(row_data[0]);
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: row_data[0].bpartnerId,
        clientId: row_data[0].clientId,
        orgId: row_data[0].orgId,
        searchKey: row_data[0].customerId,
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${row_data[0].customerId}/`,
      "_blank"
    );
  };

  const getFilteredData = () => {
    // checking for applied filters
    if (
      parseInt(districtValue) <= 0 &&
      parseInt(cc1Value) <= 0 &&
      parseInt(cc2Value) <= 0 &&
      maleChecked.current.checked === false &&
      femaleChecked.current.checked === false &&
      searchValue.current.value === "" &&
      maxAgeValue.current.value === "" &&
      minAgeValue.current.value === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Try Again",
        text: "You have not applied any filter.",
        showDenyButton: false,
        confirmButtonText: "Ok",
      });
    } else {
      console.log(filterAttributes);
      setIsLoaded(LOADING);
      // getting applied filters
      setFilterAttributes((prev) => ({
        ...prev,
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      }));
      // --> organizations
      let tempActiveOrgList = [];
      loggedUser.activeOrgRoleList.forEach((org_item) => {
        if (
          document.querySelector(`#id_input_filter_org_id_${org_item.orgId}`)
            .checked
        ) {
          tempActiveOrgList.push(org_item.orgId);
        }
      });
      setFilterAttributes((prev) => ({
        ...prev,
        orgIdList: tempActiveOrgList,
      }));
      // -->search value
      let searchType = SEARCH_BY_NAME;
      if (searchValue.current.value.match(/CUS.+/)) {
        searchType = SEARCH_BY_CUSID;
      } else if (searchValue.current.value.match(/[\w]+-[\w]+-[\w]+/)) {
        searchType = SEARCH_BY_REF;
      } else if (
        searchValue.current.value.match(/^[\d]+[VvXx]$/) ||
        !searchValue.current.value.match(/[\D]/)
      ) {
        searchType = SEARCH_BY_NIC;
      } else if (searchValue.current.value.match(/^[A-Z]\d{7,}$/)) {
        searchType = SEARCH_BY_PASSPORT;
      }
      switch (searchType) {
        case SEARCH_BY_CUSID:
          setFilterAttributes((prev) => ({
            ...prev,
            customerId: searchValue.current.value,
            customerName: "",
            nic: "",
            referenceId: "",
            passportNo: "",
          }));
          break;
        case SEARCH_BY_REF:
          setFilterAttributes((prev) => ({
            ...prev,
            referenceId: searchValue.current.value,
            customerId: "",
            customerName: "",
            nic: "",
            passportNo: "",
          }));
          break;
        case SEARCH_BY_NAME:
          setFilterAttributes((prev) => ({
            ...prev,
            customerName: searchValue.current.value,
            customerId: "",
            nic: "",
            referenceId: "",
            passportNo: "",
          }));
          break;
        case SEARCH_BY_NIC:
          setFilterAttributes((prev) => ({
            ...prev,
            nic: searchValue.current.value,
            customerId: "",
            customerName: "",
            referenceId: "",
            passportNo: "",
          }));
          break;
        case SEARCH_BY_PASSPORT:
          setFilterAttributes((prev) => ({
            ...prev,
            nic: "",
            customerId: "",
            customerName: "",
            referenceId: "",
            passportNo: searchValue.current.value,
          }));
          break;
        default:
          break;
      }
      // --> gender value
      if (femaleChecked.current.checked) {
        setFilterAttributes((prev) => ({
          ...prev,
          male: false,
          female: true,
        }));
      }
      if (maleChecked.current.checked) {
        setFilterAttributes((prev) => ({
          ...prev,
          male: true,
          female: false,
        }));
      }
      // --> district value
      setFilterAttributes((prev) => ({ ...prev, districtId: districtValue }));
      // --> cc1 value
      setFilterAttributes((prev) => ({
        ...prev,
        cc1DispositionId: cc1Value,
      }));
      // --> cc2 value
      setFilterAttributes((prev) => ({
        ...prev,
        cc2DispositionId: cc2Value,
      }));
      // --> age range
      if (minAgeValue.current.value !== "") {
        setFilterAttributes((prev) => ({
          ...prev,
          minAge: parseInt(minAgeValue.current.value),
        }));
      } else {
        setFilterAttributes((prev) => ({
          ...prev,
          minAge: 0,
        }));
      }
      if (maxAgeValue.current.value !== "") {
        setFilterAttributes((prev) => ({
          ...prev,
          maxAge: parseInt(maxAgeValue.current.value),
        }));
      } else {
        setFilterAttributes((prev) => ({
          ...prev,
          maxAge: 0,
        }));
      }
    }
  };

  const changeDistrict = (elem) => {
    console.log(elem);
    if (elem.checked) {
      districtList.forEach((district_item) => {
        if (district_item.districtId !== parseInt(elem.value))
          document.querySelector(
            `#id_input_filter_district_id_${district_item.districtId}`
          ).checked = false;
      });
      setDistrictValue(elem.value);
    } else {
      setDistrictValue(-1);
    }
  };
  const changeCC1Disposition = (elem) => {
    if (elem.checked) {
      cc1DispositionList.forEach((cc1_item) => {
        if (cc1_item.cc1DispositionId !== parseInt(elem.value))
          document.querySelector(
            `#id_input_filter_cc1_id_${cc1_item.cc1DispositionId}`
          ).checked = false;
      });
      setCC1Value(elem.value);
    } else {
      setCC1Value(-1);
    }
  };
  const changeCC2Disposition = (elem) => {
    if (elem.checked) {
      cc2DispositionList.forEach((cc2_item) => {
        if (cc2_item.cc2DispositionId !== parseInt(elem.value))
          document.querySelector(
            `#id_input_filter_cc2_id_${cc2_item.cc2DispositionId}`
          ).checked = false;
      });
      setCC2Value(elem.value);
    } else {
      setCC2Value(-1);
    }
  };
  const changeGender = (value) => {
    if (value === "MALE") {
      femaleChecked.current.checked = false;
    } else {
      maleChecked.current.checked = false;
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let reqData = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      resultCount: 100,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewSearchProfileTab",
        data: reqData,
      });
      resolve(result);
    }).then((response_data) => {
      let tempList = [];
      if (response_data.customerList.length > 0) {
        response_data.customerList.forEach((customer) => {
          tempList.push([
            {
              bpartnerId: customer.bpartnerId,
              clientId: customer.clientId,
              orgId: customer.orgId,
              customerId: customer.customerId,
              referenceNo: customer.referenceId,
              fullName: customer.fullName,
              age: customer.age,
              nic: customer.nic,
              passportNo: customer.passportNo
            },
          ]);
        });
        setCustomerRowList(tempList);
      }
      if (response_data.activeDistrictList.length > 0) {
        setDistrictList([...response_data.activeDistrictList]);
      }
      if (response_data.activeCc1DispositionList.length > 0) {
        setcc1DispositionList([...response_data.activeCc1DispositionList]);
      }
      if (response_data.activeCc2DispositionList.length > 0) {
        setcc2DispositionList([...response_data.activeCc2DispositionList]);
      }
      loggedUser.activeOrgRoleList.forEach((org_item) => {
        document.querySelector(
          `#id_input_filter_org_id_${org_item.orgId}`
        ).checked = true;
      });
      setIsLoaded(LOADED);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterAttributes.orgIdList.length > 0) {
      if (
        filterAttributes.clientId &&
        filterAttributes.orgId &&
        filterAttributes.userId
      )
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/searchProfile",
            data: filterAttributes,
          });
          resolve(result);
        }).then((response_data) => {
          let tempList = [];
          if (response_data.customerList.length > 0) {
            response_data.customerList.forEach((customer) => {
              tempList.push([
                {
                  bpartnerId: customer.bpartnerId,
                  clientId: customer.clientId,
                  orgId: customer.orgId,
                  customerId: customer.customerId,
                  referenceNo: customer.referenceId,
                  fullName: customer.fullName,
                  age: customer.age,
                  nic: customer.nic,
                  passportNo: customer.passportNo    
                },
              ]);
            });
            setCustomerRowList(tempList);
          } else {
            setCustomerRowList([]);
          }
          setIsLoaded(LOADED);
        });
    } else {
      setIsLoaded(LOADED);
      Swal.fire({
        icon: "warning",
        title: "Try Again",
        text: "You must select an Organization",
        showDenyButton: false,
        confirmButtonText: "Ok",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterAttributes]);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Customer" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-3">
            <div
              onClick={() => getFilteredData()}
              className="btn btn-primary w-100 mb-4"
            >
              Apply Filters
            </div>
            <Card>
              <CardHeader className="px-4 py-2 rounded">
                <div
                  className="d-flex align-items-center justify-content-between btn p-0"
                  onClick={() => setToggleOrganizations((prev) => !prev)}
                >
                  <Button
                    as={Card.Header}
                    className="p-0 fw-bold txt-primary"
                    color="default"
                  >
                    Organizations
                  </Button>
                  {toggleOrganizations ? (
                    <ChevronUp className="txt-primary" height={"20px"} />
                  ) : (
                    <ChevronDown className="txt-primary" height={"20px"} />
                  )}
                </div>
              </CardHeader>
              <Collapse isOpen={toggleOrganizations} className="p-4">
                {loggedUser.activeOrgRoleList.map((list_item, index) => (
                  <div className="checkbox ml-2" key={index}>
                    <input
                      id={`id_input_filter_org_id_${list_item.orgId}`}
                      type="checkbox"
                      value={list_item.orgId}
                    />
                    <label
                      htmlFor={`id_input_filter_org_id_${list_item.orgId}`}
                    >
                      {list_item.name}
                    </label>
                  </div>
                ))}
              </Collapse>
            </Card>
            <Card>
              <CardHeader className="px-4 py-2 rounded">
                <div
                  className="d-flex align-items-center justify-content-between btn p-0"
                  onClick={() => setToggleSearch((prev) => !prev)}
                >
                  <div className="d-flex align-items-center">
                    <Button
                      as={Card.Header}
                      className="p-0 fw-bold txt-primary"
                      color="default"
                    >
                      Search
                    </Button>
                    <>
                      <Popover
                        placement="top"
                        isOpen={popover}
                        target="help-search-popover"
                        toggle={OffsetToggle}
                        offset={[50, 20]}
                        trigger="hover"
                      >
                        <PopoverHeader>Customer Search</PopoverHeader>
                        <PopoverBody>
                          User can search for customer, by using Customer ID,
                          Customer Name, NIC, Passport Number or Reference
                          Number.
                        </PopoverBody>
                      </Popover>
                    </>
                    <Info
                      id="help-search-popover"
                      className="pointer text-secondary"
                      height={"12px"}
                    />
                  </div>
                  {toggleSearch ? (
                    <ChevronUp className="txt-primary" height={"20px"} />
                  ) : (
                    <ChevronDown className="txt-primary" height={"20px"} />
                  )}
                </div>
              </CardHeader>
              <Collapse isOpen={toggleSearch}>
                <CardBody>
                  <input
                    ref={searchValue}
                    type="text"
                    className="form-control mb-2"
                    placeholder="search"
                    onKeyDown={(e) => {
                      if (e.code === "Enter") getFilteredData();
                    }}
                  />
                  <div className="d-flex align-items-start rounded">
                    <Info className="text-info" height={"16px"} />
                    <small className="ml-2 text-info">
                      Press <span className="fw-bold">Enter</span> or Click{" "}
                      <span className="fw-bold">Apply Filters</span> Button to
                      start searching.
                    </small>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
            <Card>
              <CardHeader className="px-4 py-2 rounded">
                <div
                  className="d-flex align-items-center justify-content-between btn p-0"
                  onClick={() => setToggleFilter((prev) => !prev)}
                >
                  <Button
                    as={Card.Header}
                    className="p-0 fw-bold txt-primary"
                    color="default"
                  >
                    Filter
                  </Button>
                  {toggleFilter ? (
                    <ChevronUp className="txt-primary" height={"20px"} />
                  ) : (
                    <ChevronDown className="txt-primary" height={"20px"} />
                  )}
                </div>
              </CardHeader>
              <Collapse isOpen={toggleFilter} className="p-4">
                {/* district filters */}
                {districtList.length > 0 ? (
                  <div className="mb-4">
                    <div className="fw-bold">District</div>
                    {districtList.map((list_item, index) => (
                      <div className="checkbox ml-2" key={index}>
                        <input
                          id={`id_input_filter_district_id_${list_item.districtId}`}
                          type="checkbox"
                          onChange={(evt) => changeDistrict(evt.target)}
                          value={list_item.districtId}
                        />
                        <label
                          htmlFor={`id_input_filter_district_id_${list_item.districtId}`}
                        >
                          {list_item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : null}
                {/* gender filters */}
                <div className="mb-4">
                  <div className="fw-bold">Gender</div>
                  <div className="checkbox ml-2">
                    <input
                      id={`id_input_filter_male`}
                      ref={maleChecked}
                      type="checkbox"
                      onChange={(evt) => changeGender("MALE")}
                    />
                    <label htmlFor="id_input_filter_male">Male</label>
                  </div>
                  <div className="checkbox ml-2">
                    <input
                      id={`id_input_filter_female`}
                      ref={femaleChecked}
                      type="checkbox"
                      onChange={(evt) => changeGender("FEMALE")}
                    />
                    <label htmlFor="id_input_filter_female">Female</label>
                  </div>
                </div>
                {/* cc1 disposition filters */}
                {cc1DispositionList.length > 0 ? (
                  <div className="mb-4">
                    <div className="fw-bold">CC1 Disposition</div>
                    {cc1DispositionList.map((list_item, index) => (
                      <div className="checkbox ml-2" key={index}>
                        <input
                          id={`id_input_filter_cc1_id_${list_item.cc1DispositionId}`}
                          type="checkbox"
                          onChange={(evt) => changeCC1Disposition(evt.target)}
                          value={list_item.cc1DispositionId}
                        />
                        <label
                          htmlFor={`id_input_filter_cc1_id_${list_item.cc1DispositionId}`}
                        >
                          {list_item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : null}
                {/* cc2 disposition filters */}
                {cc2DispositionList.length > 0 ? (
                  <div className="mb-4">
                    <div className="fw-bold">CC2 Disposition</div>
                    {cc2DispositionList.map((list_item, index) => (
                      <div className="checkbox ml-2" key={index}>
                        <input
                          id={`id_input_filter_cc2_id_${list_item.cc2DispositionId}`}
                          type="checkbox"
                          onChange={(evt) => changeCC2Disposition(evt.target)}
                          value={list_item.cc2DispositionId}
                        />
                        <label
                          htmlFor={`id_input_filter_cc2_id_${list_item.cc2DispositionId}`}
                        >
                          {list_item.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : null}
                {/* age range filters */}
                <div className="mb-4">
                  <div className="fw-bold mb-2">Age Range</div>
                  <div className="d-flex align-items-center">
                    <input
                      ref={minAgeValue}
                      type="number"
                      className="form-control mb-2"
                      placeholder="Min"
                      min={0}
                      max={100}
                      maxLength={3}
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          validateNumber: true,
                        })
                      }
                    />
                    <div className="mx-2"></div>
                    <input
                      ref={maxAgeValue}
                      type="number"
                      className="form-control mb-2"
                      placeholder="Max"
                      min={0}
                      max={100}
                      maxLength={3}
                      onChange={(evt) =>
                        formValidation({
                          event: evt,
                          validateNumber: true,
                        })
                      }
                    />
                  </div>
                </div>
              </Collapse>
            </Card>
          </div>
          <div className="col-sm-9">
            <div className="mb-4">
              <div className="p-4 bg-white rounded position-relative">
                {isLoaded === LOADING ? (
                  <div className="form-loader d-flex flex-column justify-content-center align-items-center">
                    <div className="loader-box">
                      <div className="loader">
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                        <div className="line bg-primary"></div>
                      </div>
                    </div>
                    <div>Loading...</div>
                  </div>
                ) : null}
                {customerRowList.length > 0 ? (
                  <ReactTable
                    columns={Columns}
                    data={customerRowList}
                    pagination
                    noHeader
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    onRowClicked={(row, event) => onRowClick(row)}
                  />
                ) : (
                  <div className="d-flex flex-column align-items-center">
                    <img
                      src={LoadStaticURL(NoDetailsFound)}
                      alt=""
                      width={"200px"}
                    />
                    <p className="ml-2 fs-5 fw-bold text-warning">
                      No Records Found.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
