import React, { useEffect, useState } from "react";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import { Alert } from "reactstrap";
import {
  INSERT_JOB_CATEGORY,
  INSERT_SKILL,
  JOB_CATEGORY,
  LOADED,
  LOADING,
  SKILL,
  SUBMITTING,
} from "../../../../constant";
import formValidation from "../../../../customHooks/useValidation";
import { useSelector } from "react-redux";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import ConfigDB from "../../../../data/customizer/config";
import Swal from "sweetalert2";

const Skill = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshSkillList, setRefreshSkillList] = useState(false);
  const [refreshDpJobCategory, setRefreshDpJobCategory] = useState(false);

  // form values
  const [skillList, setSkillList] = useState([]);
  const [jobList, setJobList] = useState([
    { jobCategoryId: 0, name: "- Select -" },
  ]);
  const [dpJobValue, setDpJobValue] = useState(0);

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewSkillTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        setJobList((prev_item) => [
          prev_item[0],
          ...response_data.customerJobCategoryList,
        ]);
        if (response_data.customerSkillTableList.length > 0) {
          setDpJobValue(response_data.customerSkillTableList[0].jobCategoryId);
          // setSkillList(response_data.customerSkillTableList[0].skillList);
        }
      }
      setIsLoaded(LOADED);
    });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dpJobValue > 0) {
      setIsLoaded(LOADING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpJobValue]);

  useEffect(() => {
    if (isLoaded === LOADING) {
      const reqBody = {
        bpartnerId: b_partner_id,
        jobCategoryId: dpJobValue,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/getSkill",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setSkillList(response_data.skillList);
        }
        setIsLoaded(LOADED);
      });
    }
  }, [isLoaded]);

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let selectedSkills = [];
      skillList.forEach((skill_item) => {
        selectedSkills.push({
          skillId: skill_item.skillId,
          isCan: !!formData.get(`fm_14_sk_${skill_item.skillId}_skilled`),
          isShowApplication: !!formData.get(
            `fm_14_sk_${skill_item.skillId}_isshowinapplication`
          ),
          remark: formData.get(`fm_14_sk_${skill_item.skillId}_remark`),
        });
      });
      let skill = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        jobCategoryId: dpJobValue,
        skillList: selectedSkills,
      };
      setIsLoaded(SUBMITTING);
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveSkillTab",
          data: skill,
          template: "CONTROL_DATA",
        });
        resolve(result);
      }).then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: "Customer Skills has successfully updated.",
            showConfirmButton: false,
            timer: 5000,
          });
        }
        setIsLoaded(LOADING);
      });
    }
  };

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <Alert
        className="alert-dismissible d-flex align-items-center"
        color="warning"
        isOpen={b_partner_id === 0}
      >
        <AlertTriangle />
        <p className="ml-2">
          You must create a Customer, before add Skill Details.
        </p>
      </Alert>
      <Alert
        className="alert-dismissible d-flex align-items-center"
        color="warning"
        isOpen={jobList.length === 0}
      >
        <AlertTriangle />
        <p className="ml-2">First, add data in Job Tab.</p>
      </Alert>
      <form
        onSubmit={(evt) => submitForm(evt)}
        className={`position-relative form-14 ${
          isValidated ? "was-validated" : ""
        }`}
        noValidate
      >
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex justify-content-center align">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-group col-12 col-sm-4 position-relative mb-4">
          <div clasasName="d-flex">
            <label
              htmlFor="id_input_fm_14_sa_job_category_id"
              className="col-form-label pt-0 required-input-mark"
            >
              Job Category
            </label>
            <RefreshCw
              className={`mx-3 mt-1 dp-refresh-icon ${
                refreshDpJobCategory ? "rotate-icon" : ""
              }`}
              height={"14px"}
              onClick={() => setRefreshDpJobCategory(true)}
            />
            <PlusCircle
              className="mt-1 dp-refresh-icon"
              height={"14px"}
              onClick={() =>
                openMasterFileWindow(JOB_CATEGORY, INSERT_JOB_CATEGORY)
              }
            />
          </div>
          <select
            id="id_input_fm_14_sa_job_category_id"
            name="fm_14_sa_job_category_id"
            className="form-select"
            onChange={(evt) => setDpJobValue(evt.target.value)}
            value={dpJobValue}
          >
            {jobList.length
              ? jobList.map((list_item, index) => (
                  <option value={list_item?.jobCategoryId} key={index}>
                    {list_item?.name}
                  </option>
                ))
              : null}
          </select>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-3 d-flex">
            <div className="d-flex">
              <label className="col-form-label pt-0 fw-bold">Skill</label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshSkillList ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshSkillList(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() => openMasterFileWindow(SKILL, INSERT_SKILL)}
              />
            </div>
          </div>
          <div className="form-group col-3 d-flex justify-content-center">
            <label
              htmlFor="id_input_fm_2_is_most_interested_job"
              className="mt-0 mb-0 fw-bold"
            >
              Skilled In
            </label>
          </div>
          <div className="form-group col-3 d-flex justify-content-center">
            <label
              htmlFor="id_input_fm_2_is_most_interested_job"
              className="mt-0 mb-0 fw-bold"
            >
              Show In Application
            </label>
          </div>
          <div className="form-group col-3 d-flex">
            <label
              htmlFor="id_input_fm_2_is_most_interested_job"
              className="mt-0 mb-0 fw-bold"
            >
              Remark
            </label>
          </div>
        </div>
        {isLoaded === LOADED && skillList.length > 0 ? (
          skillList.map((skill_item, index) => (
            <div key={index} className="form-row mb-4 align-items-center">
              <div className="form-group col-3 d-flex">
                <label className="col-form-label mt-1">
                  {skill_item.skillName}
                </label>
              </div>
              <div className="form-group col-3 d-flex justify-content-center">
                <div className="checkbox checkbox-solid-dark mb-2">
                  <input
                    id={`id_fm_14_sk_${skill_item.skillId}_skilled`}
                    name={`fm_14_sk_${skill_item.skillId}_skilled`}
                    type="checkbox"
                    defaultChecked={skill_item.isCan}
                  />
                  <label
                    htmlFor={`id_fm_14_sk_${skill_item.skillId}_skilled`}
                  ></label>
                </div>
              </div>
              <div className="form-group col-3 d-flex justify-content-center">
                <div className="checkbox checkbox-solid-dark mb-2">
                  <input
                    id={`id_fm_14_sk_${skill_item.skillId}_isshowinapplication`}
                    name={`fm_14_sk_${skill_item.skillId}_isshowinapplication`}
                    type="checkbox"
                    defaultChecked={skill_item.isShowApplication}
                  />
                  <label
                    htmlFor={`id_fm_14_sk_${skill_item.skillId}_isshowinapplication`}
                    className=""
                  ></label>
                </div>
              </div>
              <div className="form-group col-3 d-flex ">
                <textarea
                  className="form-control"
                  id={`id_fm_14_sk_${skill_item.skillId}_remark`}
                  name={`fm_14_sk_${skill_item.skillId}_remark`}
                  maxLength={250}
                  onChange={(evt) =>
                    formValidation({ event: evt, checkExpression: "" })
                  }
                  value={skill_item.remark}
                ></textarea>
              </div>
            </div>
          ))
        ) : (
          <Alert
            className="alert-dismissible d-flex align-items-center bg-white rounded"
            color="warning"
            isOpen={true}
          >
            <AlertTriangle className="txt-warning" />
            <p className="ml-2 txt-warning fw-bold">
              Selected Job Category has no mapped skill set.
            </p>
          </Alert>
        )}
        {b_partner_id && jobList.length > 0 ? (
          <div className="d-sm-flex justify-content-end">
            <button
              className="btn btn-primary col-12 col-sm-2 mb-2 mb-sm-0"
              type="submit"
            >
              Update
            </button>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default Skill;
