import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import { CHANGE_ORG } from "../../../redux/actionTypes";

const Organization = () => {
  const dispatch = useDispatch();

  // custom hooks
  const sendRequest = useAxiosTemplates();

  // stored data
  const location = useLocation();
  const loggedUser = useSelector((content) => content.UserReducer);
  const [selected, setSelected] = useState(
    loggedUser.activeOrgRoleList.find((org) => org.orgId === loggedUser.orgId)
      ?.name
  );

  useEffect(() => {
    if (location.pathname !== "/login") {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        device: "windows PC",
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/updateLoggedOrg",
          data: reqBody,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeOrganization = (org) => {
    setSelected(org.name);
    const reqBody = {
      orgId: org.orgId,
      roleId: loggedUser.userRoleId,
    };
    let responseData = new Promise((resolve) => {
      const result = sendRequest({
        url: "/getUserRoleLine",
        data: reqBody,
      });
      resolve(result);
    });
    responseData.then((response_data) => {
      dispatch({
        type: CHANGE_ORG,
        payload: {
          orgId: org.orgId,
          roleList: response_data,
        },
      });
      localStorage.setItem("store_updated", true);
      window.location.reload(false);
    });
  };

  return (
    <Fragment>
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="txt-dark">
          <h6
            className="d-none d-md-block text-uppercase"
            style={{ minWidth: "max-content" }}
          >
            {selected}
          </h6>
          <div className="d-block d-md-none txt-primary">
            <i className="fa fa-building-o"></i>
          </div>
        </a>
        <ul className="language-dropdown onhover-show-div p-20">
          {loggedUser.activeOrgRoleList.map((list_item, index) => (
            <li
              onClick={() => changeOrganization(list_item)}
              className="d-flex"
              key={index}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a data-lng="en">
                <i className="fa fa-building-o"></i> {list_item.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
};

export default Organization;
