import { useEffect, useRef, useState } from "react";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import { Alert } from "reactstrap";
import Swal from "sweetalert2";
import {
  INSERT_LANGUAGE,
  LANGUAGE,
  LOADED,
  LOADING,
  PROFICIENCY_LIST,
} from "../../../../constant";
import useAccess from "../../../../customHooks/useAccess";
import ConfigDB from "../../../../data/customizer/config";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";

const Language = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpLanguage, setRefreshDpLanguage] = useState(true);

  // form values
  const [languageList, setlanguageList] = useState([]);
  const [proficiencyList] = useState(PROFICIENCY_LIST);
  const selectedLanguages = useRef([]);

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    if (refreshDpLanguage) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: b_partner_id,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "customer/viewLanguageTab",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setlanguageList([...response_data.languageList]);
        }
        setRefreshDpLanguage(false);
        setIsLoaded(LOADED);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpLanguage]);

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      selectedLanguages.current = [];
      languageList.forEach((langauge_item) => {
        selectedLanguages.current.push({
          languageId: langauge_item.languageId,
          isCan: !!formData.get(
            `fm_13_lan_${langauge_item.languageId}_canspeak`
          ),
          isShowApplication: !!formData.get(
            `fm_13_lan_${langauge_item.languageId}_isshowinapplication`
          ),
          remark: formData.get(
            `fm_13_lan_${langauge_item.languageId}_proficiency`
          ),
        });
      });
      let language = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        languageList: selectedLanguages.current,
      };
      setIsLoaded(LOADING);
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveLanguageTab",
          data: language,
          template: "CONTROL_DATA",
        });
        resolve(result);
      }).then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: "Customer Languages has successfully updated.",
            showConfirmButton: false,
            timer: 5000,
          });
          setRefreshDpLanguage(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setRefreshDpLanguage(true);
    setIsLoaded(LOADING);
  };

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <Alert
        className="alert-dismissible d-flex align-items-center"
        color="warning"
        isOpen={b_partner_id === 0}
      >
        <AlertTriangle />
        <p className="ml-2">
          You must create a Customer, before add Language Details.
        </p>
      </Alert>
      <form
        onSubmit={(evt) => submitForm(evt)}
        onReset={(evt) => resetForm(evt)}
        className={`position-relative form-13 ${
          isValidated ? "was-validated" : ""
        }`}
        noValidate
      >
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex justify-content-center align">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-row mb-4">
          <div className="form-group col-3 d-flex">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_2_sa_job_category_id"
                className="col-form-label pt-0 fw-bold"
              >
                Language
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpLanguage ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpLanguage(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() => openMasterFileWindow(LANGUAGE, INSERT_LANGUAGE)}
              />
            </div>
          </div>
          <div className="form-group col-3 d-flex justify-content-center">
            <label
              htmlFor="id_input_fm_2_is_most_interested_job"
              className="mt-0 mb-0 fw-bold"
            >
              Can Speak
            </label>
          </div>
          <div className="form-group col-3 d-flex justify-content-center">
            <label
              htmlFor="id_input_fm_2_is_most_interested_job"
              className="mt-0 mb-0 fw-bold"
            >
              Show In Application
            </label>
          </div>
          <div className="form-group col-3 d-flex">
            <label
              htmlFor="id_input_fm_2_is_most_interested_job"
              className="mt-0 mb-0 fw-bold"
            >
              Language Proficiency
            </label>
          </div>
        </div>
        {languageList.length > 0
          ? languageList.map((language_item, index) => (
              <div key={index} className="form-row mb-4">
                <div className="form-group col-3 d-flex">
                  <label className="col-form-label mt-1">
                    {language_item.name}
                  </label>
                </div>
                <div className="form-group col-3 d-flex justify-content-center">
                  <div className="checkbox checkbox-solid-dark mb-2">
                    <input
                      id={`id_fm_13_lan_${language_item.languageId}_canspeak`}
                      name={`fm_13_lan_${language_item.languageId}_canspeak`}
                      type="checkbox"
                      defaultChecked={language_item.isCan}
                    />
                    <label
                      htmlFor={`id_fm_13_lan_${language_item.languageId}_canspeak`}
                    ></label>
                  </div>
                </div>
                <div className="form-group col-3 d-flex justify-content-center">
                  <div className="checkbox checkbox-solid-dark mb-2">
                    <input
                      id={`id_fm_13_lan_${language_item.languageId}_isshowinapplication`}
                      name={`fm_13_lan_${language_item.languageId}_isshowinapplication`}
                      type="checkbox"
                      defaultChecked={language_item.isShowApplication}
                    />
                    <label
                      htmlFor={`id_fm_13_lan_${language_item.languageId}_isshowinapplication`}
                      className=""
                    ></label>
                  </div>
                </div>
                <div className="form-group col-3 position-relative">
                  <select
                    id="id_input_fm_2_sa_level_proficiency"
                    name={`fm_13_lan_${language_item.languageId}_proficiency`}
                    className="form-select"
                    defaultValue={language_item.remark}
                  >
                    {proficiencyList.length
                      ? proficiencyList.map((list_item, index) => (
                          <option value={list_item} key={index}>
                            {list_item}
                          </option>
                        ))
                      : null}
                  </select>
                  <div className="invalid-feedback position-absolute">
                    Please select a Proficiency Lavel
                  </div>
                </div>
              </div>
            ))
          : null}
        {b_partner_id ? (
          <div className="d-sm-flex justify-content-end">
            <button
              className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
              type="submit"
            >
              Update
            </button>
            <button className="btn btn-warning col-12 col-sm-2" type="reset">
              Reset
            </button>
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default Language;
