import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import { AlertTriangle, ExternalLink, RefreshCw } from "react-feather";
import Swal from "sweetalert2";
import {
  INSERT_PHOTO_TYPE,
  LOADED,
  LOADING,
  PHOTO_TYPE,
} from "../../../../constant";
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import { useSelector } from "react-redux";
// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import ConfigDB from "../../../../data/customizer/config";
import default_avatar from "../../../../assets/images/user/default_avatar.png";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";

const Photo = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [profilePicture, setProfilePicture] = useState({
    base64Value: "",
    extension: "",
    changed: false,
    url: "",
  });
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpPhotoType, setRefreshDpPhotoType] = useState(false);

  // form values
  const [photoTypeList, setPhotoTypeList] = useState([
    { photoTypeId: "", name: "-Select-" },
  ]);
  const [dpPhotoTypeValue, setDpPhotoTypeValue] = useState(0);
  const [uploadedPhotoTypeList, setUploadedPhotoTypeList] = useState([]);
  const [photoId, setPhotoId] = useState(0);

  // table data
  const [photoRowList, setPhotoRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Photo Type",
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editPhoto] = useState(() => (photo_id, response_data) => {
    const editingPhoto = response_data.find(
      (item) => item.photoId === photo_id
    );
    setPhotoId(editingPhoto.photoId);
    setDpPhotoTypeValue(editingPhoto.photoTypeId);
    setProfilePicture({
      changed: false,
      base64Value: "",
      extension: "",
      url: editingPhoto.documentPath,
    });
    document.querySelector("textarea[name='fm_11_remark']").value =
      editingPhoto.remark;
    if (document.querySelector("input[name='fm_11_is_active']")) {
      document.querySelector("input[name='fm_11_is_active']").checked =
        editingPhoto.isActive;
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deletePhoto] = useState(() => (photo_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Photo!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          photoId: photo_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deletePhoto",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Photo has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    console.log(uploadedPhotoTypeList);
    const existResult = uploadedPhotoTypeList.find(
      (photo_type) => photo_type.photoTypeId === parseInt(dpPhotoTypeValue)
    );
    if (profilePicture.base64Value === "" && profilePicture.url === "") {
      evt.target.setCustomValidity("Invalid");
    } else if (photoId === 0 && existResult) {
      Swal.fire({
        icon: "error",
        title: "Already Exists",
        text: `A photo related to ${
          photoTypeList.find(
            (photo_type) =>
              photo_type.photoTypeId === parseInt(dpPhotoTypeValue)
          ).name
        } has already added.`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: false,
        confirmButtonText: "Ok",
      });
    } else {
      if (evt.target.checkValidity()) {
        const formData = new FormData(evt.target);
        let family = {
          clientId: parseInt(client_id),
          orgId: parseInt(org_id),
          userId: parseInt(loggedUser.userId),
          photoId: photoId,
          bpartnerId: parseInt(b_partner_id),
          photoTypeId: dpPhotoTypeValue,
          isActive: formData.get("fm_11_is_active") ? true : false,
          isAttachmentModified: profilePicture.changed,
          attachmentExtension: profilePicture.extension,
          attachment: profilePicture.base64Value, //base64
          remark: formData.get("fm_11_remark"),
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/savePhotoTab",
            data: family,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((reponse_data) => {
          if (reponse_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: photoId
                ? "Photo has successfully updated."
                : "Photo has successfully added.",
              showConfirmButton: false,
              timer: 5000,
            });
            setIsLoaded(LOADING);
            setRefreshTable(true);
          }
        });
      }
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setDpPhotoTypeValue(0);
    setPhotoId(0);
    setProfilePicture({
      base64Value: "",
      extension: "",
      url: "",
      changed: true,
    });
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/configuration/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewPhotoTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activePhotoTypeList) {
              let tempPhotoTypeList = [];
              response_data.activePhotoTypeList.forEach((photo_type_item) => {
                let searchResult = response_data.allCustomerPhotoTableList.find(
                  (photo) => photo.photoTypeId === photo_type_item.photoTypeId
                );
                if (searchResult) {
                  tempPhotoTypeList.push({
                    photoId: searchResult.photoId,
                    photoTypeId: searchResult.photoTypeId,
                  });
                }
              });
              setUploadedPhotoTypeList(tempPhotoTypeList);
              setPhotoTypeList([
                { photoTypeId: "", name: "-Select-" },
                ...response_data.activePhotoTypeList,
              ]);
            }
            if (response_data.allCustomerPhotoTableList) {
              let tempList = [];
              response_data.allCustomerPhotoTableList.forEach((listItem) => {
                tempList.push([
                  listItem.photoId,
                  listItem.photoTypeName,
                  listItem.remark,
                  listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editPhoto(
                        listItem.photoId,
                        response_data.allCustomerPhotoTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deletePhoto(listItem.photoId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setPhotoRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  const readUrl = (event) => {
    if (event.target.files.length === 0) return;
    //Image upload validation
    var mimeType = event.target.files[0].type;
    // Image upload
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      console.log(event.target);
      console.log(mimeType.replace(/image\//, "."));
      setProfilePicture({
        changed: true,
        base64Value: reader.result,
        extension: mimeType.replace(/image\//, "."),
        url: "",
      });
    };
  };

  useEffect(() => {
    if (refreshDpPhotoType) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActivePhotoType",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setPhotoTypeList([
            { photoTypeId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpPhotoType(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpPhotoType]);

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Customer Photos.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-11 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group mb-4 mb-sm-0 col-12 col-sm-3 d-flex justify-content-center align-items-center">
              <div className="user-image">
                <div className="avatar">
                  <img
                    className="pro"
                    alt=""
                    src={
                      profilePicture.url
                        ? `${ConfigDB.data.fileServerUrl}${profilePicture.url}`
                        : profilePicture.base64Value
                        ? profilePicture.base64Value
                        : LoadStaticURL(default_avatar)
                    }
                    data-intro="This is Profile image"
                  />
                  <div className="icon-wrapper">
                    <i
                      className="icofont icofont-pencil-alt-5"
                      data-intro="Change Profile image here"
                    >
                      <input
                        accept="image/*"
                        className="pencil"
                        type="file"
                        onChange={(e) => readUrl(e)}
                      />
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-9">
              <div className="form-group mb-4">
                <div className="d-flex">
                  <label
                    htmlFor="id_input_fm_11_sa_photo_type_id"
                    className="col-form-label pt-0 required-input-mark"
                  >
                    Photo Type
                  </label>
                  <RefreshCw
                    className={`mx-3 mt-1 dp-refresh-icon ${
                      refreshDpPhotoType ? "rotate-icon" : ""
                    }`}
                    height={"14px"}
                    onClick={() => setRefreshDpPhotoType(true)}
                  />
                  <ExternalLink
                    className="mt-1 dp-refresh-icon"
                    height={"14px"}
                    onClick={() =>
                      openMasterFileWindow(PHOTO_TYPE, INSERT_PHOTO_TYPE)
                    }
                  />
                </div>
                <select
                  id="id_input_fm_11_sa_photo_type_id"
                  name="fm_11_sa_photo_type_id"
                  className="form-select"
                  onChange={(evt) => setDpPhotoTypeValue(evt.target.value)}
                  value={dpPhotoTypeValue}
                  required
                >
                  {photoTypeList.length
                    ? photoTypeList.map((listItem, index) => (
                        <option value={listItem?.photoTypeId} key={index}>
                          {listItem?.name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="form-group mb-4">
                <label htmlFor="id_input_fm_11_remark">Remark</label>
                <textarea
                  id="id_input_fm_11_remark"
                  name="fm_11_remark"
                  rows="3"
                  maxLength={250}
                  className="form-control"
                  placeholder="Enter Remarks"
                  onChange={(evt) =>
                    formValidation({ event: evt, checkExpression: "" })
                  }
                ></textarea>
                <small className="txt-danger position-absolute d-none input-validation"></small>
              </div>
            </div>
          </div>
          {(checkIsAccessible(INSERTFUNCTION) && photoId === 0) ||
          (checkIsAccessible(DEACTIVATEFUNCTION) && photoId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-1 mx-sm-0">
                <input
                  id="id_input_fm_11_is_active"
                  name="fm_11_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_11_is_active">Active</label>
              </div>
            </div>
          ) : null}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && photoId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {photoId ? "Update" : "Submit"}
                </button>
                <button
                  className="btn btn-warning col-12 col-sm-2"
                  type="reset"
                >
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Customer Photo Collection"
          columnData={tableColumnHeaderList}
          rowData={photoRowList}
        />
      </div>
    </>
  );
};

export default Photo;
