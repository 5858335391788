import React, { useEffect, useState } from "react";
import {
  AlertCircle,
  CheckCircle,
  Clock,
  PhoneOutgoing,
  Play,
} from "react-feather";
import { useParams } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";
import customerDashboardCard from "../../assets/images/customer-dashboard-card.png";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import Skeleton from "react-loading-skeleton";
import { LOADED, LOADING } from "../../constant";
import { UPDATE_CUSTOMER } from "../../redux/actionTypes";
import ConfigDB from "../../data/customizer/config";
import { LoadStaticURL } from "../../data/util/LoadStaticURL";
import Swal from "sweetalert2";
import CircleIcon from "@mui/icons-material/Circle";

const Dashboard = () => {
  const { customer_id } = useParams();
  const dispatch = useDispatch();
  ChartJS.register(ArcElement, Tooltip, Legend);

  const [dashboardData, setDashboardData] = useState();

  // interface controllers
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [profileProgress, setProfileProgress] = useState(0);
  const [showNumbers, setShowNumbers] = useState(false);

  // stored data
  const customerData = useSelector((content) => content.CustomerReducer);
  const loggedUser = useSelector((content) => content.UserReducer);

  const [phoneNumbers, setPhoneNumbers] = useState();
  const [phoneNumberToBeDialed, setPhoneNumberToBeDialed] = useState();

  // breadcrumb values
  let childLinks = [{ value: customer_id, active: true }];

  // dougnut data
  const data = {
    labels: [],
    datasets: [
      {
        label: "",
        data: [profileProgress, 100 - profileProgress],
        backgroundColor: ["rgba(27, 153, 139, 1)", "rgba(27, 153, 139, 0.2)"],
        borderWidth: 1,
      },
    ],
  };

  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    document.title = `Deshakthee ERP | ${customer_id}`;
    const reqBody = {
      clientId: customerData.clientId,
      orgId: customerData.orgId,
      userId: loggedUser.userId,
      bpartnerId: customerData.bpartnerId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: [
          "customer/viewCustomerDashBoard",
          "customer/viewProfileContactNoTab",
        ],
        data: reqBody,
        template: "REQUEST_ARRAY",
      });
      resolve(result);
    }).then((response_data_array) => {
      setDashboardData(response_data_array[0]);
      setProfileProgress(response_data_array[0].overallProfilePercentage);
      dispatch({
        type: UPDATE_CUSTOMER,
        payload: {
          name: response_data_array[0].fullName,
          profilePicture: response_data_array[0].profilePictureDocumentPath,
        },
      });
      setPhoneNumbers(response_data_array[1].contactNoList);
      setIsLoaded(LOADED);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dialNumber = () => {
    let phoneNumber =
      phoneNumbers.length > 1
        ? !phoneNumberToBeDialed
          ? ""
          : phoneNumberToBeDialed
        : phoneNumbers[0].contactNumber;
    if (phoneNumber === "") {
      /** If there is no selected number to be dialed */
      setShowNumbers(showNumbers ? false : true);
    } else {
      Swal.fire({
        icon: "info",
        text: `You are going to call ${dashboardData.fullName}`,
        showDenyButton: true,
        confirmButtonText: "Call",
        denyButtonText: "Cancel",
        denyButtonColor: "#ff9f40",
      }).then((result) => {
        if (result.isConfirmed) {
          const reqBody = {
            clientId: loggedUser.clientId,
            orgId: loggedUser.orgId,
            userId: loggedUser.userId,
            bpartnerId: customerData.bpartnerId,
            customerContactNumber: phoneNumber,
            interfaceSearchKey: "Dashboard",
          };
          let responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/call/saveDialingCall",
              data: reqBody,
              template: "CONTROL_DATA",
            });
            resolve(result);
          });
          responseData.then((response_data) => {
            if (response_data) {
              Swal.fire({
                icon: "success",
                title: "Request Successful",
                text: `You will be connected with ${dashboardData.fullName} shortly.`,
                showConfirmButton: false,
                timer: 5000,
              });
            }
          });
        }
      });
      setShowNumbers(false);
      setPhoneNumberToBeDialed("");
    }
  };

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Dashboard" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="card">
              <div
                className="card-body"
                style={{
                  backgroundImage: `url(${LoadStaticURL(
                    customerDashboardCard
                  )})`,
                  backgroundSize: "cover",
                  backgroundPosition: "bottom center",
                }}
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex align-items-center flex-full">
                    {isLoaded === LOADED ? (
                      <i
                        className={`fa fa-circle txt-${
                          dashboardData.isActive ? "primary" : "warning"
                        }`}
                      ></i>
                    ) : (
                      <Skeleton />
                    )}
                    {isLoaded === LOADED ? (
                      <div className="ml-2 fw-bold">
                        {dashboardData.isActive ? "Active" : "Not Active"}
                      </div>
                    ) : (
                      <Skeleton />
                    )}
                  </div>
                  <div
                    style={{ height: "1px", marginLeft: "5px" }}
                    className="w-100 bg-secondary"
                  ></div>
                </div>
                <div className="d-flex flex-column flex-sm-row align-items-center py-2">
                  <img
                    width={"150px"}
                    className="rounded-circle lazyloaded blur-up"
                    src={`${ConfigDB.data.fileServerUrl}${customerData.profilePicture}`}
                    alt="#"
                  />
                  <div className="px-3 w-100">
                    <div className="d-sm-flex justify-content-between align-items-center">
                      <div>
                        <div className="d-sm-flex">
                          <div className="mb-2">
                            <div className="fw-bold">Customer Id</div>
                            {isLoaded === LOADED ? (
                              <div className="fw-bold text-secondary">
                                {dashboardData.customerId}
                              </div>
                            ) : (
                              <Skeleton />
                            )}
                          </div>
                          <div className="mb-sm-0 mb-2 mx-sm-4">
                            <div className="fw-bold">Customer Name</div>
                            {isLoaded === LOADED ? (
                              <div className="fw-bold text-secondary">
                                {dashboardData.fullName}
                              </div>
                            ) : (
                              <Skeleton />
                            )}
                          </div>
                        </div>
                        <div className="mb-sm-0 mb-2">
                          <div className="fw-bold">Age</div>
                          {isLoaded === LOADED ? (
                            <div className="fw-bold text-secondary">
                              {dashboardData.age} Years
                            </div>
                          ) : (
                            <Skeleton />
                          )}
                        </div>
                      </div>
                      <div className="position-relative">
                        <div
                          style={{ right: 0, zIndex: 1 }}
                          className={`${
                            showNumbers ? "position-absolute" : ""
                          } d-flex align-items-center justify-content-end ml-sm-3`}
                          onClick={dialNumber}
                        >
                          <button
                            type="button"
                            className="btn rounded bg-primary w-100 p-sm-3 d-flex justify-content-center align-items-center"
                          >
                            <PhoneOutgoing
                              height="16px"
                              className="txt-light"
                            />
                          </button>
                        </div>
                        <div
                          className={`${
                            showNumbers ? "" : "d-none"
                          } position-absolute top-0 bg-light rounded`}
                          style={{
                            width: showNumbers ? "300px" : "0px",
                            right: 0,
                          }}
                        >
                          <div className="p-3">
                            <div className="fw-bold mb-2 txt-dark">
                              Select a Number To Dial
                            </div>
                            {isLoaded === LOADED
                              ? phoneNumbers.map((number_item, index) => (
                                  <div className="d-flex" key={index}>
                                    <Play
                                      height="16px"
                                      className={`${
                                        phoneNumberToBeDialed ===
                                        number_item.contactNumber
                                          ? "txt-secondary"
                                          : "invisible"
                                      }`}
                                    />
                                    <div
                                      className="txt-dark pointer"
                                      onClick={() =>
                                        setPhoneNumberToBeDialed(
                                          number_item.contactNumber
                                        )
                                      }
                                    >
                                      {number_item.contactNumber.slice(0, 3)}
                                      <CircleIcon
                                        className="text-secondary"
                                        sx={{ fontSize: 10 }}
                                      />
                                      <CircleIcon
                                        className="text-secondary"
                                        sx={{ fontSize: 10 }}
                                      />
                                      <CircleIcon
                                        className="text-secondary"
                                        sx={{ fontSize: 10 }}
                                      />
                                      <CircleIcon
                                        className="text-secondary"
                                        sx={{ fontSize: 10 }}
                                      />
                                      {number_item.contactNumber.slice(
                                        number_item.contactNumber.length - 3,
                                        number_item.contactNumber.length
                                      )}
                                    </div>
                                  </div>
                                ))
                              : "Loading..."}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card">
              <div className="card-body">
                <div className="fw-bold mb-4">Profile Completion</div>
                <Doughnut data={data} className="mb-4 d-none d-md-block" />
                <Doughnut
                  data={data}
                  className="mb-4 w-50 h-50 m-auto d-block d-md-none"
                />
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.personalDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}
                    <div className="ml-2">Personal Details</div>
                  </div>
                  {
                    <div className="">
                      {isLoaded === LOADED
                        ? dashboardData.personalDetailPercentage
                        : 0}
                      %
                    </div>
                  }
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.jobDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Job Details</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.jobDetailPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.contactDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Contact Details</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.contactDetailPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.passportDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Passport Details</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.passportDetailPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.experienceDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Experience Details</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.experienceDetailPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.educationDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Education Details</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.educationDetailPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.familyDetailPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Family Details</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.familyDetailPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.detailsInSinhalaPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}{" "}
                    <div className="ml-2">Details In Sinhala</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.detailsInSinhalaPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.customerStatusPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}
                    <div className="ml-2">Customer Status</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED
                      ? dashboardData.customerStatusPercentage
                      : 0}
                    %
                  </div>
                </div>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    {isLoaded === LOADED ? (
                      dashboardData.photoPercentage ? (
                        <CheckCircle height={"16px"} className="txt-primary" />
                      ) : (
                        <AlertCircle
                          height={"16px"}
                          className="txt-secondary"
                        />
                      )
                    ) : null}
                    <div className="ml-2">Customer Photos</div>
                  </div>
                  <div className="">
                    {isLoaded === LOADED ? dashboardData.photoPercentage : 0}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
