import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import ConfigDB from "../../../../data/customizer/config";
import {
  CC1_DISPOSITION,
  CC2_DISPOSITION,
  INSERT_CC1_DISPOSITION,
  INSERT_CC2_DISPOSITION,
  INSERT_MOOD,
  MOOD,
} from "../../../../constant";

const CustomerStatus = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  const [refreshPage, setRefreshPage] = useState(true);
  const [btnText, setBtnText] = useState("Submit");

  const [refreshDpCC1Disposition, setRefreshDpCC1Disposition] = useState(false);
  const [refreshDpCC2Disposition, setRefreshDpCC2Disposition] = useState(false);
  const [refreshDpMood, setRefreshDpMood] = useState(false);

  // form controls
  const [cc1DispositionList, setCC1DispositionList] = useState([
    { cc1DispositionId: "", name: "-Select-" },
  ]);
  const [dpCC1Value, setDpCC1Value] = useState(0);
  const [cc2DispositionList, setCC2DispositionList] = useState([
    { cc2DispositionId: "", name: "-Select-" },
  ]);
  const [dpCC2Value, setDpCC2Value] = useState(0);
  const [moodList, setMoodList] = useState([{ moodId: "", name: "-Select-" }]);
  const [dpMoodValue, setDpMoodValue] = useState(0);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  const resetForm = () => {
    setIsValidated(false);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let sinhalaDetails = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        cc1DispositionId: parseInt(dpCC1Value),
        cc2DispositionId: parseInt(dpCC2Value),
        moodId: parseInt(dpMoodValue),
        cc1DispositionRemark: formData.get("fm_10_cc1_disposition_remark"),
        cc2DispositionRemark: formData.get("fm_10_cc2_disposition_remark"),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveStatusTab",
          data: sinhalaDetails,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: true
              ? "Sinhala Detail Record has successfully updated."
              : "New Sinhala Detail Record has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setRefreshPage(true);
          setIsLoaded(LOADING);
        }
      });
    }
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshPage) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewStatusTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeCc1DispositionList) {
              setCC1DispositionList([
                { cc1DispositionId: "", name: "-Select-" },
                ...response_data.activeCc1DispositionList,
              ]);
            }
            if (response_data.activeCc2DispositionList) {
              setCC2DispositionList([
                { cc2DispositionId: "", name: "-Select-" },
                ...response_data.activeCc2DispositionList,
              ]);
            }
            if (response_data.activeMoodList) {
              setMoodList([
                { moodId: "", name: "-Select-" },
                ...response_data.activeMoodList,
              ]);
            }
            setDpCC1Value(response_data.cc1DispositionId);
            setDpCC2Value(response_data.cc2DispositionId);
            setDpMoodValue(response_data.moodId);
            document.querySelector(
              "textarea[name='fm_10_cc2_disposition_remark']"
            ).value = response_data.cc2DispositionRemark;
            document.querySelector(
              "textarea[name='fm_10_cc1_disposition_remark']"
            ).value = response_data.cc1DispositionRemark;
            if (response_data.cc1DispositionId !== -1) {
              setBtnText("Update");
            }
            setRefreshPage(false);
            setIsLoaded(LOADED);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  useEffect(() => {
    if (refreshDpCC1Disposition) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCc1Disposition",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCC1DispositionList([
            { cc1DispositionId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCC1Disposition(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCC1Disposition]);
  useEffect(() => {
    if (refreshDpCC2Disposition) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCc2Disposition",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCC2DispositionList([
            { cc2DispositionId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCC2Disposition(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCC2Disposition]);
  useEffect(() => {
    if (refreshDpMood) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveMood",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setMoodList([{ moodId: "", name: "-Select-" }, ...response_data]);
          setRefreshDpMood(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpMood]);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <Alert
        className="alert-dismissible d-flex align-items-center"
        color="warning"
        isOpen={b_partner_id === 0}
      >
        <AlertTriangle />
        <p className="ml-2">
          You must create a Customer, before add Customer Status.
        </p>
      </Alert>
      <form
        onSubmit={(evt) => submitForm(evt)}
        onReset={(evt) => resetForm(evt)}
        className={`position-relative form-10 ${
          isValidated ? "was-validated" : ""
        }`}
        noValidate
      >
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex justify-content-center align">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-6 mb-2 mb-sm-2 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_10_sa_cc1_disposition_id"
                className="col-form-label pt-0 required-input-mark"
              >
                CC1 Disposition
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpCC1Disposition ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpCC1Disposition(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(CC1_DISPOSITION, INSERT_CC1_DISPOSITION)
                }
              />
            </div>
            <select
              id="id_input_fm_10_sa_cc1_disposition_id"
              name="fm_10_sa_cc1_disposition_id"
              className="form-select"
              onChange={(evt) => setDpCC1Value(evt.target.value)}
              value={dpCC1Value}
              required
            >
              {cc1DispositionList.length
                ? cc1DispositionList.map((listItem, index) => (
                    <option value={listItem?.cc1DispositionId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
            <div className="invalid-feedback position-absolute">
              Please select CC1 Disposition
            </div>
          </div>
          <div className="form-group col-12 col-sm-6 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_10_sa_cc2_disposition_id"
                className="col-form-label pt-0"
              >
                CC2 Disposition
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpCC2Disposition ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpCC2Disposition(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(CC2_DISPOSITION, INSERT_CC2_DISPOSITION)
                }
              />
            </div>
            <select
              id="id_input_fm_10_sa_cc2_disposition_id"
              name="fm_10_sa_cc2_disposition_id"
              className="form-select"
              onChange={(evt) => setDpCC2Value(evt.target.value)}
              value={dpCC2Value}
            >
              {cc2DispositionList.length
                ? cc2DispositionList.map((listItem, index) => (
                    <option value={listItem?.cc2DispositionId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-6 mb-2 mb-sm-2">
            <label htmlFor="id_input_fm_10_cc1_disposition_remark">
              CC1 Remark
            </label>
            <textarea
              id="id_input_fm_10_cc1_disposition_remark"
              name="fm_10_cc1_disposition_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter CC1 Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-6">
            <label htmlFor="id_input_fm_10_cc2_disposition_remark">
              CC2 Remark
            </label>
            <textarea
              id="id_input_fm_10_cc2_disposition_remark"
              name="fm_10_cc2_disposition_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter CC2 Remark"
              onChange={(evt) =>
                formValidation({
                  event: evt,
                  checkExpression: "",
                })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-6 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_10_sa_mood_id"
                className="col-form-label pt-0"
              >
                Mood
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpMood ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpMood(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() => openMasterFileWindow(MOOD, INSERT_MOOD)}
              />
            </div>
            <select
              id="id_input_fm_10_sa_mood_id"
              name="fm_10_sa_mood_id"
              className="form-select"
              onChange={(evt) => setDpMoodValue(evt.target.value)}
              value={dpMoodValue}
            >
              {moodList.length
                ? moodList.map((listItem, index) => (
                    <option value={listItem?.moodId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        {b_partner_id ? (
          (checkIsAccessible(INSERTFUNCTION) && btnText === "Submit") ||
          (checkIsAccessible(EDITFUNCTION) && btnText === "Update") ? (
            <div className="d-sm-flex justify-content-end">
              <button
                className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                type="submit"
              >
                {btnText}
              </button>
              <button className="btn btn-warning col-12 col-sm-2" type="reset">
                Reset
              </button>
            </div>
          ) : null
        ) : null}
      </form>
    </div>
  );
};

export default CustomerStatus;
