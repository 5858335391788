import React, { useEffect, useState } from "react";
import { Clock, Download } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useAccess from "../../../../customHooks/useAccess";
import { useSelector } from "react-redux";

//network requests
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import DetailRowLayout from "../../../common/DetailRowLayout";
import { useNavigate, useParams } from "react-router-dom";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";

const Personal = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfilePersonalTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      {/* <div className="d-flex justify-content-end">
        {isLoaded ? (
          <div className="btn d-flex align-items-center fw-bold" type="submit">
            <Download width={"16px"} className="mr-2" />
            <div className="ml-2">Dowload As PDF</div>
          </div>
        ) : null}
      </div> */}
      <div>
        {isLoaded === LOADED ? (
          <div
            className="d-flex align-items-center mb-2"
            style={{ minWidth: "max-content" }}
          >
            <Clock height="12px" className="text-secondary"></Clock>
            {customerData.updated === customerData.created
              ? "Created "
              : "Last Modified "}
            on {ConvertMillisToDate(customerData.updated)} By{" "}
            {customerData.updatedBy}
          </div>
        ) : (
          <Skeleton className="w-25" />
        )}
      </div>
      <div>
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={[
            "First Name",
            "Last Name",
            "Name with Initials",
            "Preferred Name",
          ]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.firstName,
                  customerData.lastName,
                  customerData.nameWithInitial,
                  customerData.preferredName,
                ]
              : null
          }
        />
        {/* <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["First Name", "Last Name"]}
          data={
            isLoaded === LOADED
              ? [customerData.firstName, customerData.lastName]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["Name with Initials", "Preferred Name"]}
          data={
            isLoaded === LOADED
              ? [customerData.nameWithInitial, customerData.preferredName]
              : null
          }
        /> */}
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["Date of Birth", "Place of Birth", "Gender", "NIC"]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.dateOfBirth === null
                    ? ""
                    : ConvertMillisToDate(customerData.dateOfBirth),
                  customerData.placeOfBirth,
                  customerData.genderName,
                  customerData.nicNo,
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["Title", "Nationality", "Religion", "Customer Type"]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.titleName,
                  customerData.nationalityName,
                  customerData.religionName,
                  customerData.customerTypeName,
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["District", "City", "Police Station", "Grama Sewa Division"]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.districtName,
                  customerData.cityName,
                  customerData.policeStationName,
                  customerData.gramaSevaDivisionName,
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={[
            "Civil Status",
            "Children",
            "Youngest Child Age",
            "Complexion",
          ]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.civilStatusName,
                  customerData.children,
                  `${customerData.youngestChildAgeYear} Years, ${customerData.youngestChildAgeMonth} Months`,
                  customerData.complexionName,
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["Height", "Weight", "Disease"]}
          data={
            isLoaded === LOADED
              ? [
                  `${customerData.height}CM`,
                  `${customerData.weight}KG`,
                  customerData.disease,
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["Most Interested", "Most Interested Remark"]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.mostInterestedName,
                  customerData.mostInterestedRemark,
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={[
            "Have Worked Abroad",
            "Worked Experience",
            "Last Visit To Sri Lanka",
          ]}
          data={
            isLoaded === LOADED
              ? [
                  `${customerData.isWorkedAbroad ? "Yes" : "No"}`,
                  `${customerData.yearOfExperience} Years, ${customerData.monthOfExperience} Months`,
                  customerData.lastVisitToSriLanka === null
                    ? ""
                    : ConvertMillisToDate(customerData.lastVisitToSriLanka),
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={[
            "Expected Country",
            "Bureau Training Center",
            "Confirmed Visit Date",
          ]}
          data={
            isLoaded === LOADED
              ? [
                  customerData.expectedCountryName,
                  customerData.bureauTrainingCenterName,
                  customerData.confirmedVisitDate === null
                    ? ""
                    : ConvertMillisToDate(customerData.confirmedVisitDate),
                ]
              : null
          }
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={["Nature of Work"]}
          data={isLoaded === LOADED ? [customerData.natureOfWork] : null}
        />
        <DetailRowLayout
          display={isLoaded === LOADED}
          labels={[
            "Passport Available",
            "Passport Has To Renew",
            "Fingermark Available",
            "Visit To Office",
          ]}
          data={
            isLoaded === LOADED
              ? [
                  `${customerData.isPassportAvailable ? "Yes" : "No"}`,
                  `${customerData.isPassportToRenew ? "Yes" : "No"}`,
                  `${customerData.isFingermark ? "Yes" : "No"}`,
                  `${customerData.isVisitToOffice ? "Yes" : "No"}`,
                ]
              : null
          }
        />
        {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
          <div className="d-flex justify-content-end mt-4 mx-1">
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_1`
                )
              }
              className="btn btn-primary col-12 col-md-2"
              type="submit"
            >
              Update
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Personal;
