import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";

import default_avatar from "../../../assets/images/user/default_avatar.png";
import { Link, useParams } from "react-router-dom";
import { Edit } from "react-feather";
import ConfigDB from "../../../data/customizer/config";

const UserPanel = () => {
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  const customerData = useSelector((content) => content.CustomerReducer);
  const { customer_id } = useParams();

  const url = "";

  return (
    <Fragment>
      <div className="sidebar-user text-center">
        <div>
          <img
            className="img-60 rounded-circle lazyloaded blur-up"
            /** Hosted Image Url */
            // src={customer_id !== undefined ? `${ConfigDB.data.fileServerUrl}${customerData.profilePicture}` : `${process.env.PUBLIC_URL}/DeshaktheeERP${default_avatar}`}
            src={
              customer_id !== undefined
                ? `${ConfigDB.data.fileServerUrl}${customerData.profilePicture}`
                : `${ConfigDB.data.fileServerUrl}${loggedUser.profilePictureDocumentPath}`
            }
            alt="#"
          />
          <div className="profile-edit">
            <Link to={`/user`}>
              <Edit />
            </Link>
          </div>
        </div>
        <h6 className="mt-3 f-14">
          {customer_id === undefined
            ? loggedUser.employeeName
            : customerData.name?.split(" ")[0]}
        </h6>
        <p>{customer_id === undefined ? loggedUser.roleName : customer_id}.</p>
      </div>
    </Fragment>
  );
};

export default UserPanel;
