import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// custom components
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";

// tab panes
import Personal from "./details/Personal";
import Job from "./details/Job";
import Passport from "./details/Passport";
import Experience from "./details/Experience";
import Education from "./details/Education";
import Family from "./details/Family";
import DetailsInSinhala from "./details/DetailsInSinhala";
import CustomerStatus from "./details/CustomerStatus";
import Contact from "./details/contact/index";
import Photo from "./details/Photo";
import VisitDetail from "./details/VisitDetail";
import ConnectedSource from "./details/ConnectedSource";
import Language from "./details/Language";
import Skill from "./details/Skill";
import Remark from "./details/Remark";

const Index = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const { customer_id } = useParams();

  let childLinks = [
    { value: customer_id, active: false },
    { value: "Details", active: true },
  ];
  const [basicTab, setBasicTab] = useState("customer_details_tab_1");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Details" children={childLinks} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card-modified">
              <div className="card-modified-header">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_1"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_1")}
                    >
                      Personal
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_2"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_2")}
                    >
                      Job
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_3"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_3")}
                    >
                      Language
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_4"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_4")}
                    >
                      Skill
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_5"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_5")}
                    >
                      Contact
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_6"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_6")}
                    >
                      Passport
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_7"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_7")}
                    >
                      Experience
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_8"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_8")}
                    >
                      Education
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_9"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_9")}
                    >
                      Family
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_10"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_10")}
                    >
                      Remark
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_11"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_11")}
                    >
                      Detail In Sinhala
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_12"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_12")}
                    >
                      Customer Status
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_13"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_13")}
                    >
                      Customer Photos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_14"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_14")}
                    >
                      Connected Source
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={`fw-bold ${
                        basicTab === "customer_details_tab_15"
                          ? "active"
                          : "txt-primary"
                      }`}
                      onClick={() => setBasicTab("customer_details_tab_15")}
                    >
                      Visit Detail
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <TabContent activeTab={basicTab}>
                <TabPane className="fade show" tabId="customer_details_tab_1">
                  {basicTab === "customer_details_tab_1" ? (
                    <Personal
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_2">
                  {basicTab === "customer_details_tab_2" ? (
                    <Job
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_3">
                  {basicTab === "customer_details_tab_3" ? (
                    <Language
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_4">
                  {basicTab === "customer_details_tab_4" ? (
                    <Skill
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_5">
                  {basicTab === "customer_details_tab_5" ? (
                    <Contact
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_6">
                  {basicTab === "customer_details_tab_6" ? (
                    <Passport
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_7">
                  {basicTab === "customer_details_tab_7" ? (
                    <Experience
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_8">
                  {basicTab === "customer_details_tab_8" ? (
                    <Education
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_9">
                  {basicTab === "customer_details_tab_9" ? (
                    <Family
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_10">
                  {basicTab === "customer_details_tab_10" ? (
                    <Remark
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_11">
                  {basicTab === "customer_details_tab_11" ? (
                    <DetailsInSinhala
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_12">
                  {basicTab === "customer_details_tab_12" ? (
                    <CustomerStatus
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_13">
                  {basicTab === "customer_details_tab_13" ? (
                    <Photo
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_14">
                  {basicTab === "customer_details_tab_14" ? (
                    <ConnectedSource
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="customer_details_tab_15">
                  {basicTab === "customer_details_tab_15" ? (
                    <VisitDetail
                      b_partner_id={b_partner_id}
                      client_id={client_id}
                      org_id={org_id}
                    />
                  ) : null}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
