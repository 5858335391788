import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { Range, getTrackBackground } from "react-range";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { ControlButtons, BooleanValue } from "../common/datatable";
import formValidation from "../../customHooks/useValidation";

//network requests
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";
import {
  DEACTIVATE_JOB_CATEGORY,
  DELETE_JOB_CATEGORY,
  EDIT_JOB_CATEGORY,
  INSERT_JOB_CATEGORY,
  JOB_CATEGORY,
} from "../../constant";

const JobCategory = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Master File", active: false },
    { value: "Job Category", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, JOB_CATEGORY);
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [resData, setResData] = useState();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [genderList, setGenderList] = useState([
    { genderId: "", name: "-Select-" },
  ]);
  const [dpGenderValue, setDpGenderValue] = useState(0);
  const [jobCategoryId, setJobCategoryId] = useState(0);

  const ageIncrementBy = 1;
  const ageLowerLimit = 15;
  const ageUpperLimit = 80;
  const [rangeValues, setRangeValues] = useState([
    ageLowerLimit,
    ageUpperLimit,
  ]);

  // table data
  const [jobCategoryRowList, setJobCategoryRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    // {
    //   name: "Client",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    // {
    //   name: "Organization",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    "Search Key",
    "Name",
    "Gender",
    "Min Age Limit",
    "Max Age Limit",
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDIT_JOB_CATEGORY) ||
          checkIsAccessible(DELETE_JOB_CATEGORY),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  // table functions
  const [editJobCategory] = useState(() => (job_category_id, response_data) => {
    const editJobCategory = response_data.find(
      (item) => item.jobCategoryId === job_category_id
    );
    setJobCategoryId(job_category_id);
    setRangeValues([
      editJobCategory.ageLowerLimit,
      editJobCategory.ageUpperLimit,
    ]);
    document.querySelector("input[name='fm_1_search_key']").value =
      editJobCategory.searchKey;
    document.querySelector("input[name='fm_1_name']").value =
      editJobCategory.name;
    if (editJobCategory.gender.isActive) {
      setDpGenderValue(editJobCategory.gender.genderId);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: `${editJobCategory.gender.name} Gender Type has Deactivated`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
    document.querySelector("textarea[name='fm_1_remark']").value =
      editJobCategory.remark;
    document.querySelector("input[name='fm_1_is_active']").checked =
      editJobCategory.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteJobCategory] = useState(() => (job_category_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Job Category!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          jobCategoryId: job_category_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "/deleteJobCategory",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Job Category has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      document
        .querySelectorAll("input:required,select:required")
        .forEach((elem) =>
          elem.previousSibling.classList.add("required-input-mark")
        );
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({ url: "/viewJobCategory", data: reqBody });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.activeGenderList) {
            setGenderList((prevGenderList) => [
              ...prevGenderList,
              ...response_data.activeGenderList,
            ]);
          }
          if (response_data.allJobCategoryList) {
            setResData(response_data.allJobCategoryList);
          }
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated, sendRequest]);

  useEffect(() => {
    if (resData) {
      let tempList = [];
      resData.forEach((listItem) => {
        // if (
        //   loggedUser.activeOrgRoleList?.find(
        //     (item) => item.orgId === listItem.orgId
        //   )
        // ) {
        tempList.push([
          listItem.jobCategoryId,
          // loggedUser.activeClientList?.find(
          //   (item) => item.clientId === listItem.clientId
          // ).name,
          // loggedUser.activeOrgRoleList?.find(
          //   (item) => item.orgId === listItem.orgId
          // ).name,
          listItem.searchKey,
          listItem.name,
          listItem.gender.name,
          listItem.ageUpperLimit,
          listItem.ageLowerLimit,
          listItem.remark,
          listItem.isActive ? "True" : "False",
          <ControlButtons
            editMethod={() => editJobCategory(listItem.jobCategoryId, resData)}
            disableEdit={!checkIsAccessible(EDIT_JOB_CATEGORY)}
            deleteMethod={() =>
              deleteJobCategory(listItem.jobCategoryId, loggedUser)
            }
            disableDelete={!checkIsAccessible(DELETE_JOB_CATEGORY)}
          />,
        ]);
        // }
      });
      setJobCategoryRowList([...tempList]);
    }
  }, [
    resData,
    editJobCategory,
    deleteJobCategory,
    loggedUser,
    checkIsAccessible,
  ]);

  const resetForm = () => {
    setIsValidated(false);
    setDpGenderValue(0);
    setJobCategoryId(0);
    setRangeValues([ageLowerLimit, ageUpperLimit]);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkJobCategoryExist",
          data: {
            clientId: loggedUser.clientId,
            jobCategoryId: jobCategoryId,
            searchKey: formData.get("fm_1_search_key"),
            name: formData.get("fm_1_name"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let jobCategory = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                userId: parseInt(loggedUser.userId),
                jobCategoryId: jobCategoryId,
                isActive: document.querySelector("input[name='fm_1_is_active']")
                  ?.checked,
                searchKey: formData.get("fm_1_search_key"),
                name: formData.get("fm_1_name"),
                genderId: parseInt(dpGenderValue),
                ageUpperLimit: rangeValues[0],
                ageLowerLimit: rangeValues[1],
                remark: formData.get("fm_1_remark"),
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveJobCategory",
                  data: jobCategory,
                  template: "CONTROL_DATA",
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: jobCategoryId
                      ? "Job Category has successfully updated."
                      : "New Job Category has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setRefreshTable(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 2:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Name Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 3:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key & Name Exist",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Master File"
        children={childLinks}
      />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Job Category</h5>
                <span>Master File for creating a Job Category</span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_search_key">
                        Search Key
                      </label>
                      <input
                        id="id_input_fm_1_search_key"
                        name="fm_1_search_key"
                        type="text"
                        maxLength={45}
                        className="form-control"
                        placeholder="Enter Search Key"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateSearchKey: true,
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Search Key
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_name">Name</label>
                      <input
                        id="id_input_fm_1_name"
                        name="fm_1_name"
                        type="text"
                        maxLength={150}
                        className="form-control"
                        placeholder="Enter Job Category Name"
                        onChange={(evt) => formValidation({ event: evt })}
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Job Category
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-4 position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_district_id"
                        className="col-form-label pt-0"
                      >
                        Gender
                      </label>
                      <select
                        id="id_input_fm_1_sa_gender_id"
                        name="fm_1_sa_gender_id"
                        className="form-select"
                        onChange={(evt) => setDpGenderValue(evt.target.value)}
                        value={dpGenderValue}
                        required
                      >
                        {genderList.length
                          ? genderList.map((listItem, index) => (
                              <option value={listItem?.genderId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Gender
                      </small>
                    </div>
                  </div>
                  <div className="form-group mb-4 position-relative">
                    <label htmlFor="id_input_fm_1_search_key">Age Limit</label>
                    <Range
                      values={rangeValues}
                      step={ageIncrementBy}
                      min={ageLowerLimit}
                      max={ageUpperLimit}
                      onChange={(values) => {
                        setRangeValues(values);
                      }}
                      renderTrack={({ props, children }) => (
                        <div
                          onMouseDown={props.onMouseDown}
                          onTouchStart={props.onTouchStart}
                          style={{
                            ...props.style,
                            height: "36px",
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <output style={{ marginTop: "30px" }}>
                            {rangeValues[0]}
                          </output>
                          <div
                            ref={props.ref}
                            style={{
                              height: "5px",
                              width: "100%",
                              marginLeft: "30px",
                              marginRight: "30px",
                              borderRadius: "4px",
                              background: getTrackBackground({
                                values: rangeValues,
                                colors: ["#ccc", "#7366ff", "#ccc"],
                                min: ageLowerLimit,
                                max: ageUpperLimit,
                              }),
                              alignSelf: "center",
                            }}
                          >
                            {children}
                          </div>
                          <output style={{ marginTop: "30px" }}>
                            {rangeValues[1]}
                          </output>
                        </div>
                      )}
                      renderThumb={({ props, isDragged }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "42px",
                            width: "42px",
                            borderRadius: "4px",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 2px 6px #AAA",
                          }}
                        >
                          <div
                            style={{
                              height: "16px",
                              width: "5px",
                              backgroundColor: isDragged ? "#7366ff" : "#CCC",
                            }}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="form-group mb-4 position-relative">
                    <label htmlFor="id_input_fm_1_remark">Remark</label>
                    <textarea
                      id="id_input_fm_1_remark"
                      name="fm_1_remark"
                      maxLength={250}
                      rows="3"
                      className="form-control"
                      placeholder="Enter Remark"
                      onChange={(evt) =>
                        formValidation({ event: evt, checkExpression: "" })
                      }
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                  <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-1">
                      <input
                        id="id_input_fm_1_is_active"
                        name="fm_1_is_active"
                        type="checkbox"
                        disabled={!checkIsAccessible(DEACTIVATE_JOB_CATEGORY)}
                        defaultChecked
                      />
                      <label htmlFor="id_input_fm_1_is_active">Active</label>
                    </div>
                  </div>
                  {checkIsAccessible(INSERT_JOB_CATEGORY) ||
                  (checkIsAccessible(EDIT_JOB_CATEGORY) &&
                    jobCategoryId !== 0) ? (
                    <div className="d-sm-flex justify-content-end">
                      <button
                        className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                        type="submit"
                      >
                        {jobCategoryId ? "Update" : "Submit"}{" "}
                      </button>
                      <button
                        className="btn btn-warning col-12 col-sm-2"
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className="mb-4">
              <Datatable
                titleData="Job Category List"
                columnData={tableColumnHeaderList}
                rowData={jobCategoryRowList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobCategory;
