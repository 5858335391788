import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import {
  INSERT_JOB_CATEGORY,
  INSERT_JOB_CATEGORY_LINE,
  JOB_CATEGORY,
  JOB_CATEGORY_LINE,
} from "../../../../constant";
import ConfigDB from "../../../../data/customizer/config";

const Job = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpJobCategory, setRefreshDpJobCategory] = useState(false);
  const [refreshDpJobCategoryLine, setRefreshDpJobCategoryLine] =
    useState(false);

  // form values
  const [jobList, setJobList] = useState([
    { jobCategoryId: "", name: "-Select-" },
  ]);
  const [dpJobValue, setDpJobValue] = useState(0);
  const [vacancyList, setVacancyList] = useState([
    { jobCategoryLineId: "", name: "-Select-" },
  ]);
  const [dpVacancyValue, setDpVacancyValue] = useState(0);
  const [jobId, setJobId] = useState(0);

  // table data
  const [jobRowList, setJobRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Job Category",
    "Vacancy",
    {
      name: "Most Interested",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editJob] = useState(() => (job_id, response_data) => {
    const editingJob = response_data.find((item) => item.jobId === job_id);
    setJobId(editingJob.jobId);
    setDpJobValue(editingJob.jobCategoryId);
    setDpVacancyValue(editingJob.jobCategoryLineId);
    document.querySelector(
      "input[name='fm_2_is_most_interested_job']"
    ).checked = editingJob.isMostInterestedJob;
    document.querySelector("textarea[name='fm_2_remark']").value =
      editingJob.remark;
    document.querySelector("input[name='fm_2_is_active']").checked =
      editingJob.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteJob] = useState(() => (job_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Job Record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          jobId: job_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteJob",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Job Record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let job = {
        bpartnerId: parseInt(b_partner_id),
        jobId: parseInt(jobId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        jobCategoryLineId: parseInt(dpVacancyValue),
        remark: formData.get("fm_2_remark"),
        isActive: formData.get("fm_2_is_active") ? true : false,
        isMostInterestedJob: formData.get("fm_2_is_most_interested_job")
          ? true
          : false,
      };
      let nestedResponseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveJobTab",
          data: job,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      nestedResponseData.then((nested_reponse_data) => {
        if (nested_reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: jobId
              ? "Job has successfully updated."
              : "New Job has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setJobId(0);
    setDpJobValue(0);
    setDpVacancyValue(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewJobTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeJobCategoryList) {
              setJobList([
                { jobCategoryId: "", name: "-Select-" },
                ...response_data.activeJobCategoryList,
              ]);
            }
            if (response_data.allCustomerJobTableList) {
              let tempList = [];
              response_data.allCustomerJobTableList.forEach((listItem) => {
                tempList.push([
                  listItem.jobId,
                  listItem.jobCategoryName,
                  listItem.jobCategoryLineName,
                  listItem.isMostInterestedJob ? "True" : "False",
                  listItem.remark,
                  listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editJob(
                        listItem.jobId,
                        response_data.allCustomerJobTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() => deleteJob(listItem.jobId, loggedUser)}
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setJobRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (dpJobValue || refreshDpJobCategoryLine) {
      const reqBody = {
        jobCategoryId: dpJobValue,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveJobCategoryLineByJobCategoryId",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setVacancyList((prevList) => [prevList[0], ...response_data]);
          setRefreshDpJobCategoryLine(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpJobValue, refreshDpJobCategoryLine]);

  useEffect(() => {
    if (refreshDpJobCategory) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveJobCategory",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setJobList([
            { jobCategoryId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpJobCategory(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpJobCategory]);

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Job Details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-2 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
              <div clasasName="d-flex">
                <label
                  htmlFor="id_input_fm_2_sa_job_category_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Job
                </label>{" "}
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpJobCategory ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpJobCategory(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(JOB_CATEGORY, INSERT_JOB_CATEGORY)
                  }
                />
              </div>
              <select
                id="id_input_fm_2_sa_job_category_id"
                name="fm_2_sa_job_category_id"
                className="form-select"
                onChange={(evt) => setDpJobValue(evt.target.value)}
                value={dpJobValue}
                required
              >
                {jobList.length
                  ? jobList.map((listItem, index) => (
                      <option value={listItem?.jobCategoryId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select a Job
              </div>
            </div>
            <div className="form-group col-12 col-sm-4 mb-3 mb-sm-0 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_2_sa_vacancy_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Vacancy
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpJobCategoryLine ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpJobCategoryLine(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(
                      JOB_CATEGORY_LINE,
                      INSERT_JOB_CATEGORY_LINE
                    )
                  }
                />
              </div>
              <select
                id="id_input_fm_2_sa_vacancy_id"
                name="fm_2_sa_vacancy_id"
                className="form-select"
                onChange={(evt) => setDpVacancyValue(evt.target.value)}
                value={dpVacancyValue}
                disabled={dpJobValue ? false : true}
                required
              >
                {vacancyList.length
                  ? vacancyList.map((listItem, index) => (
                      <option value={listItem?.jobCategoryLineId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select a Vacancy
              </div>
            </div>
            <div className="form-group col-12 col-sm-4 d-flex align-items-end">
              <div className="checkbox checkbox-solid-dark">
                <input
                  id="id_input_fm_2_is_most_interested_job"
                  name="fm_2_is_most_interested_job"
                  type="checkbox"
                />
                <label
                  htmlFor="id_input_fm_2_is_most_interested_job"
                  className="mt-0 mb-0"
                >
                  Most Interested
                </label>
              </div>
            </div>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_2_remark">Remark</label>
            <textarea
              className="form-control"
              id="id_input_fm_2_remark"
              name="fm_2_remark"
              rows="3"
              maxLength={250}
              placeholder="Enter Remark"
              onChange={(evt) =>
                formValidation({ event: evt, checkExpression: "" })
              }
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {(checkIsAccessible(INSERTFUNCTION) && jobId === 0) ||
          (checkIsAccessible(DEACTIVATEFUNCTION) && jobId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-6">
                <input
                  id="id_input_fm_2_is_active"
                  name="fm_2_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_2_is_active">Active</label>
              </div>
            </div>
          ) : null}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && jobId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0" type="submit">
                  {jobId ? "Update" : "Submit"}
                </button>
                <button className="btn btn-warning col-12 col-sm-2" type="reset">
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Job List"
          columnData={tableColumnHeaderList}
          rowData={jobRowList}
        />
      </div>
    </>
  );
};

export default Job;
