// hooks
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import {
  CONNECTED_SOURCE,
  CONNECTED_SOURCE_LINE,
  CSL_AGENT_TYPE,
  CSL_CUSTOMER_TYPE,
  CSL_EMPLOYEE_TYPE,
  CSL_NONE_TYPE,
  CSL_TYPE_LIST,
  INSERT_CONNECTED_SOURCE,
  INSERT_CONNECTED_SOURCE_LINE,
  LOADED,
  LOADING,
} from "../../../../constant";
import formValidation from "../../../../customHooks/useValidation";
import { ConvertMillisToDateTime } from "../../../../data/util/DateUtil";
import ConfigDB from "../../../../data/customizer/config";

const ConnectedSource = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  const [refreshPage, setRefreshPage] = useState(true);
  const [btnText, setBtnText] = useState("Submit");
  const [searchStatus, setSearchStatus] = useState(LOADED);

  const [refreshDpConnectedSource, setRefreshDpConnectedSource] =
    useState(false);
  const [refreshDpConnectedSourceLine, setRefreshDpConnectedSourceLine] =
    useState(false);

  // form controls
  const [connectedSourceList, setConnectedSourceList] = useState([
    { connectedSourceId: "", name: "-Select-" },
  ]);
  const [dpConnectedSourceValue, setDpConnectedSourceValue] = useState(0);
  const [connectedSourceLineList, setConnectedSourceLineList] = useState([
    { connectedSourceLineId: "", name: "-Select-" },
  ]);
  const [dpConnectedSourceLineValue, setDpConnectedSourceLineValue] =
    useState(0);
  const [bPartnerList, setBPartnerList] = useState([
    { bpartnerId: "", name: "-Select-" },
  ]);
  const [dpBpartnerValue, setDpBpartnerValue] = useState(0);

  const [CSLType, setCSLType] = useState(CSL_NONE_TYPE);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let connectedSource = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        connectedSourceId: parseInt(dpConnectedSourceValue),
        connectedSourceLineId: parseInt(dpConnectedSourceLineValue),
        connectedBpartnerId: parseInt(dpBpartnerValue)
          ? parseInt(dpBpartnerValue)
          : -1,
        noticedTime: formData.get("fm_11_noticed_time")
          ? formData.get("fm_11_noticed_time").replace("T", " ")
          : "",
        isActive: true,
        remark: formData.get("fm_11_remark"),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveConnectedSourceTab",
          data: connectedSource,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text:
              dpConnectedSourceValue > 0
                ? "Connected Source has successfully updated."
                : "New Connected Source Record has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setRefreshPage(true);
          setIsLoaded(LOADING);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setDpConnectedSourceValue(0);
    setDpConnectedSourceLineValue(0);
    setDpBpartnerValue(0);
    setSearchStatus(LOADED);
    setCSLType(CSL_NONE_TYPE);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const searchCustomers = (evt) => {
    if (evt.target.value) {
      setSearchStatus(LOADING);
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/searchProfile",
          data: {
            clientId: loggedUser.clientId,
            orgIdList: loggedUser.activeOrgRoleList.map(
              (org_item) => org_item.orgId
            ),
            orgId: loggedUser.orgId,
            userId: loggedUser.userId,
            customerId: "",
            customerName: document.querySelector("input[name='fm_11_search']")
              .value,
            nic: "",
            districtId: -1,
            cc1DispositionId: -1,
            cc2DispositionId: -1,
            male: false,
            female: false,
            minAge: 0,
            maxAge: 0,
          },
        });
        resolve(result);
      }).then((response_data) => {
        let tempList = [{ bpartnerId: "", name: "-Select-" }];
        if (response_data.customerList.length > 0) {
          response_data.customerList.forEach((customer) => {
            tempList.push({
              bpartnerId: customer.bpartnerId,
              name: customer.fullName,
            });
          });
          setBPartnerList(tempList);
        }
        setSearchStatus(LOADED);
      });
    }
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshPage) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewConnectedSourceTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeConnectedSourceList) {
              setConnectedSourceList([
                { connectedSourceId: "", name: "-Select-" },
                ...response_data.activeConnectedSourceList,
              ]);
            }
            if (response_data.connectedSourceId) {
              if (response_data.activeConnectedSourceLineList) {
                setConnectedSourceLineList([
                  { connectedSourceLineId: "", name: "-Select-" },
                  ...response_data.activeConnectedSourceLineList,
                ]);
                setDpBpartnerValue(response_data.connectedBpartnerId);
              }
              setDpConnectedSourceValue(response_data.connectedSourceId);
              setDpConnectedSourceLineValue(
                response_data.connectedSourceLineId
              );
              document.querySelector("input[name='fm_11_noticed_time']").value =
                ConvertMillisToDateTime(response_data.noticedTime);
              setBtnText("Update");
            }
            setRefreshPage(false);
            setIsLoaded(LOADED);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshPage]);

  useEffect(() => {
    if (dpConnectedSourceValue > 0 || refreshDpConnectedSourceLine) {
      const reqBody = {
        connectedSourceId: dpConnectedSourceValue,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveConnectedSourceLineByConnectedSource",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setConnectedSourceLineList([
            { connectedSourceLineId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpConnectedSourceLine(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpConnectedSourceValue, refreshDpConnectedSourceLine]);

  useEffect(() => {
    if (dpConnectedSourceLineValue > 0) {
      const reqBody = {
        clientId: loggedUser.clientId,
        connectedSourceLineId: parseInt(dpConnectedSourceLineValue),
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getConnectedSourceLineTypeData",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        setCSLType(response_data.type);
        if (
          response_data.type === CSL_AGENT_TYPE ||
          response_data.type === CSL_EMPLOYEE_TYPE
        ) {
          setBPartnerList([
            { bpartnerId: "", name: "-Select-" },
            ...response_data.activeConnectedBpartnerList,
          ]);
          // document.querySelector("input[name='fm_11_noticed_time']").value = "";
          document.querySelector("input[name='fm_11_search']").value = "";
        } else {
          if (response_data.type === CSL_CUSTOMER_TYPE) {
            // document.querySelector("input[name='fm_11_noticed_time']").value =
            //   "";
            document.querySelector("input[name='fm_11_search']").value = "";
          }
          setBPartnerList([{ bpartnerId: "", name: "-Select-" }]);
          setDpBpartnerValue(0);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpConnectedSourceLineValue]);

  useEffect(() => {
    if (refreshDpConnectedSource) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveConnectedSource",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setConnectedSourceList([
            { connectedSourceId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpConnectedSource(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpConnectedSource]);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <Alert
        className="alert-dismissible d-flex align-items-center"
        color="warning"
        isOpen={b_partner_id === 0}
      >
        <AlertTriangle />
        <p className="ml-2">
          You must create a Customer, before add Customer Status.
        </p>
      </Alert>
      <form
        onSubmit={(evt) => submitForm(evt)}
        onReset={(evt) => resetForm(evt)}
        className={`position-relative form-11 ${
          isValidated ? "was-validated" : ""
        }`}
        noValidate
      >
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex justify-content-center align">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_11_sa_connected_source_id"
                className="col-form-label pt-0 required-input-mark"
              >
                Connected Source
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpConnectedSource ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpConnectedSource(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(
                    CONNECTED_SOURCE,
                    INSERT_CONNECTED_SOURCE
                  )
                }
              />
            </div>
            <select
              id="id_input_fm_11_sa_connected_source_id"
              name="fm_11_sa_connected_source_id"
              className="form-select"
              onChange={(evt) => setDpConnectedSourceValue(evt.target.value)}
              value={dpConnectedSourceValue}
              required
            >
              {connectedSourceList.length
                ? connectedSourceList.map((listItem, index) => (
                    <option value={listItem?.connectedSourceId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
            <div className="invalid-feedback position-absolute">
              Please select Connected Source
            </div>
          </div>
          <div className="form-group col-12 col-sm-4 mb-2 mb-sm-0 position-relative">
            <div className="d-flex">
              <label
                htmlFor="id_input_fm_11_sa_connected_source_line_id"
                className="col-form-label pt-0 required-input-mark"
              >
                Connected Source Line
              </label>
              <RefreshCw
                className={`mx-3 mt-1 dp-refresh-icon ${
                  refreshDpConnectedSourceLine ? "rotate-icon" : ""
                }`}
                height={"14px"}
                onClick={() => setRefreshDpConnectedSourceLine(true)}
              />
              <PlusCircle
                className="mt-1 dp-refresh-icon"
                height={"14px"}
                onClick={() =>
                  openMasterFileWindow(
                    CONNECTED_SOURCE_LINE,
                    INSERT_CONNECTED_SOURCE_LINE
                  )
                }
              />
            </div>
            <select
              id="id_input_fm_11_sa_connected_source_line_id"
              name="fm_11_sa_connected_source_line_id"
              className="form-select"
              onChange={(evt) =>
                setDpConnectedSourceLineValue(evt.target.value)
              }
              value={dpConnectedSourceLineValue}
              disabled={dpConnectedSourceValue ? false : true}
              required
            >
              {connectedSourceLineList.length
                ? connectedSourceLineList.map((listItem, index) => (
                    <option value={listItem?.connectedSourceLineId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
          <div className="form-group col-12 col-sm-4">
            <label htmlFor="id_input_fm_11_noticed_time">Noticed Time</label>
            <input
              id="id_input_fm_11_noticed_time"
              name="fm_11_noticed_time"
              type="datetime-local"
              className="form-control"
              // disabled={CSLType === CSL_NONE_TYPE ? false : true}
            />
          </div>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-12 col-sm-5 mb-2 mb-sm-0 position-relative">
            <label htmlFor="id_input_fm_11_search">Search Customer</label>
            <input
              id="id_input_fm_11_search"
              name="fm_11_search"
              type="text"
              maxLength={150}
              className="form-control"
              placeholder="Enter a Customer Name"
              onChange={(evt) => formValidation({ event: evt })}
              disabled={CSLType !== CSL_CUSTOMER_TYPE}
            />
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-group col-12 col-sm-3 mb-2 mb-sm-0 mx-0 d-flex align-items-end">
            <button
              type="button"
              className="btn btn-primary"
              disabled={CSLType !== CSL_CUSTOMER_TYPE}
              onClick={(evt) => searchCustomers(evt)}
            >
              {searchStatus === LOADED ? "Search" : "Searching..."}
            </button>
          </div>
          <div className="form-group col-12 col-sm-4 position-relative">
            <label
              htmlFor="id_input_fm_11_sa_bpartner_id"
              className={`col-form-label pt-0 ${
                CSLType === CSL_AGENT_TYPE || CSLType === CSL_EMPLOYEE_TYPE
                  ? "required-input-mark"
                  : ""
              }`}
            >
              {CSLType === CSL_NONE_TYPE
                ? "---"
                : CSL_TYPE_LIST.find((list_item) => list_item.value === CSLType)
                    .name}
              {CSLType === CSL_CUSTOMER_TYPE
                ? `(${bPartnerList.length - 1} results)`
                : null}
            </label>
            <select
              id="id_input_fm_11_sa_bpartner_id"
              name="fm_11_sa_bpartner_id"
              className="form-select"
              onChange={(evt) => setDpBpartnerValue(evt.target.value)}
              value={dpBpartnerValue}
              disabled={
                CSLType === CSL_AGENT_TYPE ||
                CSLType === CSL_EMPLOYEE_TYPE ||
                CSLType === CSL_CUSTOMER_TYPE
                  ? false
                  : true
              }
              required={
                CSLType === CSL_AGENT_TYPE || CSLType === CSL_EMPLOYEE_TYPE
              }
            >
              {bPartnerList.length
                ? bPartnerList.map((listItem, index) => (
                    <option value={listItem?.bpartnerId} key={index}>
                      {listItem?.name}
                    </option>
                  ))
                : null}
            </select>
          </div>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="id_input_fm_11_remark">Remark</label>
          <textarea
            id="id_input_fm_11_remark"
            name="fm_11_remark"
            rows="3"
            maxLength={250}
            className="form-control"
            placeholder="Enter Remark"
            onChange={(evt) =>
              formValidation({
                event: evt,
                checkExpression: "",
              })
            }
          ></textarea>
          <small className="txt-danger position-absolute d-none input-validation"></small>
        </div>
        {/* {(checkIsAccessible(INSERTFUNCTION) &&
          educationQualificationId === 0) ||
        (checkIsAccessible(DEACTIVATEFUNCTION) &&
          educationQualificationId !== 0) ? (
          <div className="form-group mb-4">
            <div className="checkbox col-6">
              <input
                id="id_input_fm_11_is_active"
                name="fm_11_is_active"
                type="checkbox"
                defaultChecked
              />
              <label htmlFor="id_input_fm_11_is_active">Active</label>
            </div>
          </div>
        ) : null} */}
        {b_partner_id ? (
          (checkIsAccessible(INSERTFUNCTION) && btnText === "Submit") ||
          (checkIsAccessible(EDITFUNCTION) && btnText === "Update") ? (
            <div className="d-sm-flex justify-content-end">
              <button
                className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                type="submit"
              >
                {" "}
                {btnText}
              </button>
              <button className="btn btn-warning col-12 col-sm-2" type="reset">
                {" "}
                Reset
              </button>
            </div>
          ) : null
        ) : null}
      </form>
    </div>
  );
};

export default ConnectedSource;
