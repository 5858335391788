// hooks
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "../common/breadcrumb";
import Datatable, { BooleanValue, ControlButtons } from "../common/datatable";
import VideoUploader from "../common/videoUploader";

// third-party components
import Swal from "sweetalert2";

// custom hooks
import useAccess from "../../customHooks/useAccess";
import useAxiosTemplates from "../../customHooks/useAxiosTemplates";

// constants
import { LOADED, LOADING } from "../../constant";

// utilities
import formValidation from "../../customHooks/useValidation";
import axios from "axios";
import ConfigDB from "../../data/customizer/config";

const InterviewVideo = () => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  const MAXFILELIMIT = 1;

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  const customerData = useSelector((content) => content.CustomerReducer);
  const { customer_id } = useParams();

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // breadcrumb values
  let childLinks = [
    { value: customer_id, active: false },
    { value: "Interview Video", active: true },
  ];

  // helper containers
  const [attachmentList, setAttachmentList] = useState([]);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  // const [isLoaded, setIsLoaded] = useState(LOADING);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(LOADED);

  // form values
  const [videoId, setVideoId] = useState(0);

  // table data
  const [videoRowList, setVideoRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Name",
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "Action",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  // table functions
  const [editVideo] = useState(() => (video_id, response_data) => {
    resetForm();
    setTimeout(() => {
      const editingVideo = response_data.find(
        (item) => item.videoId === video_id
      );
      setVideoId(editingVideo.videoId);
      setPreviewFileList([
        {
          id: editingVideo.videoId,
          type: editingVideo.documentPath.split(".")[1],
          source: editingVideo.documentPath,
        },
      ]);
      setAttachmentList([
        {
          documentId: editingVideo.videoId,
          isModified: false,
          attachmentExtension: "",
          attachment: "",
        },
      ]);
      document.querySelector("input[name='fm_1_name']").value =
        editingVideo.name;
      document.querySelector("textarea[name='fm_1_remark']").value =
        editingVideo.remark;
      if (document.querySelector("input[name='fm_1_is_active']")) {
        document.querySelector("input[name='fm_1_is_active']").checked =
          editingVideo.isActive;
      }
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 1000);
  });
  const [deleteVideo] = useState(() => (video_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Video!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          videoId: video_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteVideo",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Video has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    setIsLoaded(LOADING);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      const video = new FormData();
      video.append("clientId", loggedUser.clientId);
      video.append("orgId", loggedUser.orgId);
      video.append("userId", loggedUser.userId);
      video.append("videoId", parseInt(videoId));
      video.append("bpartnerId", parseInt(customerData.bpartnerId));
      video.append("name", formData.get("fm_1_name"));
      video.append("isActive", formData.get("fm_1_is_active") ? true : false);
      video.append(
        "isAttachmentModified",
        attachmentList.length > 0 ? attachmentList[0].isModified : false
      );
      if (attachmentList.length) {
        if (attachmentList[0].isModified) {
          video.append(
            "attachmentExtension",
            attachmentList[0].attachmentExtension
          );
        }
      }
      if (attachmentList.length) {
        if (attachmentList[0].isModified) {
          video.append(
            "attachment",
            attachmentList.length > 0 ? attachmentList[0].attachment : null
          );
        }
      }
      video.append("remark", formData.get("fm_1_remark"));

      const reqInstance = axios.create({
        baseURL: ConfigDB.data.backendServerUrl,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          UserId: loggedUser.userId,
        },
      });
      reqInstance
        .post("/customer/saveInterviewVideoTabNew", video, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            // Update progress bar UI
            setUploadPercentage(progress);
          },
        })
        .then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: videoId
                ? "Video has successfully updated."
                : "New Video has successfully uploaded.",
              showConfirmButton: false,
              timer: 5000,
            });
            // setIsLoaded(LOADING);
            setRefreshTable(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const resetForm = () => {
    setIsValidated(false);
    setAttachmentList([]);
    setPreviewFileList([]);
    setVideoId(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  useEffect(() => {
    console.log(previewFileList);
    console.log(attachmentList);
  }, [previewFileList]);

  useEffect(() => {
    if (refreshTable) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: parseInt(customerData.bpartnerId),
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/viewInterviewVideoTab",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          if (response_data.customerVideoList) {
            let tempList = [];
            response_data.customerVideoList.forEach((listItem) => {
              tempList.push([
                listItem.videoId,
                listItem.name,
                listItem.remark,
                listItem.isActive ? "True" : "False",
                <ControlButtons
                  editMethod={() =>
                    editVideo(listItem.videoId, response_data.customerVideoList)
                  }
                  disableEdit={!checkIsAccessible(EDITFUNCTION)}
                  deleteMethod={() => deleteVideo(listItem.videoId, loggedUser)}
                  disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                />,
              ]);
            });
            setVideoRowList([...tempList]);
          }
          setIsLoaded(LOADED);
          setRefreshTable(false);
        }
      });
    }
  }, [refreshTable]);

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Interview Video"
        children={childLinks}
      />
      <div className="card-modified-body bg-white pt-0 mb-4 position-relative">
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex flex-column justify-content-center align-items-center">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
            <div>Saving... {uploadPercentage}%</div>
          </div>
        ) : null}
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm()}
          className={`form-1 ${isValidated ? "was-validated" : ""}`}
          noValidate
        >
          <div className="form-group col-12 pt-3 mb-3 position-relative">
            <label htmlFor="id_input_fm_1_name" className="required-input-mark">
              Name
            </label>
            <input
              id="id_input_fm_1_name"
              name="fm_1_name"
              type="text"
              maxLength={150}
              className="form-control"
              placeholder="Enter Video Name"
              onChange={(evt) => formValidation({ event: evt })}
              required
            />
            <div className="invalid-feedback position-absolute">
              Please enter Video Name.
            </div>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <VideoUploader
            label={"Interview Video"}
            attachment_list={attachmentList}
            preview_list={previewFileList}
            set_attachment_list={setAttachmentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
          />
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_1_remark">Remark</label>
            <textarea
              id="id_input_fm_1_remark"
              name="fm_1_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remarks"
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {(checkIsAccessible(INSERTFUNCTION) && videoId === 0) ||
          (checkIsAccessible(DEACTIVATEFUNCTION) && videoId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-2">
                <input
                  id="id_input_fm_1_is_active"
                  name="fm_1_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_1_is_active">Active</label>
              </div>
            </div>
          ) : null}
          {checkIsAccessible(INSERTFUNCTION) ||
          (checkIsAccessible(EDITFUNCTION) && videoId !== 0) ? (
            <div className="d-sm-flex justify-content-end">
              <button
                className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                type="submit"
              >
                {videoId ? "Update" : "Submit"}
              </button>
              <button className="btn btn-warning col-12 col-sm-2" type="reset">
                Reset
              </button>
            </div>
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Interview Video List"
          columnData={tableColumnHeaderList}
          rowData={videoRowList}
        />
      </div>
    </>
  );
};

export default InterviewVideo;
