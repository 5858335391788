export const ConvertMillisToDate = (date_in_millis) => {
  /**
   * Convert a millisecond value to date
   * eg:- 390700800000 => 1982-05-20
   *
   * @params {int} date_in_millis - date value in miliseconds
   * @return {string}               date value in yyyy-mm-dd format
   */
  if (!date_in_millis) {
    return "Not Specified";
  } else {
    let date = new Date(parseInt(date_in_millis));
    return `${date.getFullYear()}-${
      date.getMonth() + 1 >= 10
        ? (date.getMonth() + 1).toString()
        : "0" + (date.getMonth() + 1).toString()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()}`;
  }
};

export const ConvertMillisToDateTime = (datetime_in_millis) => {
  /**
   * Convert a millisecond value to date and time
   * eg:- 390700800000 => 1982-05-20 11:23
   *
   * @params {int} date_in_millis - date value in miliseconds
   * @return {string}               date value in yyyy-mm-dd format
   */
  let date = new Date(parseInt(datetime_in_millis));
  return `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1).toString()
  }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()}T${
    date.getHours() > 9 ? date.getHours() : "0" + date.getHours().toString()
  }:${
    date.getMinutes() > 9
      ? date.getMinutes()
      : "0" + date.getMinutes().toString()
  }`;
};

export const FormattedCurrentTime = () => {
  /**
   * Return current date in HH:mm format
   * eg:- 15:00
   *
   * @return {string} current time in HH:mm format
   */
  let date = new Date();
  return `${
    date.getHours() > 9
      ? date.getHours().toString()
      : "0" + date.getHours().toString()
  }:${
    date.getMinutes() > 9
      ? date.getMinutes()
      : "0" + date.getMinutes().toString()
  }`;
};

export const FormattedCurrentDate = () => {
  /**
   * Return current date in yyyy-mm-dd format
   * eg:- 1982-05-20
   *
   * @return {string} current date in yyyy-mm-dd format
   */
  let date = new Date();
  return `${date.getFullYear()}-${
    date.getMonth() + 1 >= 10
      ? (date.getMonth() + 1).toString()
      : "0" + (date.getMonth() + 1).toString()
  }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()}`;
};
export const FormattedCurrentDateTypeTwo = () => {
  /**
   * Return current date in yyyy-mm-dd format
   * eg:- 1982-05-20
   *
   * @return {string} current date in yyyy-mm-dd format
   */
  let date = new Date();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${
    date.getDate() > 9 ? date.getDate() : "0" + date.getDate().toString()
  }-${months[date.getMonth()]}-${date.getFullYear()}`;
};

export const ShowTimeAgo = (datetime_in_millis) => {
  /**
   * Return how much time ago compared to current date.
   * eg:- 10 sec ago, 10 min ago, 1 hour ago, 1 day ago, 1 month ago, 1 year ago
   *
   * @params {int} date_in_millis - date value in miliseconds
   * @return {string}               date/time difference
   */
  let datetime = new Date(parseInt(datetime_in_millis));
  let currentDate = new Date();
  let difference = new Date(currentDate - datetime).getTime();
  if (difference / (1000 * 60 * 60 * 24 * 30 * 12) >= 1) {
    // getting in years
    let roundedDifference = Math.round(
      difference / (1000 * 60 * 60 * 24 * 30 * 12)
    );
    return `${roundedDifference} year${roundedDifference === 1 ? "" : "s"}`;
  } else if (difference / (1000 * 60 * 60 * 24 * 30) >= 1) {
    // getting in months
    let roundedDifference = Math.round(difference / (1000 * 60 * 60 * 24 * 30));
    return `${roundedDifference} month${roundedDifference === 1 ? "" : "s"}`;
  } else if (difference / (1000 * 60 * 60 * 24) >= 1) {
    // getting in days
    let roundedDifference = Math.round(difference / (1000 * 60 * 60 * 24));
    return `${roundedDifference} day${roundedDifference === 1 ? "" : "s"}`;
  } else if (difference / (1000 * 60 * 60) >= 1) {
    // getting in hours
    let roundedDifference = Math.round(difference / (1000 * 60 * 60));
    return `${roundedDifference} hour${roundedDifference === 1 ? "" : "s"}`;
  } else if (difference / (1000 * 60) >= 1) {
    // getting in minutes
    return `${Math.round(difference / (1000 * 60))} min`;
  } else {
    // getting in seconds
    return `${Math.round(difference / 1000)} sec`;
  }
};

export const CalculateYearDifference = (date_in_millis) => {
  /**
   * Return year difference between two dates.
   * eg:- 1999-03-30 - 2002-03-30 -> 2
   *
   * @params {int} date_in_millis - date value in miliseconds
   * @return {int}                  date difference in years
   */
  let datetime = new Date(parseInt(date_in_millis));
  let currentDate = new Date();
  let difference = new Date(currentDate - datetime).getTime();
  // return Math.floor(difference / (1000 * 60 * 60 * 24 * 30 * 12));//less accurate
  return Math.floor(difference / (1000 * 60 * 60 * 24 * 365));
};
