// hooks
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle } from "react-feather";
import { LOADED, LOADING } from "../../../../constant";
import formValidation from "../../../../customHooks/useValidation";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";

const VisitDetail = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form values
  const [detailId, setDetailId] = useState(0);

  // table data
  const [detailRowList, setDetailRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Visited Date",
    "Remark",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editDetail] = useState(() => (detail_id, response_data) => {
    const editingDetail = response_data.find(
      (item) => item.visitDetailId === detail_id
    );
    setDetailId(editingDetail.visitDetailId);
    document.querySelector("input[name='fm_12_visit_date']").value =
      ConvertMillisToDate(editingDetail.visitedDate);
    document.querySelector("textarea[name='fm_12_remark']").value =
      editingDetail.remark;
    document.querySelector("input[name='fm_12_is_active']").checked =
      editingDetail.isActive;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  const [deleteDetail] = useState(() => (detail_id, logged_user) => {
    //     Swal.fire({
    //       icon: "warning",
    //       title: "Are you sure?",
    //       text: "This will permenantly deletes this Visit Detail Record!!!",
    //       showDenyButton: true,
    //       confirmButtonText: "Delete",
    //       denyButtonText: "Cancel",
    //       denyButtonColor: "#2a3142",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         const reqBody = {
    //           clientId: logged_user.clientId,
    //           orgId: logged_user.orgId,
    //           userId: logged_user.userId,
    //           familyId: family_id,
    //         };
    //         let responseData = new Promise((resolve) => {
    //           const result = sendRequest({
    //             url: "customer/deleteFamily",
    //             data: reqBody,
    //             template: "CONTROL_DATA",
    //           });
    //           resolve(result);
    //         });
    //         responseData.then((response_data) => {
    //           if (response_data) {
    //             Swal.fire({
    //               icon: "success",
    //               title: "Request Successful",
    //               text: "Family Record has successfully deleted.",
    //               showConfirmButton: false,
    //               timer: 5000,
    //             });
    //             setRefreshTable(true);
    //           }
    //         });
    //       }
    //     });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let visitDetail = {
        bpartnerId: parseInt(b_partner_id),
        visitDetailId: parseInt(detailId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        visitedDate: formData.get("fm_12_visit_date"),
        remark: formData.get("fm_12_remark"),
        isActive: formData.get("fm_12_is_active") ? true : false,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveVisitDetailTab",
          data: visitDetail,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: detailId
              ? "Visit Detail Record has successfully updated."
              : "New Detail Record has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setDetailId(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  useEffect(() => {
    document
      .querySelectorAll("input:required,select:required,textarea:required")
      .forEach((elem) =>
        elem.previousSibling.classList.add("required-input-mark")
      );
  }, []);

  useEffect(() => {
    document
      .querySelectorAll("input:required,select:required")
      .forEach((elem) =>
        elem.previousSibling.classList.add("required-input-mark")
      );
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewVisitDetailTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.allCustomerVisitDetailTableList) {
              let tempList = [];
              response_data.allCustomerVisitDetailTableList.forEach(
                (listItem) => {
                  tempList.push([
                    listItem.visitDetailId,
                    ConvertMillisToDate(listItem.visitedDate),
                    listItem.remark,
                    listItem.isActive ? "True" : "False",
                    <ControlButtons
                      editMethod={() =>
                        editDetail(
                          listItem.visitDetailId,
                          response_data.allCustomerVisitDetailTableList
                        )
                      }
                      disableEdit={!checkIsAccessible(EDITFUNCTION)}
                      deleteMethod={() =>
                        deleteDetail(listItem.visitDetailId, loggedUser)
                      }
                      disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                    />,
                  ]);
                }
              );
              setDetailRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  }, [refreshTable]);

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Visit Details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-12 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-3">
              <label htmlFor="id_input_fm_12_visit_date">Visited Date</label>
              <input
                id="id_input_fm_12_visit_date"
                name="fm_12_visit_date"
                type="date"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_12_remark">Remark</label>
            <textarea
              id="id_input_fm_12_remark"
              name="fm_12_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remarks"
              required
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          {(checkIsAccessible(INSERTFUNCTION) && detailId === 0) ||
          (checkIsAccessible(DEACTIVATEFUNCTION) && detailId !== 0) ? (
            <div className="form-group mb-4">
              <div className="checkbox checkbox-solid-dark">
                <input
                  id="id_input_fm_12_is_active"
                  name="fm_12_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_12_is_active">Active</label>
              </div>
            </div>
          ) : null}
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && detailId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {" "}
                  {detailId ? "Update" : "Submit"}
                </button>
                <button
                  className="btn btn-warning col-12 col-sm-2"
                  type="reset"
                >
                  {" "}
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Visit Records"
          columnData={tableColumnHeaderList}
          rowData={detailRowList}
        />
      </div>
    </>
  );
};

export default VisitDetail;
