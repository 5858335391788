import React from "react";
import MUIDataTable, { TableFooter } from "mui-datatables";
import { Search, Printer, Download, Columns, Filter } from "react-feather";
import { CheckCircle, Circle, Trash2, Edit2 } from "react-feather";

const Datatable = ({
  titleData,
  columnData,
  rowData,
  sort = true,
  toolBar = true,
  customizedHead = false,
  onRowClick = null,
  onCellClick = null,
  onDownload = null
}) => {
  let options = {
    Tooltip: "",
    filterType: "checkbox",
    sort: sort,
    print: false,
    elevation: 0,
    responsive: "standard",
    isRowSelectable: (dataIndex, selectedRows, data) => false,
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style: { padding: "8px 24px"},
    //   };
    // },
  };
  if (onRowClick !== null) {
    options = {
      ...options,
      onRowClick: onRowClick,
    };
  }
  if (onCellClick !== null) {
    options = {
      ...options,
      onCellClick: onCellClick,
    };
  }
  if (onDownload !== null) {
    options = {
      ...options,
      onDownload: onDownload,
    };
  }

  let components = {
    icons: {
      SearchIcon: Search,
      PrintIcon: Printer,
      DownloadIcon: Download,
      ViewColumnIcon: Columns,
      FilterIcon: Filter,
    },
  };
  if (!toolBar) {
    components = { ...components, TableToolbar: () => false };
  }
  if (customizedHead) {
    components = {
      ...components,
      TableHead: (data) => {
        const filteredHeaders = data.columns.filter(
          (item) => item.display === "true"
        );
        return (
          <thead>
            <tr className="bg-light">
              {filteredHeaders.map((item, index) => (
                <th key={index} className="text-dark py-2 px-4">
                  {item.name}
                </th>
              ))}
            </tr>
          </thead>
        );
      },
    };
  }

  return (
    <MUIDataTable
      title={
        <div className="fs-5" style={{ fontFamily: "Nunito" }}>
          {titleData}
        </div>
      }
      data={rowData}
      columns={columnData}
      options={options}
      components={components}
    />
  );
};

export default Datatable;
export const ControlButtons = ({
  editMethod,
  deleteMethod,
  viewMethod,
  customMethod,
  buttonName,
  showView = false,
  disableEdit = false,
  disableDelete = false,
  customButton = false
}) => {
  return (
    <div className="d-flex">
      {customButton === true ? (
        <button
          onClick={customMethod}
          className="btn btn-outline-primary d-flex align-items-center"
        >
          {buttonName}
        </button>
      ) : null}
      {showView === true ? (
        <button
          onClick={viewMethod}
          className="btn btn-outline-primary d-flex align-items-center"
        >
          View
        </button>
      ) : null}
      {disableEdit === false ? (
        <button
          onClick={editMethod}
          className="btn btn-outline-info d-flex align-items-center"
        >
          <Edit2 height={"16px"} width={"16px"} />
        </button>
      ) : null}
      {disableDelete === false ? (
        <button
          onClick={deleteMethod}
          className="btn btn-outline-danger mx-1 d-flex align-items-center"
        >
          <Trash2 height={"16px"} width={"16px"} />
        </button>
      ) : null}
    </div>
  );
};
export const BooleanValue = ({ value }) => {
  return value === "True" ? (
    <CheckCircle height={"16px"} width={"16px"} />
  ) : (
    <Circle height={"16px"} width={"16px"} />
  );
};
