import {
  Circle,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
  Svg,
} from "@react-pdf/renderer";

export const Phone = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    viewBox="0 0 24 24"
    fill="#ffffff"
    stroke="#A0616A"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-phone"
  >
    <Path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></Path>
  </Svg>
);
export const Globe = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    viewBox="0 0 24 24"
    fill="#ffffff"
    stroke="#A0616A"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-globe"
  >
    <Circle cx="12" cy="12" r="10"></Circle>
    <Line x1="2" y1="12" x2="22" y2="12"></Line>
    <Path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></Path>
  </Svg>
);
export const Mail = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    viewBox="0 0 24 24"
    fill="#ffffff"
    stroke="#A0616A"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-mail"
  >
    <Path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></Path>
    <Polyline points="22,6 12,13 2,6"></Polyline>
  </Svg>
);
export const Location = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    viewBox="0 0 24 24"
    fill="#ffffff"
    stroke="#A0616A"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-map-pin"
  >
    <Path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></Path>
    <Circle cx="12" cy="10" r="3"></Circle>
  </Svg>
);
export const TitleIcon = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height, marginRight: 5, marginBottom: -3 }}
    viewBox="0 0 24 24"
    fill="#ffffff"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-layers"
  >
    <Polygon points="12 2 2 7 12 12 22 7 12 2"></Polygon>
    <Polyline points="2 17 12 22 22 17"></Polyline>
    <Polyline points="2 12 12 17 22 12"></Polyline>
  </Svg>
);
export const RemarkIcon = ({ width, height }) => (
  // <Svg
  //   style={{ width: width, height: height, marginRight: 5, marginBottom: -3 }}
  //   xmlns="http://www.w3.org/2000/svg"
  //   id="Outline"
  //   viewBox="0 0 24 24"
  //   fill="#ffffff"
  //   stroke="#1B998B"
  //   stroke-width="1"
  // >
  //   <Path d="M20.137,24a2.8,2.8,0,0,1-1.987-.835L12,17.051,5.85,23.169a2.8,2.8,0,0,1-3.095.609A2.8,2.8,0,0,1,1,21.154V5A5,5,0,0,1,6,0H18a5,5,0,0,1,5,5V21.154a2.8,2.8,0,0,1-1.751,2.624A2.867,2.867,0,0,1,20.137,24ZM6,2A3,3,0,0,0,3,5V21.154a.843.843,0,0,0,1.437.6h0L11.3,14.933a1,1,0,0,1,1.41,0l6.855,6.819a.843.843,0,0,0,1.437-.6V5a3,3,0,0,0-3-3Z" />
  // </Svg>
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="M2.849,23.55a2.954,2.954,0,0,0,3.266-.644L12,17.053l5.885,5.853a2.956,2.956,0,0,0,2.1.881,3.05,3.05,0,0,0,1.17-.237A2.953,2.953,0,0,0,23,20.779V5a5.006,5.006,0,0,0-5-5H6A5.006,5.006,0,0,0,1,5V20.779A2.953,2.953,0,0,0,2.849,23.55Z" />
  </Svg>
);

export const PersonalIcon = ({ width, height }) => (
  // <Svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   style={{ width: width, height: height, marginRight: 5, marginBottom: -3 }}
  //   id="Layer_1"
  //   viewBox="0 0 24 24"
  //   data-name="Layer 1"
  //   fill="#ffffff"
  //   stroke="#1B998B"
  //   stroke-width="1"
  // >
  //   <Path d="m19 4h-4v-1a3 3 0 0 0 -6 0v1h-4a5.006 5.006 0 0 0 -5 5v10a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-10a5.006 5.006 0 0 0 -5-5zm-8-1a1 1 0 0 1 2 0v2a1 1 0 0 1 -2 0zm11 16a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3-3v-10a3 3 0 0 1 3-3h4.184a2.982 2.982 0 0 0 5.632 0h4.184a3 3 0 0 1 3 3zm-12-9h-5a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-8a1 1 0 0 0 -1-1zm-1 8h-3v-6h3zm11-3a1 1 0 0 1 -1 1h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1zm0-4a1 1 0 0 1 -1 1h-5a1 1 0 0 1 0-2h5a1 1 0 0 1 1 1zm-2 8a1 1 0 0 1 -1 1h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 1 1z" />
  // </Svg>
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="m19 4h-4v-1a3 3 0 0 0 -6 0v1h-4a5.006 5.006 0 0 0 -5 5v10a5.006 5.006 0 0 0 5 5h14a5.006 5.006 0 0 0 5-5v-10a5.006 5.006 0 0 0 -5-5zm-9 14a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1-1v-8a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1zm2-12a1 1 0 0 1 -1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1 -1 1zm6 13h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2zm2-4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm0-4h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zm-15 0h3v6h-3z" />
  </Svg>
);

export const EduIcon = ({ width, height }) => (
  // <Svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   style={{ width: width, height: height, marginRight: 5, marginBottom: -3 }}
  //   id="Layer_1"
  //   data-name="Layer 1"
  //   viewBox="0 0 24 24"
  //   fill="#ffffff"
  //   stroke="#1B998B"
  //   stroke-width="1"
  // >
  //   <Path d="M24,3v14.04c0,2.22-1.491,4.197-3.626,4.808l-6.726,1.921c-1.078,.308-2.218,.308-3.296,0l-6.726-1.921c-2.135-.61-3.626-2.587-3.626-4.808V4c.006-1.308,1.994-1.307,2,0v13.04c0,1.333,.895,2.519,2.176,2.885l6.726,1.921c.719,.205,1.478,.205,2.198,0l6.725-1.921c1.281-.366,2.176-1.552,2.176-2.885V3c.006-1.308,1.994-1.307,2,0ZM4,14.937V4c0-1.267,.58-2.431,1.591-3.193C6.602,.044,7.88-.194,9.099,.154l1.726,.493c.446,.127,.845,.354,1.176,.651,.331-.297,.73-.524,1.176-.651l1.725-.493c2.461-.778,5.171,1.268,5.099,3.846V14.937c0,1.776-1.193,3.358-2.901,3.846l-3.451,.986c-1.079,.308-2.219,.308-3.296,0l-3.451-.986c-1.708-.488-2.901-2.07-2.901-3.846Zm9,2.936c.033-.009,3.549-1.013,3.549-1.013,.854-.244,1.451-1.035,1.451-1.923V4c0-.633-.29-1.215-.795-1.597-.508-.384-1.151-.499-1.754-.327l-1.725,.493c-.427,.122-.726,.517-.726,.961v14.342Zm-7-2.936c0,.888,.597,1.679,1.451,1.923,0,0,3.516,1.004,3.549,1.013V3.531c0-.444-.298-.839-.725-.961l-1.726-.493c-.603-.173-1.247-.057-1.754,.327-.505,.381-.795,.963-.795,1.597V14.937Z" />
  // </Svg>
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path
      d="m24 8.48v11.52a1 1 0 0 1 -2 0v-8.248l-7.4 3.536a5 5 0 0 1
    -2.577.694 5.272 5.272 0 0 1 -2.7-.739l-7.38-3.513a3.691 3.691 0 0 1
    -.084-6.455c.027-.016.056-.031.084-.045l7.457-3.558a5.226 5.226 0 0 1
    5.282.045l7.375 3.513a3.767 3.767 0 0 1 1.943 3.25zm-11.978 9.5a7.26 7.26 0
    0 1 -3.645-.972l-4.377-2.089v2.7a5.007 5.007 0 0 0 3.519 4.778 15.557 15.557
    0 0 0 4.481.603 15.557 15.557 0 0 0 4.481-.607 5.007 5.007 0 0 0
    3.519-4.778v-2.691l-4.459 2.13a6.983 6.983 0 0 1 -3.519.928z"
    />
  </Svg>
);

export const PassportIcon = ({ width, height }) => (
  // <Svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   style={{ width: width, height: height, marginRight: 5, marginBottom: -3 }}
  //   id="Layer_1"
  //   data-name="Layer 1"
  //   viewBox="0 0 24 24"
  //   fill="#ffffff"
  //   stroke="#1B998B"
  //   stroke-width="1"
  // >
  //   <Path d="M21,10H17.693L13.446,1.563A3,3,0,0,0,10.812,0H10.63A2.63,2.63,0,0,0,8.1,3.361L10.028,10H7.644a2.982,2.982,0,0,1-2.122-.879L4.105,7.7a2.409,2.409,0,0,0-2.814-.433A2.319,2.319,0,0,0,.125,8.689a2.525,2.525,0,0,0,.228,1.966L1.967,13.48A5.012,5.012,0,0,0,6.308,16h3.711L8.351,20.447A2.629,2.629,0,0,0,10.812,24a2.972,2.972,0,0,0,2.605-1.513L17.464,16H21a3,3,0,0,0,0-6ZM10.025,2.805A.63.63,0,0,1,10.63,2h.182a.989.989,0,0,1,.863.491L15.455,10H12.11ZM21,14H16.909a1,1,0,0,0-.848.471L11.69,21.479a1,1,0,0,1-.878.521.628.628,0,0,1-.588-.85l2.174-5.8A1,1,0,0,0,11.461,14H6.308a3,3,0,0,1-2.6-1.512L2.089,9.662a.523.523,0,0,1-.047-.4A.323.323,0,0,1,2.2,9.051.437.437,0,0,1,2.4,9a.415.415,0,0,1,.287.118l1.417,1.417A4.972,4.972,0,0,0,7.644,12H21a1,1,0,0,1,0,2Z" />
  // </Svg>
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="M23.286,10.058A2.992,2.992,0,0,0,21,9H17.58L13.313,1.546a2.883,2.883,0,0,0-4.807-.425,2.688,2.688,0,0,0-.363,2.426L10.083,9H5.532L4.116,7.1A2.43,2.43,0,0,0,2.079,6h0A2.075,2.075,0,0,0,.126,8.788L2,12,.126,15.212A2.075,2.075,0,0,0,2.075,18h0a2.43,2.43,0,0,0,2.037-1.1L5.532,15h4.551l-1.94,5.453a2.688,2.688,0,0,0,.363,2.426,2.883,2.883,0,0,0,4.807-.425L17.58,15H21a3.011,3.011,0,0,0,2.286-4.942Z" />
  </Svg>
);

export const WorkIcon = ({ width, height }) => (
  // <Svg
  //   xmlns="http://www.w3.org/2000/svg"
  //   style={{ width: width, height: height, marginRight: 5, marginBottom: -3 }}
  //   id="Outline"
  //   viewBox="0 0 24 24"
  //   fill="#ffffff"
  //   stroke="#1B998B"
  //   stroke-width="1"
  // >
  //   <Path d="M19,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H5A5.006,5.006,0,0,0,0,9V19a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V9A5.006,5.006,0,0,0,19,4ZM11,2h2a3,3,0,0,1,2.816,2H8.184A3,3,0,0,1,11,2ZM5,6H19a3,3,0,0,1,3,3v3H2V9A3,3,0,0,1,5,6ZM19,22H5a3,3,0,0,1-3-3V14h9v1a1,1,0,0,0,2,0V14h9v5A3,3,0,0,1,19,22Z" />
  // </Svg>
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="M19,4H17.9A5.009,5.009,0,0,0,13,0H11A5.009,5.009,0,0,0,6.1,4H5A5.006,5.006,0,0,0,0,9v3H24V9A5.006,5.006,0,0,0,19,4ZM8.184,4A3,3,0,0,1,11,2h2a3,3,0,0,1,2.816,2Z" />
    <Path d="M13,15a1,1,0,0,1-2,0V14H0v5a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V14H13Z" />
  </Svg>
);

export const GeneralIcon = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="m14 7v-6.54a6.977 6.977 0 0 1 2.465 1.59l3.484 3.486a6.954 6.954 0 0 1 1.591 2.464h-6.54a1 1 0 0 1 -1-1zm8 3.485v8.515a5.006 5.006 0 0 1 -5 5h-10a5.006 5.006 0 0 1 -5-5v-14a5.006 5.006 0 0 1 5-5h4.515c.163 0 .324.013.485.024v6.976a3 3 0 0 0 3 3h6.976c.011.161.024.322.024.485zm-8 8.515a1 1 0 0 0 -1-1h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 1-1zm3-4a1 1 0 0 0 -1-1h-8a1 1 0 0 0 0 2h8a1 1 0 0 0 1-1z" />
  </Svg>
);

export const LanguageIcon = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="M8.7,18H3c-1.493,0-3-1.134-3-3.666V9.294A9.418,9.418,0,0,1,8.349.023a9,9,0,0,1,9.628,9.628A9.419,9.419,0,0,1,8.7,18ZM20,9.08h-.012c0,.237,0,.474-.012.712C19.59,15.2,14.647,19.778,9.084,19.981l0,.015A8,8,0,0,0,16,24h5a3,3,0,0,0,3-3V16A8,8,0,0,0,20,9.08Z" />
  </Svg>
);

export const SkillsIcon = ({ width, height }) => (
  <Svg
    style={{ width: width, height: height }}
    id="Layer_1"
    viewBox="0 0 24 24"
    data-name="Layer 1"
    fill="#ffffff"
  >
    <Path d="M12,21.215l3.025,2.415c.688,.549,1.713,.176,1.887-.687l.765-3.795,3.87-.095c.88-.022,1.425-.966,1.004-1.739l-1.853-3.399,2.904-2.56c.66-.582,.471-1.656-.349-1.977l-3.604-1.412,.579-3.827c.132-.87-.704-1.571-1.538-1.291l-3.669,1.235L13.004,.78C12.545,.029,11.455,.029,10.996,.78l-2.017,3.304-3.669-1.235c-.834-.281-1.67,.42-1.538,1.291l.579,3.827-3.604,1.412c-.819,.321-1.009,1.395-.349,1.977l2.904,2.56-1.853,3.399c-.421,.773,.124,1.717,1.004,1.739l3.87,.095,.765,3.795c.174,.863,1.199,1.236,1.887,.687l3.025-2.415Z" />
  </Svg>
);

export const CheckedIcon = ({
  width,
  height,
  stroke_color = "#000000",
  stroke_width = 1,
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: width, height: height }}
    viewBox="0 0 24 24"
    stroke={stroke_color}
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-check-square"
  >
    <Path
      strokeWidth={stroke_width}
      d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
    />
    <Polyline strokeWidth={stroke_width} points="9 11 12 14 22 4" />
  </Svg>
);

export const UncheckedIcon = ({
  width,
  height,
  stroke_color = "#000000",
  stroke_width = 1,
}) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: width, height: height }}
    viewBox="0 0 24 24"
    stroke={stroke_color}
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-x-square"
  >
    <Rect
      strokeWidth={stroke_width}
      x="3"
      y="3"
      width="18"
      height="18"
      rx="2"
      ry="2"
    />
    <Line strokeWidth={stroke_width} x1="9" y1="9" x2="15" y2="15" />
    <Line strokeWidth={stroke_width} x1="15" y1="9" x2="9" y2="15" />
  </Svg>
);
