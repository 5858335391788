import { Font, StyleSheet } from "@react-pdf/renderer";
import NotoArabSource from "./fonts//NotoSansArabic-Bold.ttf";
import RobotoRegularSource from "./fonts/Roboto-Regular.ttf";
import RobotoBoldSource from "./fonts/Roboto-Bold.ttf";
import MontserratSource from "./fonts/Montserrat-Light.ttf";
import MontserratRegularSource from "./fonts/Montserrat-Regular.ttf";
import MontserratSemiBoldSource from "./fonts/Montserrat-SemiBold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoBoldSource, fontWeight: 700 },
    { src: RobotoRegularSource, fontWeight: 400 },
  ],
});
Font.register({
  family: "Noto Sans Arabic",
  src: NotoArabSource,
});
Font.register({
  family: "Montserrat",
  fonts: [
    { src: MontserratSource, fontWeight: 300 },
    { src: MontserratRegularSource, fontWeight: 400 },
    { src: MontserratSemiBoldSource, fontWeight: 600 },
  ],
});

// const PRIMARY_COLOR = "#FCA61A";
const PRIMARY_COLOR = "#b3bbc8";
const SECONDARY_COLOR = "#E5E1DE";

export const styles = {
  page: StyleSheet.create({
    container: {
      flexDirection: "column",
      width: "100%",
      justifyContent: "space-between",
    },
    pageRightColumn: { width: "70%" },
    pageLeftColumn: {
      flexDirection: "column",
      width: "30%",
      marginBottom: 5,
      marginRight: 5,
    },
  }),
  body: StyleSheet.create({
    outer: {
      height: "93.8%",
      flexDirection: "row",
      marginRight: "6.6mm",
    },
    main: {
      flexDirection: "row",
      justifyContent: "space-between",
    },

    dataRow: {
      flexDirection: "row",
      alignItems: "center",
      borderBottom: 1,
      borderColor: "#000000",
    },
    dataLastRow: {
      flexDirection: "row",
      alignItems: "center",
      borderBottom: 2,
      borderColor: "#000000",
      marginBottom: 5,
    },

    // orange strip
    stripContainer: {
      flexDirection: "row",
      alignItems: "flex-end",
    },
    orangeStrip: {
      width: "4.6mm",
      height: "20cm",
      backgroundColor: "#C4C2C7",
      marginLeft: "2mm",
    },

    applicationHeaderContainer: {
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: PRIMARY_COLOR,
      marginTop: "8.6mm",
    },
    headerTextContainer: {
      flex: "1 0",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    headerTextInner: {
      flexDirection: "column",
      alignItems: "center",
      marginRight: 40,
    },
    applicationHeaderText: {
      fontFamily: "Montserrat",
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "center",
      color: "#ffffff",
      marginBottom: "-5",
      paddingVertical: 3,
      paddingHorizontal: 20,
      border: 1,
      borderColor: "#000000",
      backgroundColor: "#650f70",
    },
    categoryText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "center",
      color: "#ff0000",
      marginVertical: 2,
      paddingHorizontal: 10,
      border: 1,
      borderColor: "#000000",
      backgroundColor: "#ffffff",
    },
    logoContainer: {
      // flexDirection: "column",
      // justifyContent: "center",
    },
    headerImage: {
      width: "3cm",
    },
    imageText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 8,
      fontWeight: "bold",
      margin: "auto",
      color: "#1B998B",
    },

    // application main data section
    mainDataContainer: {
      marginTop: "-2.5cm",
      marginBottom: 5,
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
    passportRow: {
      flexDirection: "row",
      alignItems: "center",
    },
    passportColumnOne: {
      width: 100,
      height: "100%",
    },
    passportColumnTwo: {
      width: 80,
      height: "100%",
    },
    passportContainer: {
      flexDirection: "row",
    },
    passportLabelColumn: {
      marginRight: 20,
    },
    passportDetailColumn: {
      marginRight: 20,
    },
    passportText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 10,
      fontWeight: "bold",
      marginBottom: 1,
    },
    passportArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      color: "#000000",
      marginBottom: "-3",
    },
    // Reference Number and Date Section
    refNoBox: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 20,
      fontWeight: "bold",
      marginBottom: 5,
      paddingHorizontal: 5,
      paddingVertical: 2,
      backgroundColor: "#FCA61A",
    },
    dateLabel: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 8,
      fontWeight: "bold",
    },
    dateBox: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 8,
      fontWeight: "bold",
      paddingVertical: 5,
      paddingHorizontal: 5,
      backgroundColor: "#FCA61A",
    },

    // primary application data section
    primaryApplicationDataHeader: {
      flexDirection: "row",
      backgroundColor: PRIMARY_COLOR,
    },
    primaryApplicationDataHeaderColumnOne: { width: "30%" },
    primaryApplicationDataHeaderColumnTwo: { width: "65%" },
    primaryApplicationDataHeaderColumnThree: { width: "25%" },
    primaryApplicationDataHeaderText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 8,
      fontWeight: "bold",
      textAlign: "center",
      color: "#ffffff",
      marginTop: 2,
    },
    primaryApplicationDataHeaderArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#ffffff",
      marginBottom: 2,
    },
    primaryApplicationDataColumnOne: {
      width: "30%",
      height: "100%",
      borderRight: 1,
      borderColor: "#e8ebf2",
    },
    primaryApplicationDataColumnTwo: {
      width: "65%",
      height: "100%",
      borderRight: 1,
      borderColor: "#e8ebf2",
    },
    primaryApplicationDataColumnThree: {
      width: "25%",
      height: "100%",
      // marginRight: 2,
    },
    primaryApplicationDataText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      textAlign: "center",
      marginVertical: 2,
    },

    // primary personal data section
    primaryPersonalColumnOne: {
      width: "30%",
      height: "100%",
      borderRight: 1,
      borderColor: "#e8ebf2",
      backgroundColor: SECONDARY_COLOR,
    },
    primaryPersonalColumnTwo: {
      width: "65%",
      height: "100%",
      borderRight: 1,
      borderColor: "#e8ebf2",
    },
    primaryPersonalArabicColumn: {
      width: "25%",
      fontSize: 6,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      // color: "#6c757d",
      color: "#000000",
      textAlign: "right",
      paddingRight: 2,
      backgroundColor: SECONDARY_COLOR,
    },
    primaryPersonalColumnOneText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginLeft: 2,
      marginVertical: "auto",
      // color: "#6c757d",
      color: "#000000",
    },
    primaryPersonalColumnTwoText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginVertical: "auto",
      textAlign: "center",
      color: "#ff0000",
    },

    // personal information section
    personalHeader: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: PRIMARY_COLOR,
      color: "#000000",
      justifyContent: "center",
      textAlign: "center",
    },
    personalHeaderText: {
      fontSize: 8,
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      flexDirection: "row",
      fontWeight: "bold",
    },
    personalHeaderArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#000000",
      marginHorizontal: 10,
    },
    personalColumnOne: {
      width: "30%",
      height: "100%",
      borderRight: 1,
      // borderColor: "#e8ebf2",
      borderColor: "#000000",
      // color: "#6c757d",
      color: "#000000",
      backgroundColor: SECONDARY_COLOR,
    },
    personalColumnTwo: {
      width: "65%",
      height: "100%",
      borderRight: 1,
      borderColor: "#000000",
      color: "#ff0000",
    },
    personalArabicColumn: {
      width: "25%",
      height: "100%",
      fontSize: 6,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      color: "#000000",
      textAlign: "right",
      paddingRight: 2,
      backgroundColor: SECONDARY_COLOR,
    },
    personalColumnText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginLeft: 2,
      marginVertical: "auto",
    },

    // experience information section
    experienceHeader: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: PRIMARY_COLOR,
      color: "#000000",
      justifyContent: "center",
      textAlign: "center",
    },
    experienceHeaderText: {
      fontSize: 8,
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      flexDirection: "row",
      fontWeight: "bold",
      color: "#000000",
      // borderRight: 1,
      // borderColor: "#000000",
    },
    experienceHeaderArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#000000",
      marginHorizontal: 10,
    },
    experienceApplicationDataHeader: {
      flexDirection: "row",
      backgroundColor: SECONDARY_COLOR,
      borderBottom: 1,
      borderColor: "#000000",
    },
    experienceApplicationDataHeaderColumn: {
      flex: "1 1 1",
    },
    experienceDataHeaderBorder: {
      borderRight: 1,
      borderColor: "#000000",
    },
    experienceApplicationDataHeaderText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 6,
      fontWeight: "bold",
      textAlign: "center",
      color: "#6c757d",
      marginTop: 2,
    },
    experienceApplicationDataHeaderArabic: {
      fontSize: 6,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#6c757d",
      marginBottom: 2,
    },
    experienceColumn: {
      flex: "1 1 1",
      height: "100%",
      borderRight: 1,
      borderColor: "#000000",
    },
    experienceLastColumn: {
      flex: "1 1 1",
      height: "100%",
    },
    experienceColumnText: {
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginVertical: 2,
      textAlign: "center",
      minHeight: 7,
      color: "#ff0000",
    },

    // image data section
    faceViewImageContainer: {
      border: 1,
      borderColor: "#000000",
      flexDirection: "row",
      justifyContent: "center",
      height: "4.8cm",
    },
    faceViewImage: {
      width: "4cm",
      objectFit: "cover",
    },
    fullViewImageContainer: {
      border: 1,
      borderColor: "#000000",
      flexDirection: "row",
      justifyContent: "center",
      height: "10.5cm",
    },
    fullViewImage: {
      width: "5.5cm",
      objectFit: "cover",
    },
    passportImageContainer: {
      paddingHorizontal: "6.6mm",
      paddingVertical: "8.6mm",
    },
    passportImage:{
      marginHorizontal: "auto",
      width: "19cm",
      height: "27cm",
      objectFit: "contain",
    },

    // language data section
    languageHeader: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: PRIMARY_COLOR,
      color: "#000000",
      justifyContent: "center",
      textAlign: "center",
    },
    languageHeaderText: {
      fontSize: 8,
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      flexDirection: "row",
      fontWeight: "bold",
    },
    languageHeaderArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#000000",
      marginHorizontal: 10,
    },
    languageArabic: {
      fontSize: 6,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      marginHorizontal: 5,
    },
    languageColumnOne: {
      width: "55%",
      height: "100%",
      borderRight: 1,
      borderColor: "#e8ebf2",
      color: "#000000",
      backgroundColor: SECONDARY_COLOR,
      flexDirection: "row",
    },
    languageColumnTwo: {
      width: "45%",
      height: "100%",
      borderRight: 1,
      borderColor: "#e8ebf2",
      color: "#ff0000",
      textAlign: "center",
    },
    languageColumnText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginLeft: 2,
      marginVertical: 2,
    },

    // skill data section
    skillHeader: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: PRIMARY_COLOR,
      color: "#000000",
      justifyContent: "center",
      textAlign: "center",
    },
    skillHeaderText: {
      fontSize: 8,
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      flexDirection: "row",
      fontWeight: "bold",
    },
    skillHeaderArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#000000",
      marginHorizontal: 10,
    },
    skillContainer: {
      width: "24.7%",
      backgroundColor: "#F8F8F8",
      opacity: 0.9,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 2,
      marginRight: 2,
      marginBottom: 2,
      paddingVertical: 5,
      border: 1,
      borderColor: "#e8ebf2",
    },
    skillText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginLeft: 2,
      marginBottom: 1,
      // color: "#6c757d",
      color: "#000000",
    },
    skillArabic: {
      fontSize: 6,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#ffffff",
      marginHorizontal: 10,
    },

    // remarks section
    remarkHeader: {
      flexDirection: "row",
      alignItems: "center",
      color: "#000000",
      marginTop: 5,
    },
    remarkHeaderLine: {
      width: "100%",
      height: 1,
      backgroundColor: "#000000",
    },
    remarkHeaderText: {
      fontSize: 8,
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      flexDirection: "row",
      fontWeight: "bold",
    },
    remarkHeaderArabic: {
      fontSize: 8,
      fontWeight: "bold",
      fontFamily: "Noto Sans Arabic",
      textAlign: "center",
      color: "#000000",
      marginHorizontal: 10,
    },
    remarksText: {
      textTransform: "uppercase",
      fontFamily: "Montserrat",
      fontSize: 7,
      fontWeight: "bold",
      marginVertical: 2,
      minHeight: 7,
    },

    // footer
    footerContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
  }),
  utility: StyleSheet.create({
    redCell: {
      color: "#ff0000",
    },
  }),
};
