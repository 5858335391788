import React, { useEffect, useState } from "react";
import { Clock } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import DetailRowLayout from "../../../common/DetailRowLayout";
import { useSelector } from "react-redux";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import { EDIT_CUSTOMER_FUNC, LOADED, LOADING } from "../../../../constant";

const Skill = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {  
    let navigate = useNavigate();
    const { customer_id } = useParams();
  
    // stored data
    const loggedUser = useSelector((content) => content.UserReducer);
  
    // form controls
    const [isLoaded, setIsLoaded] = useState(LOADING);
    const [customerData, setCustomerData] = useState(undefined);
  
    // custom hooks
    const [checkIsAccessible] = useAccess(loggedUser, "Customer");
    const sendRequest = useAxiosTemplates();
  
    useEffect(() => {
      const reqBody = {
        clientId: client_id,
        orgId: org_id,
        userId: loggedUser.userId,
        bpartnerId: b_partner_id,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/viewProfileSkillTab",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        setCustomerData(response_data);
        setIsLoaded(LOADED);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>{isLoaded === LOADING ? <Skeleton className="w-25" /> : null}</div>
      <div>
        {isLoaded === LOADED ? (
          customerData.skillList.length > 0 ? (
            customerData.skillList.map((skill_item, index) => (
              <div className="mb-4" key={index}>
                <div
                  className="d-flex align-items-center mb-2"
                  style={{ minWidth: "max-content" }}
                >
                  <Clock height="12px" className="text-secondary"></Clock>
                  <div className="text-secondary">
                    {skill_item.updated === skill_item.created
                      ? "Created "
                      : "Last Modified "}
                    on {ConvertMillisToDate(skill_item.updated)} By{" "}
                    {skill_item.updatedBy}{" "}
                  </div>
                </div>
                <div key={index} className="rounded mb-2">
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Skill", "Is Skilled", "Show In Application"]}
                    data={
                      isLoaded === LOADED
                        ? [
                            skill_item.name,
                            `${skill_item.isCan ? "Yes" : "No"}`,
                            `${skill_item.isShowApplication ? "Yes" : "No"}`,
                          ]
                        : null
                    }
                    active={skill_item.isCan}
                  />
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Remark"]}
                    data={isLoaded === LOADED ? [skill_item.remark] : null}
                    active={skill_item.isCan}
                  />
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Skill", "Is Skilled", "Show In Application"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Remark"]}
              data={[]}
            />
          </>
        )}
        <div className="d-flex justify-content-end mt-4 mx-1">
          {checkIsAccessible(EDIT_CUSTOMER_FUNC) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_4/`
                )
              }
              className="btn btn-primary col-12 col-md-2"
            >
              {customerData.skillList.length > 0 ? "Update" : "Insert"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Skill