import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle } from "react-feather";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
// import { singlishToUnicode } from "sinhala-unicode-coverter";
import { LOADED, LOADING } from "../../../../constant";
import { sinhalaConverter } from "../../../../data/util/SinhalaConverter";

const DetailInSinhala = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  // interface status data
  const [refreshPage, setRefreshPage] = useState(true);
  const [btnText, setBtnText] = useState("Submit");

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const addressFieldKeyValues = useRef("");
  const nameFieldKeyValues = useRef("");

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let sinhalaDetails = {
        bpartnerId: parseInt(b_partner_id),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        name: formData.get("fm_9_name_in_sinhala"),
        address: formData.get("fm_9_address"),
        remark: formData.get("fm_9_remark"),
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveSinhalaTab",
          data: sinhalaDetails,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text:
              btnText === "Submit"
                ? "Sinhala Detail Record has successfully updated."
                : "New Sinhala Detail Record has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setRefreshPage(true);
          setIsLoaded(LOADING);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const convertAddressToSinhala = (evt) => {
    if (evt.key.length === 1) {
      addressFieldKeyValues.current = addressFieldKeyValues.current.concat(
        evt.key
      );
      evt.target.value = sinhalaConverter(addressFieldKeyValues.current);
    }
    if (evt.key === "Backspace") {
      let newString = addressFieldKeyValues.current.split("");
      newString.pop();
      addressFieldKeyValues.current = newString.join("");
      evt.target.value = sinhalaConverter(addressFieldKeyValues.current);
    }
  };

  const convertNameToSinhala = (evt) => {
    if (evt.key.length === 1) {
      nameFieldKeyValues.current = nameFieldKeyValues.current.concat(evt.key);
      evt.target.value = sinhalaConverter(nameFieldKeyValues.current);
    }
    if (evt.key === "Backspace") {
      let newString = nameFieldKeyValues.current.split("");
      newString.pop();
      nameFieldKeyValues.current = newString.join("");
      evt.target.value = sinhalaConverter(nameFieldKeyValues.current);
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshPage) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewSinhalaTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            document.querySelector("input[name='fm_9_name_in_sinhala']").value =
              response_data.name;
            document.querySelector("input[name='fm_9_address']").value =
              response_data.address;
            document.querySelector("textarea[name='fm_9_remark']").value =
              response_data.remark;
            if (
              response_data.name !== "" ||
              response_data.address !== "" ||
              response_data.remark !== ""
            ) {
              setBtnText("Update");
            }
            setRefreshPage(false);
            setIsLoaded(LOADED);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshPage]);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <Alert
        className="alert-dismissible d-flex align-items-center"
        color="warning"
        isOpen={b_partner_id === 0}
      >
        <AlertTriangle />
        <p className="ml-2">
          You must create a Customer, before add Sinhala Details.
        </p>
      </Alert>
      <form
        onSubmit={(evt) => submitForm(evt)}
        onReset={(evt) => resetForm(evt)}
        className={`position-relative form-9 ${
          isValidated ? "was-validated" : ""
        }`}
        noValidate
      >
        {isLoaded === LOADING ? (
          <div className="form-loader d-flex justify-content-center align">
            <div className="loader-box">
              <div className="loader">
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
                <div className="line bg-secondary"></div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="form-group position-relative mb-4">
          <label htmlFor="id_input_fm_9_name_in_sinhala required-input-mark">Name In Sinhala</label>
          <input
            id="id_input_fm_9_name_in_sinhala"
            name="fm_9_name_in_sinhala"
            type="text"
            maxLength={500}
            className="form-control"
            placeholder="Enter Name in Sinhala"
            autoComplete="off"
            onKeyUp={(evt) => convertNameToSinhala(evt)}
            required
          />
          <div className="invalid-feedback position-absolute">
            Please enter Name In Sinhala.
          </div>
          <small className="txt-danger position-absolute d-none input-validation"></small>
        </div>
        <div className="form-row mb-4">
          <div className="form-group col-12">
            <label htmlFor="id_input_fm_9_address">Address</label>
            <input
              id="id_input_fm_9_address"
              name="fm_9_address"
              type="text"
              maxLength={250}
              className="form-control"
              placeholder="Enter Address"
              autoComplete="off"
              onKeyUp={(evt) => convertAddressToSinhala(evt)}
            />
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
        </div>
        <div className="form-group mb-4">
          <label htmlFor="id_input_fm_9_remark">Remark</label>
          <textarea
            id="id_input_fm_9_remark"
            name="fm_9_remark"
            rows="3"
            maxLength={250}
            className="form-control"
            placeholder="Enter Remark"
            onChange={(evt) =>
              formValidation({
                event: evt,
                checkExpression: "",
              })
            }
          ></textarea>
          <small className="txt-danger position-absolute d-none input-validation"></small>
        </div>
        {b_partner_id ? (
          (checkIsAccessible(INSERTFUNCTION) && btnText === "Submit") ||
          (checkIsAccessible(EDITFUNCTION) && btnText === "Update") ? (
            <div className="d-sm-flex justify-content-end">
              <button
                className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                type="submit"
              >
                {btnText}
              </button>
              <button className="btn btn-warning col-12 col-sm-2" type="reset">
                Reset
              </button>
            </div>
          ) : null
        ) : null}
      </form>
    </div>
  );
};

export default DetailInSinhala;
