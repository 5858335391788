import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import { useSelector } from "react-redux";
import { Clock } from "react-feather";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import DetailRowLayout from "../../../common/DetailRowLayout";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";

const Passport = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfilePassportTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>{isLoaded === LOADING ? <Skeleton className="w-25" /> : null}</div>
      <div>
        {isLoaded === LOADED ? (
          customerData.passportList.length > 0 ? (
            customerData.passportList.map((passport_item, index) => (
              <div className="mb-4" key={index}>
                <div
                  className="d-flex align-items-center mb-2"
                  style={{ minWidth: "max-content" }}
                >
                  <Clock height="12px" className="text-secondary"></Clock>
                  <div className="text-secondary">
                    {passport_item.updated === passport_item.created
                      ? "Created "
                      : "Last Modified "}
                    on {ConvertMillisToDate(passport_item.updated)} By{" "}
                    {passport_item.updatedBy}{" "}
                  </div>
                </div>
                <div key={index}>
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={[
                      "Passport Number",
                      "Passport Type",
                      "Issue Date",
                      "Expire Date",
                    ]}
                    data={
                      isLoaded === LOADED
                        ? [
                            passport_item.passportNo,
                            passport_item.passportTypeName,
                            ConvertMillisToDate(passport_item.issueDate),
                            ConvertMillisToDate(passport_item.expireDate),
                          ]
                        : null
                    }
                    active={passport_item.isActive}
                  />
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Collected"]}
                    data={
                      isLoaded === LOADED
                        ? [`${passport_item.isCollected ? "Yes" : "No"}`]
                        : null
                    }
                    active={passport_item.isActive}
                  />
                  <DetailRowLayout
                    display={isLoaded === LOADED}
                    labels={["Remark"]}
                    data={isLoaded === LOADED ? [passport_item.remark] : null}
                    active={passport_item.isActive}
                  />
                  {passport_item.attachmentList?.length > 0 ? (
                    <DetailRowLayout
                      display={isLoaded === LOADED}
                      attachment_row={true}
                      data={passport_item.attachmentList}
                      active={passport_item.isActive}
                      lastRow={true}
                    />
                  ) : null}
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          // Loading Skeleton
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={[
                "Passport Number",
                "Passport Type",
                "Issue Date",
                "Expire Date",
              ]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Collected"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Any Comment"]}
              data={[]}
            />
          </>
        )}
        <div className="d-flex justify-content-end mt-4 mx-1">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_6/`
                )
              }
              className="btn btn-primary col-12 col-md-2"
            >
              {customerData.passportList.length > 0 ? "Update" : "Insert"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Passport;
