import React, { Fragment } from "react";

import { User, Mail, Lock, Settings, LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import ConfigDB from "../../../data/customizer/config";

const UserMenu = () => {
  let navigate = useNavigate();
  const loggedUser = useSelector((content) => content.UserReducer);
  // custom hooks
  const sendRequest = useAxiosTemplates();

  const signOut = () => {
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
    };
    let responseData = new Promise((resolve) => {
      const result = sendRequest({
        url: "/logOut",
        data: reqBody,
        template: "CONTROL_DATA",
      });
      resolve(result);
    });
    responseData.then((response_data) => {
      localStorage.removeItem("token");
      localStorage.removeItem("store");
      localStorage.removeItem("lastKeyboardStrokeAt");
      localStorage.removeItem("lastMouseMoveAt");
      navigate("/login");
    });
  };

  return (
    <Fragment>
      <li className="onhover-dropdown">
        <div className="media align-items-center">
          <img
            className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded"
            /** Hosted Image Url */
            // src={`${process.env.PUBLIC_URL}/DeshaktheeERP${default_avatar}`}
            src={`${ConfigDB.data.fileServerUrl}${loggedUser.profilePictureDocumentPath}`}
            alt="header-user"
          />
        </div>
        <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
          <li>
            <Link to={`/user`}>
              <User />
              Edit Profile
            </Link>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ cursor: "pointer" }}>
              <Mail />
              Inbox
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ cursor: "pointer" }}>
              <Lock />
              Lock Screen
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ cursor: "pointer" }}>
              <Settings />
              Settings
            </a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a style={{ cursor: "pointer" }} onClick={signOut}>
              <LogOut /> Log out
            </a>
          </li>
        </ul>
      </li>
    </Fragment>
  );
};

export default UserMenu;
