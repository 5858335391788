import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../common/breadcrumb";
import deshaktheeLogo from "../../../assets/images/deshakthee.png";
import {
  AlertTriangle,
  Calendar,
  PhoneOutgoing,
  Play,
  RefreshCcw,
} from "react-feather";
import CircleIcon from "@mui/icons-material/Circle";
import {
  FormattedCurrentDate,
  FormattedCurrentTime,
  ShowTimeAgo,
} from "../../../data/util/DateUtil";
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import ConfigDB from "../../../data/customizer/config";
import { LOADED, LOADING } from "../../../constant";
import Swal from "sweetalert2";

const Default = () => {
  const dispatch = useDispatch();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  const today = new Date();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [syncDate, setSyncDate] = useState(FormattedCurrentDate());
  const [visitingCustomerList, setVisitingCustomerList] = useState([]);
  const [visitingCustomerData, setVisitingCustomerData] = useState(LOADING);
  const [lastSyncedTime, setLastSyncedTime] = useState(FormattedCurrentTime());

  const [showNumbersFor, setShowNumbersFor] = useState(0);
  const [phoneNumberToBeDialed, setPhoneNumberToBeDialed] = useState("");

  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    setVisitingCustomerData(LOADING);
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      visitDate: syncDate,
    };
    let responseData = new Promise((resolve) => {
      const result = sendRequest({
        url: "/dashboard/viewToBeCustomerVisitDetail",
        data: reqBody,
      });
      resolve(result);
    });
    responseData.then((response_data) => {
      setVisitingCustomerList(response_data.customerList);
      setVisitingCustomerData(LOADED);
    });
    let syncTimer = setInterval(() => {
      setVisitingCustomerData(LOADING);
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        visitDate: syncDate,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/dashboard/viewToBeCustomerVisitDetail",
          data: reqBody,
          check_token: true,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        setVisitingCustomerList(response_data.customerList);
        setLastSyncedTime(FormattedCurrentTime());
        setVisitingCustomerData(LOADED);
      });
    }, ConfigDB.data.visiting_customer_check_timer_interval);
    if (syncDate !== FormattedCurrentDate()) {
      clearInterval(syncTimer);
    } else {
      setLastSyncedTime(FormattedCurrentTime());
    }

    return () => {
      clearInterval(syncTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncDate]);

  const dialNumber = (bpartner_id, phone_number = "") => {
    let customerToBeDialed = visitingCustomerList.find(
      (customer_item) => customer_item.bpartnerId === bpartner_id
    );
    if (phone_number === "" && phoneNumberToBeDialed === "") {
      /** If there is no selected number to be dialed */
      if (showNumbersFor === bpartner_id) {
        setShowNumbersFor(0);
        setPhoneNumberToBeDialed("");
      } else {
        setShowNumbersFor(bpartner_id);
      }
    } else {
      let phoneNumber = phone_number;
      /** If there is a selected number to be dialed */
      if (phone_number === "") {
        if (
          customerToBeDialed.activeContactNoList.includes(phoneNumberToBeDialed)
        ) {
          phoneNumber = phoneNumberToBeDialed;
        }
      }
      Swal.fire({
        icon: "info",
        text: `You are going to call ${customerToBeDialed.customerName}`,
        showDenyButton: true,
        confirmButtonText: "Call",
        denyButtonText: "Cancel",
        denyButtonColor: "#ff9f40",
      }).then((result) => {
        if (result.isConfirmed) {
          const reqBody = {
            clientId: loggedUser.clientId,
            orgId: loggedUser.orgId,
            userId: loggedUser.userId,
            bpartnerId: bpartner_id,
            customerContactNumber: phoneNumber,
            interfaceSearchKey: "Dashboard",
          };
          let responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/call/saveDialingCall",
              data: reqBody,
              template: "CONTROL_DATA",
            });
            resolve(result);
          });
          responseData.then((response_data) => {
            if (response_data) {
              Swal.fire({
                icon: "success",
                title: "Request Successful",
                text: `You will be connected with ${customerToBeDialed.customerName} shortly.`,
                showConfirmButton: false,
                timer: 5000,
              });
            }
          });
        }
      });
      setShowNumbersFor(0);
      setPhoneNumberToBeDialed("");
    }
  };

  const directToCustomerProfile = (bpartner_id) => {
    let customerToBeDialed = visitingCustomerList.find(
      (customer_item) => customer_item.bpartnerId === bpartner_id
    );
    dispatch({
      type: "STORE_CUSTOMER",
      payload: {
        bpartnerId: customerToBeDialed.bpartnerId,
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        searchKey: customerToBeDialed.customerId,
      },
    });
    window.open(
      `${ConfigDB.data.hosted_url}/customer/view/${customerToBeDialed.customerId}/`,
      "_blank"
    );
  };

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Dashboard" />
      <div className="container-fluid">
        <div className="row">
          <div className="d-block d-lg-none col-lg-4">
            <div className="card">
              <div className="card-header">
                <div className="py-2 text-center">
                  <img
                    width={"150px"}
                    className=""
                    src={`${deshaktheeLogo}`}
                    alt="#"
                  />
                  <div className="px-3">
                    <h3 className="mb-2 fw-bold">WELCOME</h3>
                    <div style={{ fontSize: "16px" }}>
                      Deshakthee Lanaka Agencies (PVT) LTD
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body py-3 d-flex align-items-center">
                <Calendar className="text-secondary" />
                <div className="ml-2">
                  {days[today.getDay()]}, {months[today.getMonth()]}{" "}
                  {today.getDate()}, {today.getFullYear()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-3">
                  <div className="fw-bold">Customers To Be Visited</div>
                  <div
                    className="d-flex align-items-center"
                    style={{ minWidth: "max-content" }}
                  >
                    <RefreshCcw
                      height="12px"
                      className={`text-secondary ${
                        visitingCustomerData === LOADING ? "rotate-icon" : null
                      }`}
                    ></RefreshCcw>
                    <small className="text-secondary">
                      Last Synced Today at {lastSyncedTime}
                    </small>
                  </div>
                </div>
                <Alert
                  className="alert-dismissible d-flex align-items-center bg-white rounded"
                  color="warning"
                  isOpen={visitingCustomerList?.length === 0}
                >
                  <AlertTriangle className="txt-warning" />
                  <p className="ml-2 txt-warning fw-bold">
                    No Customers to be Visited.
                  </p>
                </Alert>
                <div>
                  {visitingCustomerList?.map((customer, index) => (
                    <div key={index} className="d-sm-flex border-bottom mb-3">
                      <div className="w-100">
                        <div className="d-flex">
                          <div
                            className="text-secondary fw-bold pointer"
                            onClick={() =>
                              directToCustomerProfile(customer.bpartnerId)
                            }
                          >
                            {customer.customerId}
                          </div>
                          <div
                            className="mx-3 pointer text-uppercase"
                            onClick={() =>
                              directToCustomerProfile(customer.bpartnerId)
                            }
                          >
                            {customer.customerName}
                          </div>
                        </div>
                        <div className="my-1 text-lowercase">
                          {customer.cc2DispositionRemark === ""
                            ? customer.cc1DispositionRemark
                            : customer.cc2DispositionRemark}
                          {customer.cc2DispositionRemark === "" &&
                          customer.cc1DispositionRemark === ""
                            ? "Remark not detected"
                            : null}
                          {customer.cc2DispositionRemark === null &&
                          customer.cc1DispositionRemark === null
                            ? "Remark not detected"
                            : null}
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                          <div className="d-flex align-items-center">
                            <CircleIcon
                              className="txt-primary"
                              sx={{ fontSize: 10 }}
                            />
                            <small className="ml-2 fw-bold text-uppercase txt-warning">
                              {customer.cc2DispositionName === ""
                                ? customer.cc1DispositionName
                                : customer.cc2DispositionName}
                              {customer.cc1DispositionName === "" &&
                              customer.cc2DispositionName === ""
                                ? "Disposition Not Detected"
                                : ""}
                            </small>
                          </div>
                          <small className="text-secondary mx-sm-3">
                            {ShowTimeAgo(customer.updated)} ago
                          </small>
                        </div>
                      </div>
                      {customer.activeContactNoList.length > 1 ? (
                        <div className="d-sm-flex align-items-sm-center">
                          <div
                            className={`d-none d-sm-block ${
                              showNumbersFor === customer.bpartnerId
                                ? "position-relative"
                                : null
                            }`}
                          >
                            <div
                              style={{ right: 0, zIndex: 1 }}
                              className={`${
                                showNumbersFor === customer.bpartnerId
                                  ? "position-absolute"
                                  : ""
                              } d-flex align-items-center justify-content-end`}
                              onClick={() => dialNumber(customer.bpartnerId)}
                            >
                              <button
                                type="button"
                                className="btn rounded bg-primary p-3 d-flex align-items-center"
                              >
                                <PhoneOutgoing
                                  height="16px"
                                  className="txt-light"
                                />
                              </button>
                            </div>
                            <div
                              className={`${
                                showNumbersFor === customer.bpartnerId
                                  ? ""
                                  : "d-none"
                              } position-absolute bg-light rounded`}
                              style={{
                                width:
                                  showNumbersFor === customer.bpartnerId
                                    ? "300px"
                                    : "0px",
                                right: "-15px",
                                top: "-15px",
                              }}
                            >
                              <div className="p-3">
                                {/* <XCircle height="16px" className="text-secondary" /> */}
                                <div className="fw-bold mb-2 txt-dark">
                                  Select a Number To Dial
                                </div>
                                {customer.activeContactNoList.map(
                                  (number_item) => (
                                    <div className="d-flex border-start border-warning">
                                      <Play
                                        height="16px"
                                        className={`${
                                          phoneNumberToBeDialed === number_item
                                            ? "txt-secondary"
                                            : "invisible"
                                        }`}
                                      />
                                      <div
                                        className="txt-dark pointer"
                                        onClick={() =>
                                          setPhoneNumberToBeDialed(number_item)
                                        }
                                      >
                                        {number_item.slice(0, 3)}
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        {number_item.slice(
                                          number_item.length - 3,
                                          number_item.length
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="d-block d-sm-none w-100 my-3"
                          >
                            <div
                              className="w-100"
                              onClick={() => dialNumber(customer.bpartnerId)}
                            >
                              <button
                                type="button"
                                className="btn w-100 rounded bg-primary d-flex justify-content-center"
                              >
                                <PhoneOutgoing
                                  height="16px"
                                  className="txt-light"
                                />
                              </button>
                            </div>
                            <div
                              className={`${
                                showNumbersFor === customer.bpartnerId
                                  ? ""
                                  : "d-none"
                              } bg-light rounded mt-1`}
                            >
                              <div className="p-3">
                                {/* <XCircle height="16px" className="text-secondary" /> */}
                                <div className="fw-bold mb-2 txt-dark">
                                  Select a Number To Dial
                                </div>
                                {customer.activeContactNoList.map(
                                  (number_item) => (
                                    <div className="d-flex border-start border-warning">
                                      <Play
                                        height="16px"
                                        className={`${
                                          phoneNumberToBeDialed === number_item
                                            ? "txt-secondary"
                                            : "invisible"
                                        }`}
                                      />
                                      <div
                                        className="txt-dark pointer"
                                        onClick={() =>
                                          setPhoneNumberToBeDialed(number_item)
                                        }
                                      >
                                        {number_item.slice(0, 3)}
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        <CircleIcon
                                          className="text-secondary"
                                          sx={{ fontSize: 10 }}
                                        />
                                        {number_item.slice(
                                          number_item.length - 3,
                                          number_item.length
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="my-3 my-sm-0 d-flex align-items-center justify-content-sm-end"
                          onClick={() =>
                            dialNumber(
                              customer.bpartnerId,
                              customer.activeContactNoList[0]
                            )
                          }
                        >
                          <button
                            type="button"
                            className="d-none d-sm-flex btn rounded bg-primary p-3 d-flex align-items-center"
                          >
                            <PhoneOutgoing
                              height="16px"
                              className="txt-light"
                            />
                          </button>
                          <button
                            type="button"
                            className="d-flex d-sm-none btn rounded bg-primary w-100 d-flex justify-content-center"
                          >
                            <PhoneOutgoing
                              height="16px"
                              className="txt-light"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="d-flex justify-content-end">
                  <div className="d-flex align-items-center bg-primary-0.2 rounded">
                    <small className="txt-primary fw-bold mx-3">
                      Synced On
                    </small>
                    <small className="txt-primary">{syncDate}</small>
                    <button
                      type="button"
                      className="btn position-relative bg-primary-0.2 d-flex align-items-center py-1 px-2 ml-2 rounded"
                    >
                      <Calendar height="14px" className="txt-primary" />
                      <input
                        onChange={(evt) => setSyncDate(evt.target.value)}
                        type="date"
                        className="position-absolute opacity-0"
                        style={{ right: "8px" }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-4">
            <div className="card">
              <div className="card-header">
                <div className="py-2 text-center">
                  <img
                    width={"150px"}
                    className=""
                    src={`${deshaktheeLogo}`}
                    alt="#"
                  />
                  <div className="px-3">
                    <h3 className="mb-2 fw-bold">WELCOME</h3>
                    <div style={{ fontSize: "16px" }}>
                      Deshakthee Lanaka Agencies (PVT) LTD
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body py-3 d-flex align-items-center">
                <Calendar className="text-secondary" />
                <div className="ml-2">
                  {days[today.getDay()]}, {months[today.getMonth()]}{" "}
                  {today.getDate()}, {today.getFullYear()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Default;
