import {
  AUTHENTICATE_USER,
  CHANGE_ORG,
  SESSION_EXPIRED,
  SIGNOUT_USER,
  STORE_ROLE_LIST,
} from "../../redux/actionTypes";

const initial_state = {
  loggedInTime: "",
  clientId: 0,
  orgId: 0,
  userId: 0,
  username: "",
  bpartnerId: 0,
  employeeName: "",
  roleId: 0,
  roleName: "",
  profilePictureDocumentPath: "", 
  activeClientList: [],
  activeOrgRoleList: [],
  roleList: [],
  sessionExpired: false,
};

const userReducer = (state = initial_state, action) => {
  switch (action.type) {
    case AUTHENTICATE_USER:
      return {
        ...state,
        loggedInTime: action.payload.loggedInTime,
        clientId: action.payload.clientId,
        orgId: action.payload.orgId,
        userId: action.payload.userId,
        username: action.payload.username,
        bpartnerId: action.payload.bpartnerId,
        employeeName: action.payload.employeeName,
        userRoleId: action.payload.userRoleId,
        roleName: action.payload.roleName,
        activeClientList: action.payload.activeClientList,
        activeOrgRoleList: action.payload.activeOrgRoleList,
        profilePictureDocumentPath: action.payload.profilePictureDocumentPath, 
        sessionExpired: false,
      };

    case CHANGE_ORG:
      return {
        ...state,
        orgId: action.payload.orgId,
        roleList: action.payload.roleList,
      };

    case SIGNOUT_USER:
      return {
        clientId: 0,
        orgId: 0,
        userId: 0,
        username: "",
        bpartnerId: 0,
        employeeName: "",
        userRoleId: 0,
        roleName: "",
        profilePictureDocumentPath: "", 
        activeClientList: [],
        activeOrgRoleList: [],
        roleList: [],
        sessionExpired: true,
      };

    case STORE_ROLE_LIST:
      return { ...state, roleList: action.payload.roleList };

    case SESSION_EXPIRED:
      return { ...state, sessionExpired: true };

    default:
      return { ...state };
  }
};

export default userReducer;
