// hooks
import React, { useEffect, useRef, useState } from "react";
import { PDFViewer, usePDF } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// custom hooks
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import formValidation from "../../../customHooks/useValidation";

// utils
import {
  CalculateYearDifference,
  ConvertMillisToDate,
} from "../../../data/util/DateUtil";

// third-party components
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// components
// import TypeTwo from "./templates/TypeTwo";
import Breadcrumb from "../../common/breadcrumb";
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../common/datatable";

// icons
import { AlertCircle, RefreshCw } from "react-feather";

// constants
import {
  CURRENCY_LIST,
  LOADED,
  LOADING,
  NEW_SKILLS_HIRE,
  WORKNET_GLOBAL,
} from "../../../constant";
import { cachelessURL } from "../../../data/util/PreventCache";

// layout templates
// import TypeCommon from "./templates/TypeCommon";
import TypeFive from "./templates/TypeFive";
import TypeWorknetGlobal from "./templates/TypeWorknetGlobal";
import TypeNewSkillsHire from "./templates/TypeNewSkillsHire";
// import TypeFour from "./templates/TypeFour";

const Index = () => {
  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);
  const customerData = useSelector((content) => content.CustomerReducer);
  const { customer_id } = useParams();

  // breadcrumb values
  let childLinks = [
    { value: customer_id, active: false },
    { value: "Application", active: true },
  ];

  // interface status controllers
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [isDataLoaded, setIsDataLoaded] = useState(LOADING);
  const [refreshTable, setRefreshTable] = useState(true);

  // interface data
  const [undetectedPropertyList, setUndetectedPropertyList] = useState([]);
  const [allDetails, setAllDetails] = useState();
  const [applicationId, setApplicationId] = useState(0);
  const selectedPassport = useRef(null);
  const selectedJob = useRef(null);
  const [cvData, setCVData] = useState({
    letterHead: "",
    referenceNumber: "",
    fullName: "",
    country: "",
    preferredName: "",
    nationality: "",
    dateOfBirth: "",
    city: "",
    age: "",
    gender: "",
    placeOfBirth: "",
    civilStatus: "",
    religion: "",
    NoOfChildren: "",
    yChildAge: "",
    weight: "",
    height: "",
    complexion: "",
    education: {
      ol: "",
      al: "",
    },
    appliedJob: {
      appliedPost: "",
      salary: "",
      contactPeriod: "",
    },
    passport: {
      number: "",
      dateOfIssue: "",
      dateOfExpire: "",
      placeOfIssue: "",
    },
    workExperience: {},
    remarks: [],
    faceImage: "",
    fullImage: "",
    skills: [],
    languages: [],
  });

  // form values
  const [countryList, setCountryList] = useState([]);
  const [agentList, setAgentList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [jobList, setJobList] = useState([]);
  const [dpCountryValue, setDpCountryValue] = useState(0);
  const [dpAgentValue, setDpAgentValue] = useState(0);
  const [dpCurrencyValue, setDpCurrencyValue] = useState(0);
  const [dpJobValue, setDpJobValue] = useState(0);

  // custom hooks
  const sendRequest = useAxiosTemplates();

  // other hooks
  const [instance] = usePDF({
    document: <TypeFive cv_data={cvData} />,
  });

  // table data
  const [applicationRowList, setApplicationRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Reference No",
    "Name",
    "Country",
    "Agent",
    "Created On",
    "Created By",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display: true,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];

  // table functions
  const [viewApplication] = useState(() => (list_item) => {
    if (list_item) {
      let contractPeriodMonths = parseInt(list_item.contractPeriodMonth) % 12;
      let contractPeriodYears = Math.floor(
        parseInt(list_item.contractPeriodMonth) / 12
      );
      let currency = list_item.currencyName;
      let salaryAmount = list_item.salary;

      setCVData((prev_data) => ({
        ...prev_data,
        referenceNumber: list_item.referenceNo,
        country: list_item.countryName,
        appliedJob: {
          appliedPost: list_item.jobCategoryLineName,
          salary: salaryAmount > 0 ? `${salaryAmount} ${currency}` : "",
          contactPeriod:
            list_item.contractPeriodMonth > 0
              ? `${
                  contractPeriodYears > 0 ? contractPeriodYears + " YRS" : ""
                } ${
                  contractPeriodMonths > 0 ? contractPeriodMonths + " MM" : ""
                }`
              : "",
        },
      }));
      setApplicationId(list_item.customerApplicationId);
      setDpCountryValue(list_item.countryId);
      setDpAgentValue(list_item.agentBpartnerId);
      setDpCurrencyValue(list_item.currencyId);
      setDpJobValue(list_item.jobCategoryLineId);
      document.querySelector("textarea[name='fm_1_remark']").value =
        list_item.remark;
      document.querySelector("input[name='fm_1_contract_period_years']").value =
        contractPeriodYears;
      document.querySelector(
        "input[name='fm_1_contract_period_months']"
      ).value = contractPeriodMonths;
      document.querySelector("input[name='fm_1_salary_amount']").value =
        salaryAmount;
      document.querySelector("input[name='fm_1_is_active']").checked =
        list_item.isActive;
      generateCVData();
      setIsLoaded(LOADED);
    }
  });

  useEffect(() => {
    /**
     * getting all the details related to customer
     * which have to be displayed in pdf
     */
    const reqBody = {
      clientId: loggedUser.clientId,
      orgId: loggedUser.orgId,
      userId: loggedUser.userId,
      bpartnerId: customerData.bpartnerId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileAllDetail",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setAllDetails(response_data);
      setIsDataLoaded(LOADED);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * getting all the details related to currency
     * which have to be displayed in pdf
     */
    const reqBody = {
      clientId: loggedUser.clientId,
      countryId: parseInt(dpCountryValue),
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/getAllCurrencyAllocateToCountry",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        setCurrencyList((prev_currency_list) => [
          { currencyId: "", currencySearchKey: "-Select-" },
          ...response_data.allCurrencyList,
        ]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reqBody = {
      agentBpartnerId: dpAgentValue ? parseInt(dpAgentValue) : 32,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "agent/getLetterHead",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        setCVData((prev_data) => ({
          ...prev_data,
          letterHead: response_data.agentLetterHeadPath,
        }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dpAgentValue]);

  useEffect(() => {
    if (refreshTable) {
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: customerData.bpartnerId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/viewApplicationTab",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          if (response_data?.activeCountryList.length > 0) {
            setCountryList((prevCountryList) => [
              { countryId: "", name: "-Select-" },
              ...response_data.activeCountryList,
            ]);
          }
          if (response_data?.activeAgentList.length > 0) {
            setAgentList((prevAgentList) => [
              { bpartnerId: "", name: "-Select-" },
              ...response_data.activeAgentList,
            ]);
          }
          if (response_data?.activeCustomerJobCategoryLineList.length > 0) {
            setJobList((prev_job_list) => [
              { jobCategoryLineId: "", name: "-Select-" },
              ...response_data.activeCustomerJobCategoryLineList,
            ]);
          }
          if (response_data.allCustomerApplicationTableList.length > 0) {
            let tempList = [];
            response_data.allCustomerApplicationTableList?.forEach(
              (list_item) => {
                tempList.push([
                  list_item.customerApplicationId,
                  list_item.referenceNo,
                  list_item.customerBpartnerName,
                  list_item.countryName,
                  list_item.agentBpartnerName,
                  ConvertMillisToDate(list_item.created),
                  list_item.updatedBy,
                  list_item.isActive ? "True" : "False",
                  <ControlButtons
                    showView={true}
                    disableDelete={true}
                    disableEdit={true}
                    viewMethod={() => viewApplication(list_item)}
                  />,
                ]);
              }
            );
            setApplicationRowList([...tempList]);
          }
        }
        setRefreshTable(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (isDataLoaded) {
      if (allDetails.passportList.length === 1) {
        let passportAttachmentList =
          allDetails.passportList[0].attachmentList.length > 0
            ? allDetails.passportList[0].attachmentList.map((item) => ({
                filePath: item.filePath + cachelessURL(),
              }))
            : [];
        selectedPassport.current = {
          number: allDetails.passportList[0].passportNo,
          dateOfIssue: ConvertMillisToDate(
            allDetails.passportList[0].issueDate
          ),
          dateOfExpire: ConvertMillisToDate(
            allDetails.passportList[0].expireDate
          ),
          placeOfIssue: "Colombo",
          attachments: passportAttachmentList,
        };
      }
      if (allDetails.jobList.length === 1) {
        selectedJob.current = {
          appliedPost: allDetails.jobList[0].jobCategoryLineName,
          salary: "",
          contactPeriod: "",
        };
      }
      generateCVData();
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  const generateCVData = () => {
    let nonRetrievedList = [];
    let ol;
    let al;

    // checking for non retrieved values
    if (allDetails?.preferredName === "")
      nonRetrievedList.push("Preferred Name");
    if (allDetails?.nationalityName === "")
      nonRetrievedList.push("Nationality");
    if (allDetails?.religionName === "") nonRetrievedList.push("Religion");
    if (allDetails?.children === 0) nonRetrievedList.push("No Of Children");
    if (allDetails?.weight === 0) nonRetrievedList.push("Weight");
    if (allDetails?.height === 0) nonRetrievedList.push("Height");
    if (allDetails?.complexionName === "") nonRetrievedList.push("Complexion");
    if (allDetails?.passportList.length === 0)
      nonRetrievedList.push("Passport");
    if (allDetails?.experienceList.length === 0)
      nonRetrievedList.push("Work Experience");
    if (allDetails?.educationList.length === 0) {
      nonRetrievedList.push("Education");
    } else {
      al = allDetails?.educationList.find(
        (item) => item.levelName === "G.C.E.(A/L)"
      );
      ol = allDetails.educationList.find(
        (item) => item.levelName === "G.C.E.(O/L)"
      );
    }
    if (allDetails?.photoList.length === 0) nonRetrievedList.push("Photo");
    if (allDetails?.jobList.length === 0) nonRetrievedList.push("Applied Job");
    if (allDetails?.remarkList.length === 0) nonRetrievedList.push("Remark");
    if (allDetails?.skillList.length === 0) nonRetrievedList.push("Skill");
    if (allDetails?.languageList.length === 0)
      nonRetrievedList.push("Language");
    setUndetectedPropertyList(nonRetrievedList);

    setCVData((prev_data) => ({
      ...prev_data,
      // referenceNumber: 0,
      fullName: allDetails.firstName + " " + allDetails.lastName,
      preferredName: allDetails.preferredName,
      nationality: allDetails.nationalityName,
      city: allDetails.cityName,
      gender: allDetails.genderName,
      dateOfBirth:
        allDetails.dateOfBirth === null
          ? ""
          : ConvertMillisToDate(allDetails.dateOfBirth),
      age:
        allDetails.dateOfBirth === null
          ? ""
          : `${CalculateYearDifference(allDetails.dateOfBirth)} YRS`,
      placeOfBirth: allDetails.placeOfBirth,
      civilStatus: allDetails.civilStatusName,
      religion: allDetails.religionName,
      NoOfChildren: allDetails.children,
      yChildAge:
        allDetails.youngestChildAgeYear === 0 &&
        allDetails.youngestChildAgeMonth === 0
          ? ""
          : `${
              allDetails.youngestChildAgeYear > 0
                ? allDetails.youngestChildAgeYear + "Y"
                : ""
            } ${
              allDetails.youngestChildAgeMonth > 0
                ? allDetails.youngestChildAgeMonth + "M"
                : ""
            }`,
      weight: allDetails.weight,
      height: allDetails.height,
      complexion: allDetails.complexionName,
      education: {
        levelName:
          allDetails?.educationList[allDetails?.educationList?.length - 1]
            ?.levelName,
        ol: ol === undefined ? "" : ol.achievementName,
        al: al === undefined ? "" : al.achievementName,
      },
      appliedJob:
        selectedJob.current !== null
          ? selectedJob.current
          : {
              appliedPost: "",
              salary: "",
              contactPeriod: "",
            },
      passport:
        selectedPassport.current !== null
          ? selectedPassport.current
          : {
              number: "",
              dateOfIssue: "",
              dateOfExpire: "",
              placeOfIssue: "",
            },
      remarks: allDetails.remarkList
        .filter((item) => item.isShowApplication)
        .map((item) => item.remark),
      faceImage:
        allDetails?.photoList?.find(
          (item) => item.photoTypeName === "Front Face Viewer"
        )?.filePath + cachelessURL(),
      fullImage:
        allDetails?.photoList?.find(
          (item) => item.photoTypeName === "Front Full View"
        )?.filePath + cachelessURL(),
      workExperience: allDetails.experienceList.filter((item) => item.isActive),
      skills: allDetails.skillList.filter((item) => item.isShowApplication),
      languages: allDetails.languageList.filter(
        (item) => item.isShowApplication
      ),
    }));
  };

  const setReferenceNumber = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let application = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
        bpartnerId: customerData.bpartnerId,
        isActive: formData.has("fm_1_is_active") ? true : false,
        customerApplicationId: applicationId,
        agentBpartnerId: dpAgentValue,
        countryId: dpCountryValue,
        status: 0,
        currencyId: parseInt(dpCurrencyValue),
        salaryAmount: formData.get("fm_1_salary_amount"),
        contractPeriodMonth:
          parseInt(
            !formData.get("fm_1_contract_period_years")
              ? 0
              : formData.get("fm_1_contract_period_years")
          ) *
            12 +
          parseInt(
            !formData.get("fm_1_contract_period_months")
              ? 0
              : formData.get("fm_1_contract_period_months")
          ),
        jobCategoryLineId: dpJobValue,
        remark: formData.get("fm_1_remark"),
      };
      if (applicationId > 0) {
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/saveApplicationTab",
            data: application,
            template: "CONTROL_DATA",
          });
          resolve(result);
        }).then((nested_reponse_data) => {
          if (nested_reponse_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: applicationId
                ? "Application has successfully updated."
                : "Application has successfully created.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      } else {
        const reqBody = {
          orgId: loggedUser.orgId,
          bpartnerId: parseInt(customerData.bpartnerId),
          countryId: parseInt(dpCountryValue),
          agentBpartnerId: parseInt(dpAgentValue),
          jobCategoryLineId: parseInt(dpJobValue),
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/checkApplicationExist",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            switch (response_data.existType) {
              case 0:
                new Promise((resolve) => {
                  const result = sendRequest({
                    url: "/customer/saveApplicationTab",
                    data: application,
                    template: "CONTROL_DATA",
                  });
                  resolve(result);
                }).then((nested_reponse_data) => {
                  if (nested_reponse_data) {
                    Swal.fire({
                      icon: "success",
                      title: "Request Successful",
                      text: applicationId
                        ? "Application has successfully updated."
                        : "Application has successfully created.",
                      showConfirmButton: false,
                      timer: 5000,
                    });
                    setRefreshTable(true);
                  }
                });
                break;
              case 1:
                Swal.fire({
                  icon: "error",
                  title: "Request Failed",
                  text: "Application Already Exists",
                  showConfirmButton: false,
                  timer: 5000,
                });
                break;
              default:
                break;
            }
          }
        });
      }
    }
  };
  const setSelectedPassport = (passport_data) => {
    setIsLoaded(LOADING);
    let passportAttachmentList =
      passport_data.attachmentList.length > 0
        ? passport_data.attachmentList.map((item) => ({
            filePath: item.filePath + cachelessURL(),
          }))
        : [];
    selectedPassport.current = {
      number: passport_data.passportNo,
      dateOfIssue: ConvertMillisToDate(passport_data.issueDate),
      dateOfExpire: ConvertMillisToDate(passport_data.expireDate),
      placeOfIssue: "",
      attachments: passportAttachmentList,
    };

    generateCVData();
    setIsLoaded(LOADED);
  };
  const resetForm = () => {
    setIsValidated(false);
    setIsLoaded(LOADING);
    setCVData((prev_data) => ({
      ...prev_data,
      referenceNumber: "",
      country: "",
    }));
    generateCVData();
    setIsLoaded(LOADED);
    setApplicationId(0);
    setDpCountryValue(0);
    setDpCurrencyValue(CURRENCY_LIST[0]);
    setDpAgentValue(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Application Generator"
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12 col-sm-8 mb-2 mb-sm-0">
            <div className="position-relative">
              {instance.loading || isLoaded === LOADING ? (
                <div className="form-loader d-flex flex-column justify-content-center align-items-center">
                  <div className="loader-box">
                    <div className="loader">
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                      <div className="line bg-primary"></div>
                    </div>
                  </div>
                  <div className="fw-bold">Generating...</div>
                </div>
              ) : null}
              {loggedUser.orgId === WORKNET_GLOBAL ||
              loggedUser.orgId === NEW_SKILLS_HIRE ? (
                loggedUser.orgId === WORKNET_GLOBAL ? (
                  <PDFViewer width={"100%"} height="700px">
                    <TypeWorknetGlobal cv_data={cvData} />
                  </PDFViewer>
                ) : (
                  <PDFViewer width={"100%"} height="700px">
                    <TypeNewSkillsHire cv_data={cvData} />
                  </PDFViewer>
                )
              ) : (
                <PDFViewer width={"100%"} height="700px">
                  <TypeFive cv_data={cvData} />
                </PDFViewer>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="card">
              <div className="card-body">
                <div>
                  {undetectedPropertyList.map((property_item, index) => (
                    <div key={index} className="mb-2 d-flex align-items-center">
                      <AlertCircle height={"16px"} className="txt-secondary" />
                      <div className="ml-2">{`${property_item} Details Not Found`}</div>
                    </div>
                  ))}
                </div>
                <div className="mb-4">
                  {allDetails?.passportList?.length > 1 ? (
                    <div>
                      <div className="fw-bold mb-2">Passport Details</div>
                      {selectedPassport.current === null ? (
                        <small className="fw-bold txt-danger">
                          Multiple Passports Detected. Select One.
                        </small>
                      ) : null}
                      {allDetails.passportList.map((passport_item, index) => (
                        <div
                          key={index}
                          className={`selectable-cards ${
                            passport_item.passportNo ===
                            selectedPassport.current?.number
                              ? "selected-card"
                              : ""
                          } mb-2 pointer`}
                          onClick={() => setSelectedPassport(passport_item)}
                        >
                          <div>
                            <small className="text-uppercase text-secondary fw-bold">
                              Passport Number
                            </small>
                            <small className="text-uppercase text-secondary ml-2">
                              {passport_item.passportNo}
                            </small>
                          </div>
                          <div>
                            <small className="text-uppercase text-secondary fw-bold">
                              Date of Issue
                            </small>
                            <small className="text-uppercase text-secondary ml-2">
                              {ConvertMillisToDate(passport_item.issueDate)}
                            </small>
                          </div>
                          <div>
                            <small className="text-uppercase text-secondary fw-bold">
                              Date of Expire
                            </small>
                            <small className="text-uppercase text-secondary ml-2">
                              {ConvertMillisToDate(passport_item.expireDate)}
                            </small>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <form
                  tabIndex={1}
                  onSubmit={(evt) => setReferenceNumber(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="mb-4">
                    <div className="form-group position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_country_id"
                        className="col-form-label pt-0 required-input-mark"
                      >
                        Country
                      </label>
                      <select
                        id="id_input_fm_1_sa_country_id"
                        className={`form-select ${
                          applicationId ? "disabled" : "F"
                        }`}
                        onChange={(evt) => setDpCountryValue(evt.target.value)}
                        value={dpCountryValue}
                        required
                      >
                        {countryList.length
                          ? countryList.map((list_item, index) => (
                              <option value={list_item?.countryId} key={index}>
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Country
                      </small>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-group position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_agent_id"
                        className="col-form-label pt-0 required-input-mark"
                      >
                        Agent
                      </label>
                      <select
                        id="id_input_fm_1_sa_agent_id"
                        className={`form-select ${
                          applicationId ? "disabled" : "F"
                        }`}
                        onChange={(evt) =>
                          setDpAgentValue(parseInt(evt.target.value))
                        }
                        value={dpAgentValue}
                        required
                      >
                        {agentList.length
                          ? agentList.map((listItem, index) => (
                              <option value={listItem?.bpartnerId} key={index}>
                                {listItem?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select an Agent
                      </small>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="form-group position-relative">
                      <label
                        htmlFor="id_input_fm_1_sa_job_category_line_id"
                        className="col-form-label pt-0 required-input-mark"
                      >
                        Position
                      </label>
                      <select
                        id="id_input_fm_1_sa_job_category_line_id"
                        className={`form-select ${
                          applicationId ? "disabled" : "F"
                        }`}
                        onChange={(evt) =>
                          setDpJobValue(parseInt(evt.target.value))
                        }
                        value={dpJobValue}
                        required
                      >
                        {jobList.length
                          ? jobList.map((list_item, index) => (
                              <option
                                value={list_item?.jobCategoryLineId}
                                key={index}
                              >
                                {list_item?.name}
                              </option>
                            ))
                          : null}
                      </select>
                      <small className="invalid-feedback position-absolute">
                        Please select a Position
                      </small>
                    </div>
                  </div>
                  <div className="form-row mb-4">
                    <div className="form-group col-8 position-relative">
                      <label htmlFor="id_input_fm_1_salary_amount">
                        Salary
                      </label>
                      <input
                        id="id_input_fm_1_salary_amount"
                        name="fm_1_salary_amount"
                        type="text"
                        maxLength={200}
                        className="form-control"
                        placeholder="000.00"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateDouble: true,
                          })
                        }
                      />
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-4 position-relative">
                      <label
                        htmlFor="id_input_fm_1_currency_type"
                        className="col-form-label pt-0"
                      >
                        Currency
                      </label>
                      <select
                        id="id_input_fm_1_currency_type"
                        className="form-select"
                        onChange={(evt) => setDpCurrencyValue(evt.target.value)}
                        value={dpCurrencyValue}
                      >
                        {currencyList.length
                          ? currencyList.map((list_item, index) => (
                              <option value={list_item.currencyId} key={index}>
                                {list_item.currencySearchKey}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                  </div>
                  <div className="form-group mb-4 d-flex">
                    <div className="form-group position-relative">
                      <label htmlFor="id_input_fm_1_contract_period">
                        Contract Period
                      </label>
                      <div className="d-flex">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>No. of Years</Tooltip>}
                        >
                          <div className="input-group pr-1">
                            <div className="input-group-prepend">
                              <div className="input-group-text">YY</div>
                            </div>
                            <input
                              id="id_input_fm_1_contract_period"
                              name="fm_1_contract_period_years"
                              type="text"
                              className="form-control"
                              placeholder="00"
                              onChange={(evt) =>
                                formValidation({
                                  event: evt,
                                  validateNumber: true,
                                })
                              }
                            />
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>No. of Months</Tooltip>}
                        >
                          <div className="input-group pl-1">
                            <div className="input-group-prepend">
                              <div className="input-group-text">MM</div>
                            </div>
                            <input
                              name="fm_1_contract_period_months"
                              type="text"
                              placeholder="00"
                              className="form-control"
                              onChange={(evt) =>
                                formValidation({
                                  event: evt,
                                  validateNumber: true,
                                })
                              }
                            />
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-4 position-relative">
                    <label htmlFor="id_input_fm_1_remark">Remark</label>
                    <textarea
                      id="id_input_fm_1_remark"
                      name="fm_1_remark"
                      maxLength={250}
                      rows="3"
                      className="form-control"
                      placeholder="Enter Remark"
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                    <div className="checkbox checkbox-solid-dark col-6">
                      <input
                        id="id_input_fm_1_is_active"
                        name="fm_1_is_active"
                        type="checkbox"
                        defaultChecked
                      />
                      <label htmlFor="id_input_fm_1_is_active">Active</label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="btn btn-primary" type="submit">
                      {applicationId ? "Update" : "Create Reference Number"}
                    </button>
                    <button
                      className="btn btn-warning ml-2 d-flex align-items-center"
                      type="reset"
                    >
                      <RefreshCw height={"16px"} />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <Datatable
            titleData="Application List"
            columnData={tableColumnHeaderList}
            rowData={applicationRowList}
          />
        </div>
      </div>
    </>
  );
};

export default Index;

/**
 * ============================================  UseState Hooks  ============================================
 *
 * 1. refrshTable state
 * -----For this state, there are 2 states as, Refreshing(1) and Refreshed(0). Initially, value of this state
 * -----will be Refreshing(1). So the data related to table and form will be loaded. After loading data into
 * -----table and form dropdowns and remark textfield, value of this state will be updated as Refreshed(0).
 * -----So, If you want to refresh table and form, update refreshTable state as Refreshing(1). Current this
 * -----state will be used by setReferenceNumber() and refreshTable useEffect hook.
 *
 */
