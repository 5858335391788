import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// components
import Swal from "sweetalert2";
import { Alert } from "reactstrap";
import { AlertTriangle, PlusCircle, RefreshCw } from "react-feather";

// custom components
import Datatable, {
  BooleanValue,
  ControlButtons,
} from "../../../common/datatable";
import FileUploader from "../../../common/fileUploader";

// customer hooks
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import useAccess from "../../../../customHooks/useAccess";

// utilities
import formValidation from "../../../../customHooks/useValidation";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";
import ConfigDB from "../../../../data/customizer/config";
import {
  CIVIL_STATUS,
  INSERT_CIVIL_STATUS,
  INSERT_RELATIONSHIP,
  RELATIONSHIP,
} from "../../../../constant";

const Family = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const LOADING = 0;
  const LOADED = 1;

  const EDITFUNCTION = "EditCustomer";
  const INSERTFUNCTION = "InsertCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";
  const DELETEFUNCTION = "DeleteCustomer";

  const MAXFILELIMIT = 5;

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const isInsertAllowed = useAccess(loggedUser)[0];
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [attachmentList, setAttachmentList] = useState([]);

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);
  const [previewFileList, setPreviewFileList] = useState([]);

  // form controls
  const [isValidated, setIsValidated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [refreshDpRelationship, setRefreshDpRelationship] = useState(false);
  const [refreshDpCivilStatus, setRefreshDpCivilStatus] = useState(false);

  // form values
  const [relationshipList, setRelationshipList] = useState([
    { relationshipId: "", name: "-Select-" },
  ]);
  const [dpRelationshipValue, setDpRelationshipValue] = useState(0);
  const [civilStatusList, setCivilStatusList] = useState([
    { civilStatusId: "", name: "-Select-" },
  ]);
  const [dpCivilStatusValue, setDpCivilStatusValue] = useState(0);
  const [familyId, setFamilyId] = useState(0);

  // table data
  const [familyRowList, setFamilyRowList] = useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    "Name",
    "Relationship",
    "Civil Status",
    "Date Of Birth",
    "Place Of Birth",
    "Contact Number",
    "Address",
    "Remarks",
    {
      name: "Guardian",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDITFUNCTION) || checkIsAccessible(DELETEFUNCTION),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editFamily] = useState(() => (family_id, response_data) => {
    const editingFamily = response_data.find(
      (item) => item.familyId === family_id
    );
    setFamilyId(editingFamily.familyId);
    setDpRelationshipValue(editingFamily.relationshipId);
    setDpCivilStatusValue(editingFamily.civilStatusId);
    document.querySelector("input[name='fm_8_name']").value =
      editingFamily.name;
    document.querySelector("input[name='fm_8_date_of_birth']").value =
      ConvertMillisToDate(editingFamily.dateOfBirth);
    document.querySelector("input[name='fm_8_place_of_birth']").value =
      editingFamily.placeOfBirth;
    document.querySelector("input[name='fm_8_contact_number']").value =
      editingFamily.contactNumber;
    document.querySelector("input[name='fm_8_address']").value =
      editingFamily.address;
    document.querySelector("textarea[name='fm_8_remark']").value =
      editingFamily.remark;
    document.querySelector("input[name='fm_8_is_active']").checked =
      editingFamily.isActive;
    document.querySelector("input[name='fm_8_is_guardian']").checked =
      editingFamily.isGuardian;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    const reqBody = {
      familyId: editingFamily.familyId,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/getFamilyAttachmentListByFamilyId",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      if (response_data) {
        let tempList = [];
        let tempList2 = [];
        response_data.forEach((listItem) => {
          tempList.push({
            documentId: listItem.id,
            isModified: false,
            attachmentExtension: "",
            attachment: "",
          });
          tempList2.push({
            id: listItem.id,
            type: listItem.filePath.split(".")[1],
            source: listItem.filePath,
          });
        });
        setPreviewFileList(tempList2);
        setAttachmentList([...tempList]);
        setIsLoaded(LOADED);
        setRefreshTable(false);
      }
    });
  });
  const [deleteFamily] = useState(() => (family_id, logged_user) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will permenantly deletes this Family Record!!!",
      showDenyButton: true,
      confirmButtonText: "Delete",
      denyButtonText: "Cancel",
      denyButtonColor: "#2a3142",
    }).then((result) => {
      if (result.isConfirmed) {
        const reqBody = {
          clientId: logged_user.clientId,
          orgId: logged_user.orgId,
          userId: logged_user.userId,
          familyId: family_id,
        };
        let responseData = new Promise((resolve) => {
          const result = sendRequest({
            url: "customer/deleteFamily",
            data: reqBody,
            template: "CONTROL_DATA",
          });
          resolve(result);
        });
        responseData.then((response_data) => {
          if (response_data) {
            Swal.fire({
              icon: "success",
              title: "Request Successful",
              text: "Family Record has successfully deleted.",
              showConfirmButton: false,
              timer: 5000,
            });
            setRefreshTable(true);
          }
        });
      }
    });
  });

  // form control functions
  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let family = {
        bpartnerId: parseInt(b_partner_id),
        familyId: parseInt(familyId),
        clientId: parseInt(client_id),
        orgId: parseInt(org_id),
        userId: parseInt(loggedUser.userId),
        name: formData.get("fm_8_name"),
        relationshipId: parseInt(dpRelationshipValue),
        civilStatusId: parseInt(dpCivilStatusValue),
        dateOfBirth: formData.get("fm_8_date_of_birth"),
        placeOfBirth: formData.get("fm_8_place_of_birth"),
        contactNumber: formData.get("fm_8_contact_number"),
        address: formData.get("fm_8_address"),
        isActive: formData.get("fm_8_is_active") ? true : false,
        isGuardian: formData.get("fm_8_is_guardian") ? true : false,
        remark: formData.get("fm_8_remark"),
        attachmentList: attachmentList,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/customer/saveFamilyTab",
          data: family,
          template: "CONTROL_DATA",
        });
        resolve(result);
      });
      responseData.then((reponse_data) => {
        if (reponse_data) {
          Swal.fire({
            icon: "success",
            title: "Request Successful",
            text: familyId
              ? "Family Member has successfully updated."
              : "New Family Member has successfully added.",
            showConfirmButton: false,
            timer: 5000,
          });
          setIsLoaded(LOADING);
          setRefreshTable(true);
        }
      });
    }
  };

  const resetForm = (evt) => {
    setIsValidated(false);
    setDpRelationshipValue(0);
    setDpCivilStatusValue(0);
    setAttachmentList([]);
    setPreviewFileList([]);
    setFamilyId(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
  };

  const openMasterFileWindow = (interface_name, function_name) => {
    if (isInsertAllowed(function_name, interface_name)) {
      window.open(
        `${ConfigDB.data.hosted_url}/masterfile/${interface_name
          .replace(/(\B[A-Z])/g, "_$1")
          .toLocaleLowerCase()}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,width=1200,height=600"
      );
    } else {
      Swal.fire({
        icon: "warning",
        title: "Not Allowed",
        text: "You don't have permission for this task.",
        showConfirmButton: false,
        timer: 5000,
      });
      // TODO: get all the message strings to a one page
    }
  };

  useEffect(() => {
    if (b_partner_id > 0) {
      if (refreshTable) {
        const reqBody = {
          clientId: loggedUser.clientId,
          orgId: loggedUser.orgId,
          userId: loggedUser.userId,
          bpartnerId: b_partner_id,
        };
        new Promise((resolve) => {
          const result = sendRequest({
            url: "/customer/viewFamilyTab",
            data: reqBody,
          });
          resolve(result);
        }).then((response_data) => {
          if (response_data) {
            if (response_data.activeRelationshipList) {
              setRelationshipList([
                { relationshipId: "", name: "-Select-" },
                ...response_data.activeRelationshipList,
              ]);
            }
            if (response_data.activeCivilStatusList) {
              setCivilStatusList([
                { civilStatusId: "", name: "-Select-" },
                ...response_data.activeCivilStatusList,
              ]);
            }
            if (response_data.allCustomerFamilyTableList) {
              let tempList = [];
              response_data.allCustomerFamilyTableList.forEach((listItem) => {
                tempList.push([
                  listItem.familyId,
                  listItem.name,
                  listItem.relationshipName,
                  listItem.civilStatusName,
                  ConvertMillisToDate(listItem.dateOfBirth),
                  listItem.placeOfBirth,
                  listItem.contactNumber,
                  listItem.address,
                  listItem.remark,
                  listItem.isGuardian ? "True" : "False",
                  listItem.isActive ? "True" : "False",
                  <ControlButtons
                    editMethod={() =>
                      editFamily(
                        listItem.familyId,
                        response_data.allCustomerFamilyTableList
                      )
                    }
                    disableEdit={!checkIsAccessible(EDITFUNCTION)}
                    deleteMethod={() =>
                      deleteFamily(listItem.familyId, loggedUser)
                    }
                    disableDelete={!checkIsAccessible(DELETEFUNCTION)}
                  />,
                ]);
              });
              setFamilyRowList([...tempList]);
            }
            setIsLoaded(LOADED);
            setRefreshTable(false);
          }
        });
      }
    } else {
      setIsLoaded(LOADED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable]);

  useEffect(() => {
    if (refreshDpRelationship) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveRelationship",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setRelationshipList([
            { relationshipId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpRelationship(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpRelationship]);
  useEffect(() => {
    if (refreshDpCivilStatus) {
      const reqBody = {
        clientId: loggedUser.clientId,
      };
      new Promise((resolve) => {
        const result = sendRequest({
          url: "/getAllActiveCivilStatus",
          data: reqBody,
        });
        resolve(result);
      }).then((response_data) => {
        if (response_data) {
          setCivilStatusList([
            { civilStatusId: "", name: "-Select-" },
            ...response_data,
          ]);
          setRefreshDpCivilStatus(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDpCivilStatus]);

  return (
    <>
      <div className="card-modified-body bg-white pt-0 mb-4">
        <Alert
          className="alert-dismissible d-flex align-items-center"
          color="warning"
          isOpen={b_partner_id === 0}
        >
          <AlertTriangle />
          <p className="ml-2">
            You must create a Customer, before add Family Member Details.
          </p>
        </Alert>
        <form
          onSubmit={(evt) => submitForm(evt)}
          onReset={(evt) => resetForm(evt)}
          className={`position-relative form-8 ${
            isValidated ? "was-validated" : ""
          }`}
          noValidate
        >
          {isLoaded === LOADING ? (
            <div className="form-loader d-flex justify-content-center align">
              <div className="loader-box">
                <div className="loader">
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                  <div className="line bg-secondary"></div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-row mb-4">
            <div className="form-group col-12 col-sm-8 mb-2 mb-sm-2 position-relative">
              <label
                htmlFor="id_input_fm_8_name"
                className="required-input-mark"
              >
                Name
              </label>
              <input
                id="id_input_fm_8_name"
                name="fm_8_name"
                type="text"
                maxLength={150}
                className="form-control"
                placeholder="Enter Member Name"
                autoComplete="off"
                onChange={(evt) => formValidation({ event: evt })}
                required
              />
              <div className="invalid-feedback position-absolute">
                Please enter Family Member Name.
              </div>
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
            <div className="form-group col-12 col-sm-4 mb-2 mb-sm-2 position-relative">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_8_sa_relationship_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Relationship
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpRelationship ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpRelationship(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(RELATIONSHIP, INSERT_RELATIONSHIP)
                  }
                />
              </div>
              <select
                id="id_input_fm_8_sa_relationship_id"
                name="fm_8_sa_relationship_id"
                className="form-select"
                onChange={(evt) => setDpRelationshipValue(evt.target.value)}
                value={dpRelationshipValue}
                required
              >
                {relationshipList.length
                  ? relationshipList.map((listItem, index) => (
                      <option value={listItem?.relationshipId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
              <div className="invalid-feedback position-absolute">
                Please select Relationship
              </div>
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="form-group col-12 mb-2 mb-sm-2 col-sm-3">
              <div className="d-flex">
                <label
                  htmlFor="id_input_fm_8_sa_civil_status_id"
                  className="col-form-label pt-0 required-input-mark"
                >
                  Civil Status
                </label>
                <RefreshCw
                  className={`mx-3 mt-1 dp-refresh-icon ${
                    refreshDpCivilStatus ? "rotate-icon" : ""
                  }`}
                  height={"14px"}
                  onClick={() => setRefreshDpCivilStatus(true)}
                />
                <PlusCircle
                  className="mt-1 dp-refresh-icon"
                  height={"14px"}
                  onClick={() =>
                    openMasterFileWindow(CIVIL_STATUS, INSERT_CIVIL_STATUS)
                  }
                />
              </div>
              <select
                id="id_input_fm_8_sa_civil_status_id"
                name="fm_8_sa_civil_status_id"
                className="form-select"
                onChange={(evt) => setDpCivilStatusValue(evt.target.value)}
                value={dpCivilStatusValue}
              >
                {civilStatusList.length
                  ? civilStatusList.map((listItem, index) => (
                      <option value={listItem?.civilStatusId} key={index}>
                        {listItem?.name}
                      </option>
                    ))
                  : null}
              </select>
            </div>
            <div className="form-group col-12 mb-2 mb-sm-2 col-sm-3">
              <label htmlFor="id_input_fm_8_date_of_birth">Date Of Birth</label>
              <input
                id="id_input_fm_8_date_of_birth"
                name="fm_8_date_of_birth"
                type="date"
                className="form-control"
                placeholder="dd/mm/yyyy"
              />
            </div>
            <div className="form-group col-12 mb-2 mb-sm-2 col-sm-3">
              <label htmlFor="id_input_fm_8_place_of_birth">
                Place Of Birth
              </label>
              <input
                id="id_input_fm_8_place_of_birth"
                name="fm_8_place_of_birth"
                type="text"
                maxLength={200}
                className="form-control"
                placeholder="Enter Place Of Birth"
                autoComplete="off"
                onChange={(evt) => formValidation({ event: evt })}
              />
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
            <div className="form-group col-12 col-sm-3">
              <label htmlFor="id_input_fm_8_contact_number">
                Contact Number
              </label>
              <input
                id="id_input_fm_8_contact_number"
                name="fm_8_contact_number"
                type="text"
                maxLength={15}
                minLength={9}
                className="form-control"
                placeholder="Enter Contact Number"
                autoComplete="off"
                onChange={(evt) =>
                  formValidation({
                    event: evt,
                    checkExpression: /[^\d-]+/,
                  })
                }
              />
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="form-group col-12">
              <label htmlFor="id_input_fm_8_address">Address</label>
              <input
                id="id_input_fm_8_address"
                name="fm_8_address"
                type="text"
                maxLength={250}
                className="form-control"
                placeholder="Enter Address"
                autoComplete="off"
                onChange={(evt) => formValidation({ event: evt })}
              />
              <small className="txt-danger position-absolute d-none input-validation"></small>
            </div>
          </div>
          <FileUploader
            attachment_list={attachmentList}
            preview_list={previewFileList}
            set_attachment_list={setAttachmentList}
            set_preview_list={setPreviewFileList}
            max_file_limit={MAXFILELIMIT}
          />
          <div className="form-group mb-4">
            <label htmlFor="id_input_fm_8_remark">Remarks</label>
            <textarea
              id="id_input_fm_8_remark"
              name="fm_8_remark"
              rows="3"
              maxLength={250}
              className="form-control"
              placeholder="Enter Remarks"
            ></textarea>
            <small className="txt-danger position-absolute d-none input-validation"></small>
          </div>
          <div className="form-row mb-4">
            <div className="checkbox checkbox-solid-dark col-12 col-sm-3 mx-1 mx-sm-0">
              <input
                id="id_input_fm_8_is_guardian"
                name="fm_8_is_guardian"
                type="checkbox"
              />
              <label htmlFor="id_input_fm_8_is_guardian">Guardian</label>
            </div>
            {(checkIsAccessible(INSERTFUNCTION) && familyId === 0) ||
            (checkIsAccessible(DEACTIVATEFUNCTION) && familyId !== 0) ? (
              <div className="checkbox checkbox-solid-dark col-12 col-sm-3 mx-1 mx-sm-0">
                <input
                  id="id_input_fm_8_is_active"
                  name="fm_8_is_active"
                  type="checkbox"
                  defaultChecked
                />
                <label htmlFor="id_input_fm_8_is_active">Active</label>
              </div>
            ) : null}
          </div>
          {b_partner_id ? (
            checkIsAccessible(INSERTFUNCTION) ||
            (checkIsAccessible(EDITFUNCTION) && familyId !== 0) ? (
              <div className="d-sm-flex justify-content-end">
                <button
                  className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                  type="submit"
                >
                  {familyId ? "Update" : "Submit"}
                </button>
                <button
                  className="btn btn-warning col-12 col-sm-2"
                  type="reset"
                >
                  Reset
                </button>
              </div>
            ) : null
          ) : null}
        </form>
      </div>
      <div className="mb-4">
        <Datatable
          titleData="Family List"
          columnData={tableColumnHeaderList}
          rowData={familyRowList}
        />
      </div>
    </>
  );
};

export default Family;
