import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "../../common/breadcrumb";
import Datatable, {
  ControlButtons,
  BooleanValue,
} from "../../common/datatable";
import formValidation from "../../../customHooks/useValidation";
import useAxiosTemplates from "../../../customHooks/useAxiosTemplates";
import useAccess from "../../../customHooks/useAccess";
import {
  DEACTIVATE_EDUCATION_ACHIEVEMENT,
  DELETE_EDUCATION_ACHIEVEMENT,
  EDIT_EDUCATION_ACHIEVEMENT,
  EDUCATION_ACHIEVEMENT,
  INSERT_EDUCATION_ACHIEVEMENT,
} from "../../../constant";

// network requests

const EducationAchievement = () => {
  // breadcrumb values
  let childLinks = [
    { value: "Master File", active: false },
    { value: "Education", active: false },
    { value: "Achievement", active: true },
  ];

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, EDUCATION_ACHIEVEMENT);
  const sendRequest = useAxiosTemplates();

  // helper containers
  const [resData, setResData] = useState();

  // interface status data
  const [refreshTable, setRefreshTable] = useState(true);

  // form controls
  const [isValidated, setIsValidated] = useState(false);

  // form values
  const [educationAchievementId, setEducationAchievementId] = useState(0);

  // table data
  const [educationAchievementRowList, setEducationAchievementRowList] =
    useState([]);
  const tableColumnHeaderList = [
    {
      name: "Id",
      options: {
        display: false,
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
    // {
    //   name: "Client",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    // {
    //   name: "Organization",
    //   options: {
    //     display: false,
    //     download: true,
    //     filter: false,
    //     viewColumns: false,
    //   },
    // },
    "Code",
    "Name",
    "Remarks",
    {
      name: "Active",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <BooleanValue value={value} />;
        },
      },
    },
    {
      name: "",
      options: {
        display:
          checkIsAccessible(EDIT_EDUCATION_ACHIEVEMENT) ||
          checkIsAccessible(DELETE_EDUCATION_ACHIEVEMENT),
        download: false,
        filter: false,
        viewColumns: false,
      },
    },
  ];
  // table functions
  const [editEducationAchievement] = useState(
    () => (education_achievement_id, response_data) => {
      const editingEducationAchievement = response_data.find(
        (item) => item.educationAchievementId === education_achievement_id
      );
      setEducationAchievementId(education_achievement_id);
      document.querySelector("input[name='fm_1_search_key']").value =
        editingEducationAchievement.searchKey;
      document.querySelector("input[name='fm_1_name']").value =
        editingEducationAchievement.name;
      document.querySelector("textarea[name='fm_1_remark']").value =
        editingEducationAchievement.remark;
      document.querySelector("input[name='fm_1_is_active']").checked =
        editingEducationAchievement.isActive;
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  );
  const [deleteEducationAchievement] = useState(
    () => (education_achievement_id, logged_user) => {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "This will permenantly deletes this Education Achievement!!!",
        showDenyButton: true,
        confirmButtonText: "Delete",
        denyButtonText: "Cancel",
        denyButtonColor: "#2a3142",
      }).then((result) => {
        if (result.isConfirmed) {
          const reqBody = {
            clientId: logged_user.clientId,
            orgId: logged_user.orgId,
            userId: logged_user.userId,
            educationAchievementId: education_achievement_id,
          };
          let responseData = new Promise((resolve) => {
            const result = sendRequest({
              url: "/deleteEducationAchievement",
              data: reqBody,
              template: "CONTROL_DATA",
            });
            resolve(result);
          });
          responseData.then((response_data) => {
            if (response_data) {
              Swal.fire({
                icon: "success",
                title: "Request Successful",
                text: "Education Achievement has successfully deleted.",
                showConfirmButton: false,
                timer: 5000,
              });
              setRefreshTable(true);
            }
          });
        }
      });
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  // fetch master data
  useEffect(() => {
    if (refreshTable) {
      document
        .querySelectorAll("input:required")
        .forEach((elem) =>
          elem.previousSibling.classList.add("required-input-mark")
        );
      const reqBody = {
        clientId: loggedUser.clientId,
        orgId: loggedUser.orgId,
        userId: loggedUser.userId,
      };
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/viewEducationAchievement",
          data: reqBody,
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          if (response_data.allEducationAchievementList) {
            setResData(response_data.allEducationAchievementList);
          }
        }
      });
      setRefreshTable(false);
    }
  }, [refreshTable, loggedUser, isValidated, sendRequest]);

  useEffect(() => {
    if (resData) {
      let tempList = [];
      resData.forEach((listItem) => {
        // if (
        //   loggedUser.activeOrgRoleList?.find(
        //     (item) => item.orgId === listItem.orgId
        //   )
        // ) {
        tempList.push([
          listItem.educationAchievementId,
          // loggedUser.activeClientList?.find(
          //   (item) => item.clientId === listItem.clientId
          // ).name,
          // loggedUser.activeOrgRoleList?.find(
          //   (item) => item.orgId === listItem.orgId
          // ).name,
          listItem.searchKey,
          listItem.name,
          listItem.remark,
          listItem.isActive ? "True" : "False",
          <ControlButtons
            editMethod={() =>
              editEducationAchievement(listItem.educationAchievementId, resData)
            }
            disableEdit={!checkIsAccessible(EDIT_EDUCATION_ACHIEVEMENT)}
            deleteMethod={() =>
              deleteEducationAchievement(
                listItem.educationAchievementId,
                loggedUser
              )
            }
            disableDelete={!checkIsAccessible(DELETE_EDUCATION_ACHIEVEMENT)}
          />,
        ]);
        // }
      });
      setEducationAchievementRowList([...tempList]);
    }
  }, [
    resData,
    editEducationAchievement,
    deleteEducationAchievement,
    loggedUser,
    checkIsAccessible,
  ]);

  // form control functions
  const resetForm = () => {
    setIsValidated(false);
    setEducationAchievementId(0);
    let inputFields = document.querySelectorAll(".form-control");
    let customValidationMessages =
      document.querySelectorAll(".input-validation");
    inputFields.forEach((field) => {
      if (field.classList.contains("is-valid")) {
        field.classList.remove("is-valid");
      } else if (field.classList.contains("is-invalid")) {
        field.classList.remove("is-invalid");
      }
    });
    customValidationMessages.forEach((element) => {
      if (element.classList.contains("input-validation")) {
        if (!element.classList.contains("d-none")) {
          element.classList.add("d-none");
        }
      }
    });
    setTimeout(() => {
      document.querySelector("select[name='fm_1_sa_client_id']").value =
        loggedUser.clientId;
      document.querySelector("select[name='fm_1_sa_org_id']").value =
        loggedUser.orgId;
    }, 100);
  };

  const submitForm = (evt) => {
    setIsValidated(true);
    evt.preventDefault();
    if (evt.target.checkValidity()) {
      const formData = new FormData(evt.target);
      let responseData = new Promise((resolve) => {
        const result = sendRequest({
          url: "/checkEducationAchievementExist",
          data: {
            clientId: loggedUser.clientId,
            educationAchievementId: educationAchievementId,
            searchKey: formData.get("fm_1_search_key"),
            name: formData.get("fm_1_name"),
          },
        });
        resolve(result);
      });
      responseData.then((response_data) => {
        if (response_data) {
          switch (response_data.existType) {
            case 0:
              let educationAchievement = {
                clientId: parseInt(loggedUser.clientId),
                orgId: parseInt(loggedUser.orgId),
                userId: parseInt(loggedUser.userId),
                educationAchievementId: educationAchievementId,
                isActive: document.querySelector("input[name='fm_1_is_active']")
                  ?.checked,
                searchKey: formData.get("fm_1_search_key"),
                name: formData.get("fm_1_name"),
                remark: formData.get("fm_1_remark"),
              };
              let nestedResponseData = new Promise((resolve) => {
                const result = sendRequest({
                  url: "/saveEducationAchievement",
                  data: educationAchievement,
                  template: "CONTROL_DATA",
                });
                resolve(result);
              });
              nestedResponseData.then((nested_reponse_data) => {
                if (nested_reponse_data) {
                  Swal.fire({
                    icon: "success",
                    title: "Request Successful",
                    text: educationAchievementId
                      ? "Education Achievement has successfully updated."
                      : "New Education Achievement has successfully added.",
                    showConfirmButton: false,
                    timer: 5000,
                  });
                  setRefreshTable(true);
                }
              });
              break;
            case 1:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 2:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Name Exists",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            case 3:
              Swal.fire({
                icon: "error",
                title: "Request Failed",
                text: "Search Key & Name Exist",
                showConfirmButton: false,
                timer: 5000,
              });
              break;
            default:
              break;
          }
        }
      });
    }
  };

  return (
    <>
      <Breadcrumb
        parent="Dashboard"
        title="Master File"
        children={childLinks}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>Education Achievement</h5>
                <span>Master File for creating a Education Achievement</span>
              </div>
              <div className="card-body">
                <form
                  tabIndex={1}
                  onSubmit={(evt) => submitForm(evt)}
                  onReset={(evt) => resetForm(evt)}
                  className={`form-1 ${isValidated ? "was-validated" : ""}`}
                  noValidate
                >
                  <div className="form-row mb-4">
                    <div className="form-group col-12 col-sm-6 mb-2 mb-sm-0 position-relative">
                      <label htmlFor="id_input_fm_1_search_key">
                        Search Key
                      </label>
                      <input
                        id="id_input_fm_1_search_key"
                        name="fm_1_search_key"
                        type="text"
                        maxLength={45}
                        className="form-control"
                        placeholder="Enter Search Key"
                        onChange={(evt) =>
                          formValidation({
                            event: evt,
                            validateSearchKey: true,
                          })
                        }
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Search Key
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                    <div className="form-group col-12 col-sm-6 position-relative">
                      <label htmlFor="id_input_fm_1_name">Name</label>
                      <input
                        id="id_input_fm_1_name"
                        name="fm_1_name"
                        type="text"
                        maxLength={150}
                        className="form-control"
                        placeholder="Enter Name"
                        onChange={(evt) => formValidation({ event: evt })}
                        required
                      />
                      <small className="invalid-feedback position-absolute">
                        Please enter a Education Achievement Name
                      </small>
                      <small className="txt-danger position-absolute d-none input-validation"></small>
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <label htmlFor="id_input_fm_1_remark">Remarks</label>
                    <textarea
                      id="id_input_fm_1_remark"
                      name="fm_1_remark"
                      rows="3"
                      maxLength={250}
                      className="form-control"
                      placeholder="Enter Remarks"
                      onChange={(evt) =>
                        formValidation({ event: evt, checkExpression: "" })
                      }
                    ></textarea>
                    <small className="txt-danger position-absolute d-none input-validation"></small>
                  </div>
                  <div className="form-group mb-4">
                    <div className="checkbox checkbox-solid-dark col-12 col-sm-6 mx-1 mx-sm-0">
                      <input
                        id="id_input_fm_1_is_active"
                        name="fm_1_is_active"
                        type="checkbox"
                        disabled={
                          !checkIsAccessible(DEACTIVATE_EDUCATION_ACHIEVEMENT)
                        }
                        defaultChecked
                      />
                      <label htmlFor="id_input_fm_1_is_active">Active</label>
                    </div>
                  </div>
                  {checkIsAccessible(INSERT_EDUCATION_ACHIEVEMENT) ||
                  (checkIsAccessible(EDIT_EDUCATION_ACHIEVEMENT) &&
                    educationAchievementId !== 0) ? (
                    <div className="d-sm-flex justify-content-end">
                      <button
                        className="btn btn-primary col-12 col-sm-2 mx-sm-3 mb-2 mb-sm-0"
                        type="submit"
                      >
                        {educationAchievementId ? "Update" : "Submit"}{" "}
                      </button>
                      <button
                        className="btn btn-warning col-12 col-sm-2"
                        type="reset"
                      >
                        Reset
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
            <div className="mb-4">
              <Datatable
                titleData="Education Achievement List"
                columnData={tableColumnHeaderList}
                rowData={educationAchievementRowList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationAchievement;
