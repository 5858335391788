import React, { useEffect, useState } from "react";
import { Clock } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { LOADED, LOADING } from "../../../../constant";
import { LoadStaticURL } from "../../../../data/util/LoadStaticURL";
import DetailRowLayout from "../../../common/DetailRowLayout";
import { useSelector } from "react-redux";
import useAccess from "../../../../customHooks/useAccess";
import useAxiosTemplates from "../../../../customHooks/useAxiosTemplates";
import Skeleton from "react-loading-skeleton";
import NoDetailsFound from "../../../../assets/images/not-details-found.png";
import { ConvertMillisToDate } from "../../../../data/util/DateUtil";

const VisitDetail = ({ b_partner_id = 0, client_id = 0, org_id = 0 }) => {
  const EDITFUNCTION = "EditCustomer";
  const DEACTIVATEFUNCTION = "DeactivateCustomer";

  let navigate = useNavigate();
  const { customer_id } = useParams();

  // stored data
  const loggedUser = useSelector((content) => content.UserReducer);

  // form controls
  const [isLoaded, setIsLoaded] = useState(LOADING);
  const [customerData, setCustomerData] = useState(undefined);

  // custom hooks
  const [checkIsAccessible] = useAccess(loggedUser, "Customer");
  const sendRequest = useAxiosTemplates();

  useEffect(() => {
    const reqBody = {
      clientId: client_id,
      orgId: org_id,
      userId: loggedUser.userId,
      bpartnerId: b_partner_id,
    };
    new Promise((resolve) => {
      const result = sendRequest({
        url: "/customer/viewProfileVisitDetailTab",
        data: reqBody,
      });
      resolve(result);
    }).then((response_data) => {
      setCustomerData(response_data);
      setIsLoaded(LOADED);
    });
  }, []);

  return (
    <div className="card-modified-body bg-white pt-0 mb-4">
      <div>
        {isLoaded === LOADED ? (
          customerData.visitDetailList.length > 0 ? (
            <div
              className="d-flex align-items-center mb-2"
              style={{ minWidth: "max-content" }}
            >
              <Clock height="12px" className="text-secondary"></Clock>
              <div className="text-secondary">
                Last Updated on January 02, 2022
              </div>
            </div>
          ) : null
        ) : (
          <Skeleton className="w-25" />
        )}
      </div>
      <div>
        {isLoaded === LOADED ? (
          customerData.visitDetailList.length > 0 ? (
            customerData.visitDetailList.map((detail_item, index) => (
              <div key={index}>
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={[
                    "Visited Date",
                  ]}
                  data={
                    isLoaded === LOADED
                      ? [
                          ConvertMillisToDate(detail_item.visitedDate),
                        ]
                      : null
                  }
                  active={detail_item.isActive}
                />
                <DetailRowLayout
                  display={isLoaded === LOADED}
                  labels={["Remark"]}
                  data={isLoaded === LOADED ? [detail_item.remark] : null}
                  active={detail_item.isActive}
                  lastRow={true}
                />
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center">
              <img src={LoadStaticURL(NoDetailsFound)} alt="" width={"200px"} />
              <p className="ml-2 fs-5 fw-bold text-warning">
                No Details Inserted.
              </p>
            </div>
          )
        ) : (
          // Loading Skeleton
          <>
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Visited Date"]}
              data={[]}
            />
            <DetailRowLayout
              display={isLoaded === LOADED}
              labels={["Remark"]}
              data={[]}
            />
          </>
        )}
        <div className="d-flex justify-content-end mt-4 mx-1">
          {checkIsAccessible(EDITFUNCTION) && b_partner_id !== 0 && isLoaded ? (
            <button
              onClick={() =>
                navigate(
                  `/customer/view/${customer_id}/update/save_customer_tab_15/`
                )
              }
              className="btn btn-primary col-12 col-md-2"
            >
              {customerData.visitDetailList.length > 0 ? "Update" : "Insert"}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default VisitDetail;
